<template>
  <b-modal :visible="approverSelection"
           ref="chooseApprover"
           id="chooseApprover"
           @hidden="clear"
           no-close-on-backdrop
           hide-footer
           size="lg"
           class="h-100"
           :title="$t('section.chooseApprover')">
    <b-tabs v-model="activeTab">
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon"/>
          <span>{{ $t('document.addGroup') }}</span>
        </template>

        <b-card-text>
          <div class="pr-2 justify-content-between">
            <h6 v-if="document.lifecycleDetail.status.name === 'DRAFT' && requesterAccessLevel !== null && requesterAccessLevel === 'CREATOR'"
                class="section-label mb-1">
              {{ $t('document.addGroup') }}
            </h6>
            <groups-lookup-select :requester-access-level="requesterAccessLevel"
                                  :workspace-id="workspaceId"
                                  :approvers="approvers"
                                  :multiple="true"
                                  @handleSelect="handleGroupSelection"
                                  @handleDeselect="handleGroupDeselection"
                                  :placeholder="$t('document.groupsNeedToApprovement')"/>
            <div>
              <h6 class="section-label mb-1 mt-1">
                {{ $t('document.validationFlow') }}
              </h6>
              <b-overlay id="loading-approver-users"
                         :show="approversLoading"
                         :variant="'light'"
                         :opacity="0.65"
                         rounded="sm">
                <approvers :approvers="approvers"
                           :section-id="selectedSectionId"/>
              </b-overlay>
            </div>
          </div>

          <div class="pr-2 justify-content-between align-content-center mb-2"
               v-if="false">
            <empty-placeholder/>
          </div>
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon"/>
          <span>{{ $t('section.actionHistory') }}</span>
        </template>

        <b-card-text>
          <template v-for="(action,ix) in actions">
            <div class="timeline-item timeline-variant-primary timeline-item-fill-border-primary p-1" v-bind:key="ix">
              <h6 v-text="action.user"/>

              <b-badge variant="light-dark" size="sm">
                <span>{{ prepareStatusText(action.actionStatus) }}</span>
              </b-badge>

              <div v-if="action.note"
                   class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-25 mb-sm-0">
                <span class="timeline-item-time text-nowrap text-muted"><span
                    class="font-weight-bold">{{ $t('section.rejectReason') }}</span> {{ action.note }}</span>
              </div>

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-25 mb-sm-0">
                <small
                    class="timeline-item-time text-nowrap text-muted"
                    v-text="action.actionAt"/>
              </div>
            </div>
          </template>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-modal>

</template>
<script>

import {BBadge, BCardText, BOverlay, BTab, BTabs} from "bootstrap-vue";
import GroupsLookupSelect from "@/views/components/Select/GroupsLookupSelect";
import Approvers from '@/views/Documents/Editor/Sections/Approvers/List';
import {mapState} from "vuex";
import EmptyPlaceholder from "@/views/Documents/Editor/Sections/Approvers/EmptyPlaceholder";
import i18n from "@/libs/i18n";

export default {
  name: "ChooseApprovers",
  components: {
    GroupsLookupSelect,
    BCardText,
    Approvers,
    BOverlay,
    EmptyPlaceholder,
    BTabs,
    BTab,
    BBadge
  },
  props: {
    workspaceId: String,
  },
  watch: {
    activeTab: {
      handler: function (val, oldVal) {
        if (val === 1) {
          this.fetchApproverActions()
        }
      }
    },
  },
  data() {
    return {
      approversLoading: false,
      activeTab: 0,
      actions: []
    }
  },
  computed: {
    ...mapState({
      approverSelection: state => state.document.approverSelection,
      selectedSectionId: state => state.document.selectedSectionId,
      approvers: state => state.section.sectionApprovers,
      requesterAccessLevel: state => state.section.requesterAccessLevel,
      document: (state) => state.document.document,
    })
  },
  beforeDestroy() {
    this.clear()
  },
  methods: {
    fetchApprovers() {
      this.approversLoading = true
      this.$store.dispatch('section/fetchAllApprovers', this.selectedSectionId)
          .then(() => {
            this.approversLoading = false
          })
    },
    clear() {
      this.$store.commit("document/SET_APPROVER_SELECTION", {toggle: false, sectionId: null})
      this.activeTab = 0
      this.actions = []
    },
    handleGroupSelection(event) {
      const payload = {
        documentId: this.$route.params.id,
        sectionId: this.selectedSectionId,
        request: {
          groupId: event.id
        }
      }

      this.$store.dispatch('document/addGroup', payload)
          .then(() => this.fetchApprovers())
          .then(() => this.$emit('reloadSections'))
    },
    handleGroupDeselection(event) {
      const payload = {
        sectionId: this.selectedSectionId,
        request: {
          groupId: event.key
        }
      }

      this.$store.dispatch('section/removeApprover', payload)
          .then(() => this.fetchApprovers())
    },
    fetchApproverActions() {
      this.$store.dispatch('document/fetchApproverActions', this.selectedSectionId)
          .then(res => this.actions = res)
    },
    prepareStatusText(status) {
      return status === 'WAITING' ? i18n.t('section.lifecycle.assigned')
          : status === 'APPROVED' ? i18n.t('section.lifecycle.approved')
              : status === 'REJECTED' ? i18n.t('section.lifecycle.rejected')
                  : status === 'AS_READER' ? i18n.t('section.lifecycle.asReader')
                      : status === 'RE_DEMANDED' ? i18n.t('section.lifecycle.redemanded')
                          : status
    }
  },
}
</script>

<style scoped>

</style>