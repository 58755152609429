<template>
  <div>
    <versions-view-layout>
      <template v-slot:header>
        <div
            class="justify-content-start container-fluid justify-content-between align-items-center d-flex document-title w-full pl-0">
          <div class="row m-0">
            <div class="col">
              <div class="row">
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="outline-dark"
                    @click="$emit('switchEditorView')"
                    class="rounded mr-25 ml-1 elevated-btn normal py-1">
                  <div class="icon-wrapper">
                    <feather-icon icon="LogInIcon" class="font-weight-bolder" stroke-width="3" size="24"
                                  style="transform: rotate(180deg)"/>
                    <span class="ml-50">{{ $t('document.backToEditor') }}</span>
                  </div>
                </b-button>
              </div>
            </div>
            <div class="row d-flex justify-content-start align-items-center align-items-center m-0 p-0">
              <div class="col">
                <div class="row m-0">
                  <div class="col p-0  font-medium-3 text-dark" style="font-weight: 900">
                    {{ $t('document.versionHistory') }}
                  </div>
                </div>
                <div class="row d-flex justify-content-start align-items-start m-0 p-0">
                  <div class="col p-0">
                    {{ getHeaderDescription }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                @click="$emit('switchEditorView')"
                class="rounded mr-1 font-weight-bold py-50">
              <div class="icon-wrapper">
                <feather-icon icon="RotateCcwIcon" class="font-weight-bolder" stroke-width="3" size="24"/>
                <span class="ml-50">{{ $t('restore') }}</span>
              </div>
            </b-button>
          </div>
        </div>
      </template>
      <template v-slot:version-list>
        <version-list
            :document-id="document.documentId"
            :current-version="10"/>
      </template>
      <template v-slot:content-area>
        <div class="versions-editor-center-area">
          <div class="page">
            <b-list-group-item
                v-for="(section, index) in versionComparedSections"
                :key="index"
                tag="li">
              <Section
                  :key="section.key"
                  :readonly="true"
                  :hide-actions="true"
                  :ref="`section-ref-${section.sectionId}`"
                  :workspace-id="document.idWorkspace"
                  :index="++index"
                  :show="section.show"
                  :document-id="document.documentId"
                  :section="section"
              ></Section>
            </b-list-group-item>
          </div>
        </div>
      </template>
    </versions-view-layout>
  </div>
</template>

<script>
import VersionsViewLayout from "@/views/Documents/Editor/Sections/Versions/VersionsViewLayout";
import VersionList from '@/views/Documents/Editor/Sections/Versions/List'
import {BButton, VBTooltip, BListGroupItem} from 'bootstrap-vue'
import {mapState, mapGetters} from "vuex";
import Ripple from "vue-ripple-directive";
import Section from "@/views/Documents/Editor/Sections/Section";
import {QuillUtils} from "@/services/Utils";
import DateUtil from "@/services/DateUtil";
import {v4 as uuidv4} from 'uuid'

export default {
  name: "VersionsView",
  components: {
    VersionsViewLayout, BButton, VersionList, BListGroupItem, Section
  },
  mounted() {
    this.sections.forEach(section => {
      section['key'] = uuidv4()
      this.versionComparedSections.push(section)
      QuillUtils.setReadonlyParts(section.content)
    })
  },
  data() {
    return {
      versionComparedSections: []
    }
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState({
      document: state => state.document.document,
      sections: state => state.document.sections,

    }),
    getSelectedVersion() {
      return this.$store.getters["section/getSelectedVersion"]
    },
    getHeaderDescription() {
      if (this.getSelectedVersion !== null) {
        return ((this.getSelectedVersion.title !== null && typeof this.getSelectedVersion.title != 'undefined') ? this.getSelectedVersion.title : this.getFormattedCreatedDate(this.getSelectedVersion.editedAt))
      }
      return this.document.title
    }
  },
  methods: {
    getFormattedCreatedDate(date) {
      return DateUtil.getFormattedTime(date)
    },
  },
  beforeDestroy() {
    this.sections.forEach(section => {
      QuillUtils.unsetReadonlyParts(section.content)
    })
  }
}
</script>

<style lang="scss" scoped>


.versions-editor-center-area::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent;
}

.versions-editor-center-area::-webkit-scrollbar-thumb {
  background: lightgray;
}

.versions-editor-center-area {
  //@media (max-width: 1200px) {
  //  left: 95px;
  //  width: calc(100vw - 90px);
  //
  background-color: transparent;
  overflow-y: scroll;
  width: 100%;
  height: 100%;

  .page {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm 10mm 20mm 10mm;
    margin: 10mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .subpage {
    padding: 1cm;
    border: 5px red solid;
    height: 257mm;
    outline: 2cm #FFEAEA solid;
  }

  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    html, body {
      width: 210mm;
      //height: 297mm;
    }
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }
  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    .page {
      width: 210mm;
      height: 297mm;
    }
    /* ... the rest of the rules ... */
  }
}

</style>
