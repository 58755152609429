import _Quill from "quill"

const Quill = window.Quill || _Quill
let BlockEmbed = Quill.import('blots/block')

export class HeadingFormatter extends BlockEmbed {

    static blotName = 'heading';
    static className = 'heading';
    static tagName = 'div';

    static create({parentIndex, lineIndex, text, level, headingOrder, numbering, headingClass, readonly = false}) {

        const node = super.create()

        node.dataset.text = text
        node.dataset.index = parentIndex
        node.dataset.level = level
        node.dataset.lineIndex = lineIndex
        node.dataset.headingOrder = headingOrder
        node.dataset.numbering = numbering
        node.dataset.headingClass = headingClass

        console.log(node.dataset)

        node.setAttribute('contenteditable', false);
        node.setAttribute('class', headingClass)
        node.innerHTML = numbering

        const indexPrefix = document.createElement("span")

        const pTag = document.createElement("span")
        pTag.setAttribute('contenteditable', false);
        pTag.setAttribute('class', headingClass)
        pTag.innerHTML =  text

        if (!readonly) {
            pTag.addEventListener('click', (e) => {
                const updatedText = prompt(e.target.innerHTML, e.target.innerHTML)
                if (updatedText) {
                    node.dataset.text = updatedText;
                    pTag.innerHTML = updatedText
                }
            })
        }

        indexPrefix.appendChild(pTag)
        node.appendChild(pTag)

        // node.addEventListener('dblclick', (e) => {
        //     e.preventDefault()
        //     const updatedText = prompt('update text')
        //
        //     if(updatedText) {
        //         node.dataset.text = updatedText;
        //         //render(template(updatedText), node)
        //     }
        // })

        // node.addEventListener('mouseover', () => {
        //     console.log(parentIndex)
        // });

        //render(template(text), node)

        return node;
    }

    static value(node) {
        return {
            parentIndex: node.dataset.parentIndex,
            level: node.dataset.level,
            text: node.dataset.text,
            headingOrder: node.dataset.headingOrder,
            lineIndex: node.dataset.lineIndex,
            numbering: node.dataset.numbering,
            headingClass: node.dataset.headingClass
        }
    }
}

Quill.register(HeadingFormatter);
