<template>
  <div>

    <div :class="{'row m-0 editor-toolbar-area': true, 'left-open': isLeftOpen}">
      <div class="cols-md-8  justify-content-center text-center ">
        <div class="w-100">
          <global-editor-settings
              @scale-change="onScaleChange"
              @apply-global-document-settings="formatFontForAllSections"/>
          <div v-if="document.lifecycleDetail.status.name === 'DRAFT'" class="d-inline-block">
            <variables class="mx-25" :disabled="selectedSectionId === null"
                       @onSelect="onVariableSelect"></variables>
            <b-dropdown
                ref="headingDropdown"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                no-caret
                id="dropdown-heading-1"
                style="margin-right: 2px"
                no-flip
                left
                variant="flat-dark">
              <template #button-content style="z-index: -1 !important; ">
                <v-icon name="heading"></v-icon>
              </template>
              <b-dropdown-item @click="toggleNumbering">
                <div style="width: 220px;">
                  <span v-if="!document.numbering"
                        class="font-weight-bold">
                  {{ $t('document.toolbar.setNumbering') }}
                  </span>
                  <span v-else
                        class="font-weight-bold">
                    {{ $t('document.toolbar.resetNumbering') }}
                  </span>
                  <small v-if="!document.numbering"
                         style="white-space:pre-wrap;  display: block; text-align: left">
                    {{ $t('document.toolbar.setNumberingDescription') }}
                  </small>
                  <small v-else
                         style="white-space:pre-wrap;  display: block; text-align: left">
                    {{ $t('document.toolbar.resetNumberingDescription') }}
                  </small>
                </div>

              </b-dropdown-item>
              <b-dropdown-item @click="unformatHeadings">
                <div style="width: 220px;  display: block">
                  <span class="font-weight-bold">{{ $t('document.editor.normalText') }}</span>
                </div>
              </b-dropdown-item>
              <b-dropdown
                  ref="headingDropdown1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  no-caret
                  id="dropdown-heading-1"
                  style="width: 100%;"
                  no-flip
                  toggle-class="px-2 py-1 rounded-0 border-top"
                  dropright
                  variant="flat-dark">
                <template #button-content>
                  <div style="width: 250px;"
                       class="flex d-flex w-100 align-items-center justify-content-between">
                    <strong class="m-0 p-0">{{ $t('document.editor.addNewSection') }}</strong>
                    <feather-icon icon="ChevronRightIcon"></feather-icon>
                  </div>
                </template>
                <b-dropdown-item @click="insertHeadingSecond">
                  <div style="width: 250px;  display: block">
                    <div class="ql-editor m-0 p-0">
                      <h1>{{ $t('document.editor.secondLevelHeader') }}</h1>
                    </div>
                    <small style="white-space:pre-wrap;  display: block;">
                      {{ $t('document.editor.secondLevelSubheader') }}
                    </small>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="addClause(2)">
                  <div style="width: 250px;  display: block">
                    <span class="font-weight-bold">{{ $t('document.editor.addBullet') }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                  :disabled="selectedSectionId === null"
                  ref="headingDropdown2"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  no-caret
                  id="dropdown-heading-2"
                  style="width: 100%;"
                  no-flip
                  toggle-class="px-2 py-1 rounded-0 border-top border-bottom"
                  dropright
                  variant="flat-dark">
                <template #button-content>
                  <div style="width: 250px;"
                       class="flex d-flex w-100 align-items-center justify-content-between">
                    <h2 class="m-0 p-0">{{ $t('document.editor.secondLevel') }}</h2>
                    <feather-icon icon="ChevronRightIcon"></feather-icon>
                  </div>
                </template>
                <b-dropdown-item @click="insertHeadingSecond">
                  <div style="width: 250px;  display: block">
                    <div class="ql-editor m-0 p-0">
                      <h2>{{ $t('document.editor.secondLevelHeader') }}</h2>
                    </div>
                    <small style="white-space:pre-wrap;  display: block;">
                      {{ $t('document.editor.secondLevelSubheader') }}
                    </small>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="addClause(2)">
                  <div style="width: 250px;  display: block">
                    <span class="font-weight-bold">{{ $t('document.editor.addBullet') }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                  :disabled="selectedSectionId === null"
                  ref="headingDropdown3"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  no-caret
                  id="dropdown-heading-3"
                  style="width: 100%;"
                  no-flip
                  toggle-class="px-2 py-1 rounded-0 border-bottom"
                  dropright
                  variant="flat-dark">
                <template #button-content>
                  <div style="width: 250px;"
                       class="flex d-flex w-100 align-items-center justify-content-between">
                    <h3 class="m-0 p-0">{{ $t('document.editor.thirdLevel') }}</h3>
                    <feather-icon icon="ChevronRightIcon"></feather-icon>
                  </div>
                </template>
                <b-dropdown-item @click="insertHeadingThird">
                  <div style="width: 250px;  display: block">
                    <div class="ql-editor m-0 p-0">
                      <h2>{{ $t('document.editor.thirdLevelHeader') }}</h2>
                    </div>
                    <small style="white-space:pre-wrap;  display: block;">
                      {{ $t('document.editor.thirdLevelSubheader') }}
                    </small>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="addClause(3)">
                  <div style="width: 250px;  display: block">
                    <span class="font-weight-bold">{{ $t('document.editor.addBullet') }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                  :disabled="selectedSectionId === null"
                  ref="headingDropdown4"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  no-caret
                  id="dropdown-heading-4"
                  style="width: 100%;"
                  no-flip
                  toggle-class="px-2 py-1 rounded-0 border-bottom"
                  dropright
                  variant="flat-dark">
                <template #button-content>
                  <div style="width: 250px;"
                       class="flex d-flex w-100 align-items-center justify-content-between">
                    <h4 class="m-0 p-0">{{ $t('document.editor.fourthLevel') }}</h4>
                    <feather-icon icon="ChevronRightIcon"></feather-icon>
                  </div>
                </template>
                <b-dropdown-item @click="insertHeadingForth">
                  <div style="width: 250px;  display: block">
                    <div class="ql-editor m-0 p-0">
                      <h2>{{ $t('document.editor.fourthLevelHeader') }}</h2>
                    </div>
                    <small style="white-space:pre-wrap;  display: block;">
                      {{ $t('document.editor.fourthLevelSubheader') }}
                    </small>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="addClause(4)">
                  <div style="width: 250px;  display: block">
                    <span class="font-weight-bold">{{ $t('document.editor.addBullet') }} </span>
                  </div>
                </b-dropdown-item>

              </b-dropdown>
              <b-dropdown-item :disabled="selectedSectionId === null" @click="reorderSectionHeadings">
                <div style="width: 220px;  display: block">
                  <span class="font-weight-bold">{{ $t('document.editor.reorderHeaders') }}</span>
                  <small
                      style="white-space:pre-wrap; width: 100%; display: block;">{{
                      $t('document.editor.reorderHeadersDescription')
                    }}</small>
                </div>
              </b-dropdown-item>

            </b-dropdown>
            <div class="divider-v"></div>
            <b-form-select
                v-if="false"
                :options="fontSizeArr"
                v-model="getFontSize"
                @change="formatSize"
                style="display: inline-block; width:80px; height: 30px; border: none"
                class="font-weight-bold mx-25 text-dark"
            />
            <b-button :disabled="selectedSectionId === null"
                      @click="formatBold"
                      :class="isBold ? 'bg-light-primary' : ''"
                      variant="flat-dark" class="btn-icon">
              <v-icon name="bold"></v-icon>
            </b-button>
            <b-button :disabled="selectedSectionId === null"
                      variant="flat-dark" class="btn-icon"
                      :class="isItalic ? 'bg-light-primary btn-icon' : 'btn-icon'"
                      @click="formatItalic">
              <v-icon name="italic"></v-icon>
            </b-button>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="isUnderline ? 'bg-light-primary' : ''"
                @click="formatUnderline">
              <v-icon name="underline"></v-icon>
            </b-button>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="isStrikethrough ? 'bg-light-primary' : ''"
                @click="formatStrikethrough">
              <v-icon name="strikethrough"></v-icon>
            </b-button>
            <div class="divider-v"></div>
            <b-button
                v-if="false"
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="isCodeBlock ? 'bg-light-primary' : ''"
                @click="formatCodeBlock">
              <v-icon name="code"></v-icon>
            </b-button>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="isBrushed ? 'bg-light-primary' : ''"
                @click="formatBrushText">
              <v-icon name="brush"></v-icon>
            </b-button>

            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-danger" class="btn-icon"
                :class="isHighlighted ? 'bg-light-primary' : ''"
                @click="formatHighlightText">
              <v-icon name="highlighter"></v-icon>
            </b-button>
            <div class="divider-v"></div>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="isLineOrdered ? 'bg-light-primary' : ''"
                @click="formatLineOrdered">
              <v-icon name="list-ol"></v-icon>
            </b-button>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="isLineBullet ? 'bg-light-primary' : ''"
                @click="formatLineBullet">
              <v-icon name="list"></v-icon>
            </b-button>
            <div class="divider-v"></div>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                @click="formatLineIndent">
              <v-icon name="indent"></v-icon>
            </b-button>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                @click="formatLineDeindent">
              <v-icon name="indent" flip="both"></v-icon>
            </b-button>

            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="getAlignedType === 'align-center' ? 'bg-light-primary' : ''"
                @click="alignCenter">
              <v-icon name="align-center"></v-icon>
            </b-button>

            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="getAlignedType === 'align-justify' ? 'bg-light-primary' : ''"
                @click="alignJustify">
              <v-icon name="align-justify"></v-icon>
            </b-button>

            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="getAlignedType === 'align-left' ? 'bg-light-primary' : ''"
                @click="alignLeft">
              <v-icon name="align-left"></v-icon>
            </b-button>

            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                :class="getAlignedType === 'align-right' ? 'bg-light-primary' : ''"
                @click="alignRight">
              <v-icon name="align-right"></v-icon>
            </b-button>
            <div class="divider-v"></div>
            <b-button
                :disabled="selectedSectionId === null"
                variant="flat-dark" class="btn-icon"
                @click="insertLink">
              <v-icon name="link"></v-icon>
            </b-button>
            <div class="divider-v"></div>
            <b-button
                v-b-tooltip.hover.top="$t('buttons.addTable')"
                :disabled="selectedSectionId === null"
                variant="flat-dark"
                :class="isTable === 'align-justify' ? 'bg-light-primary' : ''"
                @click="insertTable">
              <feather-icon
                  icon="GridIcon"
                  size="18"
                  class="cursor-pointer"/>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div>

      <b-button
          v-b-tooltip.hover.top="$t('buttons.leftMenu')"
          variant="flat-primary"
          style="background-color: rgba(40, 199, 111, 0.15)"
          class="rounded-circle-sm cursor-pointer open-left-action">
        <feather-icon
            icon="ArrowRightIcon"
            size="18"
            v-if="!isLeftOpen"
            class="cursor-pointer"
            @click="openLeftSide"/>
      </b-button>
    </div>
    <div class="row" style="min-width:1400px; height: 100%; width: 100%;">
      <EditorLeftSidebar
          @load-sections="loadSectionList"
          @reload-document="this.$emit('reload-document')"
          :is-open="isLeftOpen"/>
      <div class="col ml-auto">
        <div
            ref="editorCenter"
            id="editor-area"
            :class="{'cursor-pointer editor-center-area': true, 'z100': this.zoom === 1.0, 'z125' : this.zoom === 1.2,  'left-open': isLeftOpen}">
          <div :style="getDocumentStyle()">
            <b-list-group-item
                v-for="(section, index) in sections"
                :key="`${section.sectionId}`"
                tag="li">
              <EditorSection
                  :ref="`section-ref-${section.sectionId}`"
                  :workspace-id="workspaceId"
                  :index="index + 1"
                  :show="section.show"
                  :document-id="documentId"
                  :section="section"
                  :edit-progressing="section.sectionId != null && section.sectionId === progressingSectionId"
                  @reload="reloadSection"
                  @remove="removeSection"
                  @saveSilent="saveSectionSilently"
                  @save="saveSection"
                  :document-status="document.lifecycleDetail.status.name"
              ></EditorSection>
            </b-list-group-item>
            <div class="row w-100 justify-content-center">
              <div
                  style="border: 4px solid  rgba(255, 159, 67, 0.12); width: 300px; "
                  class="row cursor-pointer justify-content-center py-50  rounded-lg  mx-lg-0 d-flex mt-1"
                  @click="openNewSection"
                  v-if="isEditorActive && !loading && !isNewSectionEditorOpened && document.documentId != null && document.lifecycleDetail.status.name === 'DRAFT' && getUserId() === document.owner.id">
                  <span class="text-warning" style="font-size: 1.1em; font-weight:700">
                    {{ $t("section.add") }}
                  </span>
              </div>
            </div>
          </div>
        </div>

        <choose-approvers :workspace-id="workspaceId"
                          :section-id="selectedSectionId"
                          @reloadSections="loadSectionList(); refreshAllSectionContent();"/>
        <div class="col" style="background-color: #F8F9FA;">
          <EditorRightSidebar
              :section-id="selectedSectionId"
              :document-id="documentId"
              :workspace-id="workspaceId"
          >
          </EditorRightSidebar>
        </div>
      </div>
    </div>

    <diff-check
        v-if="hasDiff"
        :content="localContent"
        :section-id="selectedSectionId"
        :version="version"
        @resolve="resolveConflicts"/>
  </div>
</template>
<script>
import Section from "@/views/Documents/Editor/SectionModel";
import EditorLeftSidebar from "@/views/Documents/Editor/Sides/LeftSidebar.vue";
import EditorRightSidebar from "@/views/Documents/Editor/Sides/RightSidebar.vue";
import EditorSection from "@/views/Documents/Editor/Sections/Section.vue";

import {mapState} from "vuex";
import {BButton, BDropdown, BDropdownItem, BFormSelect, BListGroupItem, VBToggle, VBTooltip} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {PEventBus} from "@/services/PEventBus";
import DiffCheck from "@/views/Documents/Editor/Sections/DiffCheck/index";
import Ripple from "vue-ripple-directive";
import i18n from "@/libs/i18n";
import {QuillUtils} from "@/services/Utils";
import store from "@/store";
import ChooseApprovers from "@/views/Documents/Editor/Sections/Approvers/ChooseApprovers"
import Variables from '@/views/Documents/Editor/Variables/List'
import GlobalEditorSettings from "@/views/Documents/Editor/Toolbar/GlobalEditorSettings";
import {getUserData} from "@/auth/utils";

export default {
  name: "EditorCenter",
  components: {
    EditorSection,
    EditorRightSidebar,
    BButton,
    Variables,
    BDropdown,
    BDropdownItem,
    BListGroupItem,
    DiffCheck,
    EditorLeftSidebar,
    GlobalEditorSettings,
    BFormSelect,
    ChooseApprovers,
  },
  directives: {
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    documentId: String,
    workspaceId: String,
    workspace: String,
    documentType: Object,
    lifecycleDetail: Object,
  },
  watch: {
    activeTab: {
      handler(index) {
        this.selectTab(index)
      }
    },
    isLeftOpen: {
      handler(isOpen) {
        if (isOpen) {
          this.openLeftSide()
        } else {
          this.closeLeftSide()
        }
      }
    }
  },
  data() {
    return {
      page_format_mm: [210, 297],
      zoom: 1.0,
      pages_height: 0, // real measured page height in px (corresponding to page_format_mm[1])
      editor_width: 0, // real measured with of an empty editor <div> in px
      px_in_mm: 0.2645833333333,
      updateVariableActive: false,
      forceRenderer: 0,
      fontSizeArr: ['8px', '9px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'],
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
      },
      isDropdownHeading2Visible: false,
      isDropdownHeading3Visible: false,
      isDropdownHeading4Visible: false,
      preventNumberingCheckbox: false,
      selectedIndex: 0, // the index of the selected tab,
      happy: false,
      reordering: false,
      newSection: null,
      version: 0,
      localContent: null,
      startDate: null,
      progressingSectionId: null,
      newCreating: false,
      hasDiff: false,
      newSectionOpened: false,
      isLeftOpen: null
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.section.listLoading,
      document: (state) => state.document.document,
      usedVariables: (state) => state.document.variableList
    }),
    calcEditorAreaTop() {
      let topmm = ((this.page_format_mm[0] * this.zoom - this.page_format_mm[0])) * 3.0
      if (this.zoom === 1.0) {
        return 0
      } else if (this.zoom === 0.95) {
        return -5
      }
      return topmm
    },
    selectedSectionId() {
      return this.$store.state.document.selectedSectionStatus.sectionId
    },
    isBold() {
      return this.isFormatApplied('bold')
    },
    isItalic() {
      return this.isFormatApplied('italic')
    },
    isUnderline() {
      return this.isFormatApplied('underline')
    },
    isStrikethrough() {
      return this.isFormatApplied('strike')
    },
    isCodeBlock() {
      return this.isFormatApplied('code-block')
    },
    isLineOrdered() {
      return this.isListApplied('ordered')
    },
    getAlignedType() {
      return this.getAlignFormat()
    },
    isTable() {
      return true
    },
    isLineBullet() {
      return this.isListApplied('bullet')
    },
    isBrushed() {
      return this.isFormatApplied('background')
    },
    isHighlighted() {
      return this.isFormatApplied('color')
    },
    getFontSize: {
      get() {
        return this.isFormatApplied('size') ? this.getFormat('size') : this.fontSizeArr[4]
      },
      set(value) {
        // omit
      }
    },
    sections: {
      get() {
        return this.$store.getters["document/getSections"];
      },
      set(value) {
        this.$store.commit("document/UPDATE_LIST", value);
      },
    },
    isNewSectionEditorOpened() {
      if (this.sections.length === 0) {
        return true;
      } else {
        return this.newSectionOpened;
      }
    },
    isEditorActive() {
      return this.document.lifecycleDetail.editable;
    },
  },
  created() {
    window.addEventListener("resize", this.handleScreenSizeChange);
    this.handleScreenSizeChange()
  },
  mounted() {
    this.startDate = this.document.startDate;
    this.loadSectionList();
    this.loadVariableTypes();
    PEventBus.$on("apply-library-as-new-section", this.applyAsNewSection);
    PEventBus.$on("load-sections", this.loadSectionList);
    PEventBus.$on("delete-section", (evt) => this.removeSection(evt));
    PEventBus.$on("recompose-variables", this.recomposeVariable);
    PEventBus.$on('refresh-all-section-content', this.refreshAllSectionContent)
    PEventBus.$on('close-left', this.closeLeftSide)

    this.initHeadingDropdown()

    const isLeftOpen = localStorage.getItem('is-editor-left-opened')
    this.isLeftOpen = isLeftOpen === 'T'

    const zoom = localStorage.getItem('scale')
    if (zoom) {
      this.zoom = parseFloat(zoom)
    }
  },
  beforeDestroy() {
    PEventBus.$off("recompose-variables", this.recomposeVariable);
    PEventBus.$off('refresh-all-section-content', this.refreshAllSectionContent)
    PEventBus.$off("load-sections", this.loadSectionList);
    PEventBus.$off("apply-library-as-new-section", this.applyAsNewSection);
    PEventBus.$off("delete-section", (evt) => this.removeSection(evt));
    PEventBus.$off('close-left', this.closeLeftSide)

    window.removeEventListener("resize", this.handleScreenSizeChange);
    this.removeVariableUpdateListeners()
  },
  methods: {
    toggleNumbering() {
      this.$store.dispatch('document/toggleNumbering', this.documentId)
    },
    removeVariableUpdateListeners() {
      this.usedVariables.forEach(val => {
        PEventBus.$off(`${val.variableId}-variable-updated`)
      })
    },
    openLeftSide() {
      localStorage.setItem('is-editor-left-opened', 'T')
      this.isLeftOpen = true
    },
    closeLeftSide() {
      localStorage.setItem('is-editor-left-opened', 'F')
      this.isLeftOpen = false
    },
    initHeadingDropdown() {
      this.$root.$on('bv::dropdown::show', bvEvent => {
        if (bvEvent.componentId === 'dropdown-heading-2') {
          this.isDropdownHeading2Visible = true;
        }
        if (bvEvent.componentId === 'dropdown-heading-3') {
          this.isDropdownHeading3Visible = true;
        }
        if (bvEvent.componentId === 'dropdown-heading-4') {
          this.isDropdownHeading4Visible = true;
        }
        if (bvEvent.componentId === 'section-numbering-checkbox') {
          this.preventNumberingCheckbox = true;
        }
      })
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        if (bvEvent.componentId === 'dropdown-heading-2') {
          this.isDropdownHeading2Visible = false;
        }
        if (bvEvent.componentId === 'dropdown-heading-3') {
          this.isDropdownHeading3Visible = false;
        }
        if (bvEvent.componentId === 'dropdown-heading-4') {
          this.isDropdownHeading4Visible = false;
        }
        if (bvEvent.componentId === 'section-numbering-checkbox') {
          this.preventNumberingCheckbox = false;
        }
        if (this.isDropdownHeading2Visible) {
          bvEvent.preventDefault()
        }
        if (this.isDropdownHeading3Visible) {
          bvEvent.preventDefault()
        }
        if (this.isDropdownHeading4Visible) {
          bvEvent.preventDefault()
        }
        if (this.preventNumberingCheckbox) {
          bvEvent.preventDefault()
        }
      })
    },
    getEditorAreaStyle() {
      let heightWeight = 40 // in mm
      if (this.zoom === 1.2) {
        heightWeight = 70
      }
      return {
        "padding-top": `${25 / this.zoom}mm;`,
        left: "auto",
        transform: `scale(${this.zoom})`,
        "margin-left": "auto",
        "margin-top": `${35 / this.zoom}mm`,
        width: "100vw",
        "transform-origin": "top",
        "position": "absolute",
        "overflow-y": "scroll",
        height: `calc(100vh - ${heightWeight}mm)`,
      }
    },
    getDocumentStyle() {

      const page_width = this.page_format_mm[0] / this.px_in_mm;
      const page_spacing_mm = 10;
      const view_padding = 10;

      let top_mm = 65 * this.px_in_mm

      const style = {

        // left: "calc("+ left_px +"px + "+ view_padding +"px)",
        width: (this.page_format_mm[0]) + "mm",
        //'min-height': "calc(" + this.zoom * this.page_format_mm[1] + "mm + " + (2 * view_padding) + "px)",
        //'box-shadow': "0 0 5px rgba(0, 0, 0, 0.1)",
        //'border-radius': '5px',
        'padding-top': `${15 / this.zoom}mm`,
        'padding-bottom': `${15 / this.zoom}mm`,
        'padding-left': `${10 / this.zoom}mm`,
        'padding-right': `${10 / this.zoom}mm`,
        "transform-origin": 'top',
        margin: `${10 / this.zoom}mm auto`,
        background: "white",

        //height: '100%',
        //height: "calc("+ this.zoom * this.page_format_mm[1] +"mm + "+ (2*view_padding) +"px)"
      }
      //style["height"] = this.page_format_mm[1]+"mm";
      return style;
    },
    onToolbarFocus(event) {
      event.preventDefault()
    },
    onScaleChange(event) {
      switch (event) {
        case '100%':
          this.zoom = 1.0
          localStorage.setItem('scale', '1.0')
          break
        case '125%' :
          this.zoom = 1.2
          localStorage.setItem('scale', '1.2')
          break

      }
    },
    handleScreenSizeChange() {
      let w = window.innerWidth;
      if (w < 1200) {
        this.closeLeftSide()
      } else {
        this.openLeftSide()

      }
    },
    isFormatApplied(formatType) {
      const selectedTextFormats = this.$store.state.document.selectedSectionStatus.formats
      if (selectedTextFormats !== null) {
        return Object.prototype.hasOwnProperty.call(selectedTextFormats, formatType)
      }
      return false
    },
    getFormat(formatType) {
      const selectedTextFormats = this.$store.state.document.selectedSectionStatus.formats
      if (selectedTextFormats !== null) {
        const sizes = selectedTextFormats[formatType]
        if (Array.isArray(sizes)) {
          return null
        } else {
          return sizes
        }
      }
    },
    isListApplied(formatType) {
      const selectedTextFormats = this.$store.state.document.selectedSectionStatus.formats
      if (selectedTextFormats !== null) {
        return selectedTextFormats['list'] === formatType
      }
      return false
    },
    getAlignFormat(formatType) {
      const selectedTextFormats = this.$store.state.document.selectedSectionStatus.formats
      if (selectedTextFormats !== null) {
        switch (selectedTextFormats['align']) {
          case 'right': {
            return 'align-right'
          }
          case '' : {
            return 'align-left'
          }
          case 'center' : {
            return 'align-center'
          }
          case 'justify' : {
            return 'align-justify'
          }
        }
      }
      return 'align-left'
    },
    formatBold() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Bold'});
    },
    formatSize(size) {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'FontSize', value: size});
    },
    formatFontForAllSections(fStyle) {
      this.sections.forEach(sec => {
        PEventBus.$emit(`${sec.sectionId}-handle-format`, {type: 'FontStyle', value: fStyle});
      })
    },
    formatStyle(fStyle) {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'FontStyle', value: fStyle});
    },
    formatItalic() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Italic'});
    },
    formatUnderline() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Underline'});
    },
    formatStrikethrough() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Strikethrough'});
    },
    formatCodeBlock() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'CodeBlock'});
    },
    formatLineOrdered() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'ListOrdered'});
    },
    formatBrushText() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Brush'});
    },
    formatHighlightText() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Highlight'});
    },
    formatLineBullet() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Bullet'});
    },
    formatLineIndent() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Indent'});
    },
    formatLineDeindent() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Deindent'});
    },
    alignLeft() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'AlignLeft'});
    },
    alignRight() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'AlignRight'});
    },
    alignCenter() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'AlignCenter'});
    },
    alignJustify() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'AlignJustify'});
    },
    insertLink() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Link'});
    },
    insertTable() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'CreateTable'});
    },
    addClause(level) {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Clause', level: level});
    },
    unformatHeadings() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'NormalText'});
      this.$refs.headingDropdown.hide(true)
    },
    insertHeadingSecond() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Heading2'});
      this.$refs.headingDropdown.hide(true)
    },
    insertHeadingThird() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Heading3'});
      this.$refs.headingDropdown.hide(true)
    },
    insertHeadingForth() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Heading4'});
      this.$refs.headingDropdown.hide(true)
    },
    reorderSectionHeadings() {
      PEventBus.$emit(`${this.selectedSectionId}-handle-format`, {type: 'Reorder'});
      this.$refs.headingDropdown.hide(true)
    },
    onVariableSelect(variable, color) {
      PEventBus.$emit(`${this.selectedSectionId}-variable-selected`, variable, color)
    },
    loadVariableTypes() {
      this.$store.dispatch("variables/loadAll");
    },
    openNewSection() {
      this.newSectionOpened = true;
      this.newSection = new Section();
      this.createSection({
        content: {ops: [{insert: ""}]},
        htmlContent: "&nbsp",
        textContent: "",
      });
      //  this.sections.push(section)
    },
    fetchDocumentBadges() {
      this.$store.dispatch('document/fetchDocumentStatistics', this.documentId)
    },
    createSection({content, htmlContent, textContent, version, id}) {
      this.newCreating = true; // new section is in saving
      // create new section
      const payload = {
        documentId: this.documentId,
        header: "Section_" + id, // todo
        content: content,
        htmlContent: {html: htmlContent},
        textContent: textContent,
        order: this.sections.length,
      };
      this.$store.dispatch("section/create", payload).then((res) => {
        this.notify(i18n.t("messages.section.created"));
        this.newCreating = false;
        this.newSectionOpened = false;
      }).then(() => this.fetchDocumentBadges());
    },
    saveSection({version, id}) {
      // edit section content
      let section = store.getters["document/getSectionById"](id)
      this.$store.dispatch("section/edit", {
        sectionId: id,
        version: version,
        content: section.content,
        htmlContent: section.htmlContent,
        textContent: section.textContent
      }).then((res) => {
        if (res.code === 13300) {
          this.version = version;
          this.hasDiff = true;

        } else if (res.code === 4019) {
          this.fail();
        } else {
          PEventBus.$emit(`${this.selectedSectionId}-focus`);
        }
      });
    },
    saveSectionSilently({editorContent, htmlContent, textContent, version, id}) {
      // edit section content
      setTimeout(() => {
        this.$store.dispatch("section/edit", {
          sectionId: id,
          version: version,
          content: editorContent,
          htmlContent: {html: htmlContent},
          textContent: textContent
        }).then((res) => {
          if (res.code === 13300) {
            this.version = version;
            this.hasDiff = true;
          } else if (res.code === 4019) {
            // handle later
          } else {
            //this.loadSection(id);
            //this.refreshAllSectionContent()
          }
        }).catch((err) => {
        });
      })
    },
    refreshAllSectionContent() {
      this.sections.forEach(section => PEventBus.$emit(`${section.sectionId}-refresh-editor`))
    },
    warnUnsaved() {
      this.$bvModal
          .msgBoxConfirm(this.$i18n.t('messages.document.questionBeforeArchive'), {
            title: i18n.t('messages.areYouSure'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: i18n.t('yes'),
            cancelTitle: i18n.t('exit'),
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: false,
          })
    },
    resolveConflicts() {
      this.hasDiff = false;
    },
    notify(message) {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: message,
              variant: "info",
              icon: "CheckIcon",
            },
          },
          {position}
      );
    },
    fail() {
      let position = "top-center";
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: i18n.t("messages.errorOccurred"),
              variant: "danger",
              icon: "AlertIcon",
            },
          },
          {position})
    },
    reorder() {
      this.$store.dispatch('document/reorderSections')
    },
    loadSectionList() {
      this.$store.dispatch("section/fetchAll", {documentId: this.documentId})
    },
    loadSection(sectionId) {
      this.$store.dispatch("section/fetchOne", sectionId)
    },
    async reloadSection(sectionId) {
      this.progressingSectionId = sectionId
      await this.loadSection(sectionId)
      setTimeout(() => {
        this.progressingSectionId = null
      }, 200)
    },
    recomposeVariable(event) {
      let arrayOfVar = [{
        variableId: event.variableId,
        value: event.userProcessedValue,
        presentationMethod: event.presentationMethod,
      }]
      this.sections.forEach((section) => {
        QuillUtils.composeVariables(section.sectionId, section.content, arrayOfVar);
      })
    },
    removeSection(sectionId) {
      this.$swal
          .fire({
            title: "Silmek İstediğinizden Emin Misiniz?",
            showCancelButton: true,
            confirmButtonText: "Sil",
            cancelButtonText: "iptal",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch("section/delete", sectionId)
                  .then(res => {
                    if (res.done) {
                      // this.loadSectionList()
                      this.loadSectionList();
                      this.fetchDocumentBadges()
                      this.$swal.fire("Silindi!", "", "success");
                    } else if (res.code === 4800) {
                      this.$swal.fire("Hata Oluştu!", "Belge En Az Bir Bölüme Sahip Olmalı!", "error");
                    } else {
                      this.$swal.fire("Hata Oluştu!", "Bölüm Silinirken Bir Hata Oluştu!", "error");
                    }
                  });

            } else if (result.isDenied) {
              this.$swal.fire("İptal Edildi", "", "info");
            }
          });
    },
    applyAsNewSection(item) {
      let section = {
        title: item.title,
        content: item.jsonContent === null ? item.text : item.jsonContent,
      };
      this.selectedSectionId = null;
      this.newSectionOpened = true;

      this.newSection = new Section();
      this.createSection({
        content: item.jsonContent === null ? {ops: [{insert: item.text}]} : item.jsonContent,
        htmlContent: item.text === null ? "&nbsp" : item.text,
        textContent: item.text === null ? "" : item.text,
      });
    },
    getUserId() {
      return getUserData().userId
    }
  },
};
</script>
<style lang="css" scoped>
.narrow-dropdown-item {
  padding: 0.6rem;
}
</style>
<style lang="scss" scoped>
html body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.show > .dropdown-menu {
  min-width: 50px;
}

.editor-toolbar-area {
  &.left-open {
    left: 346px;
  }
}


.editor-center-area {
  &.left-open {
    width: calc(100% - (300px / 1.25));
    left: calc(300px / 1.25);
  }
}


.open-left-action {
  position: absolute;
  z-index: 1;
  font-weight: 600;

  margin-top: 130px;
  margin-left: 15px;
}

</style>
