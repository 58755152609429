<template>
  <div>
    <vue-context
        v-if="contextMenuEnabled"
        :close-on-click="true"
        ref="menu"
        style="width: auto; height: auto; z-index: 1; left:150px; top:100px"
    >
      <li>

        <b-link v-if="editable || !disabled" class="d-flex align-items-center" @click="createTable">
          <feather-icon icon="DatabaseIcon" size="16"/>
          <span class="ml-50">{{ $t('buttons.addTable') }}</span>
        </b-link>

        <b-link v-if="editable || !disabled" class="d-flex align-items-center" @click="createTable">
          <feather-icon icon="DatabaseIcon" size="16"/>
          <span class="ml-50">{{ $t('buttons.addHeader') }}</span>
        </b-link>

        <b-link
            class="d-flex align-items-center"
            @click="saveAsTemplateSelectedText"
        >
          <feather-icon icon="DatabaseIcon" size="16"/>
          <span class="ml-50">{{ $t("section.saveAsTemplate") }}</span>
        </b-link>

        <b-link class="d-flex align-items-center" @click="addComment">
          <feather-icon icon="MessageSquareIcon" size="16"/>
          <span class="ml-50">{{ $t("section.addComment") }}</span>
        </b-link>

        <b-link v-if="editable || !disabled" class="d-flex align-items-center" @click="removeSection">
          <feather-icon icon="TrashIcon" size="16"/>
          <span class="ml-50">{{ $t("section.delete") }}</span>
        </b-link>
      </li>
    </vue-context>
    <div class="quill-editor" spell-check="false">
      <slot name="toolbar"></slot>
      <div ref="editor"></div>
    </div>
  </div>
</template>
<script>
// require sources
import _Quill from "quill";

const Quill = window.Quill || _Quill;
import defaultOptions from "./options";
// eslint-disable-next-line
// import ImageResize from "quill-image-resize-vue";
// import {ImageDrop} from "quill-image-drop-module";
import {PEventBus} from "@/services/PEventBus";
import i18n from "@/libs/i18n";
import {mapState} from "vuex";
//Quill.register('modules/imageResize', ImageResize, true) //Add
//Quill.register('modules/imageDrop', ImageDrop, true) //Add
//import '@/views/Documents/Editor/Extension/Blots/inline-variable';
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

if (typeof Object.assign != "function") {
  Object.defineProperty(Object, "assign", {
    value(target, varArgs) {
      if (target == null) {
        throw new TypeError("Cannot convert undefined or null to object");
      }
      const to = Object(target);
      for (let index = 1; index < arguments.length; index++) {
        const nextSource = arguments[index];
        if (nextSource != null) {
          for (const nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}

import "./quill.snow.css"
import "./quill.table.css"
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import '@/views/Documents/Editor/Extension/Blots/ShiftEnterBlot.js'

import TableUI from "quill-table-ui";
import {BLink} from "bootstrap-vue";
import {Constants, GlobalEditorSettings} from "@/services/Utils";

Quill.register(
    {
      "modules/tableUI": TableUI,
    },
    true
);

export default {
  name: "PapirEditor",
  components: {
    VueContext,
    BLink,
  },
  props: {
    sectionId: String,
    index: Number,
    content: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    accessLevel: {
      type: String,
      default: 'NO_ACCESS'
    },
    editable: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    contextMenuEnabled: Boolean
  },

  mounted() {
    PEventBus.$on(`apply-global-document-settings`, this.applySettings)
    this.initialize();

  },
  beforeDestroy() {
    this.papirQuill.quill = null;
    delete this.papirQuill.quill;
    PEventBus.$off(`apply-global-document-settings`, this.applySettings)
  },
  data() {
    return {
      userFontFamily: "Times New Roman",
      userPFontSize: '14px',
      papirQuill: {
        quill: null,
        lastSelection: {}
      },
      isCustomizerOpen: false,
      // eslint-disable-next-line vue/no-reserved-keys
      options: {
        theme: false,
        toolbar: [
          ['link']
        ],
        placeholder: this.placeholder,
        modules: {
          table: true,
          tableUI: true,
          papirNumbering: {},
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["\\"],
            positioningStrategy: "fixed",
            renderItem: (data) => {
              if (data.disabled) {
                return `<div style="height:10px;line-height:10px;font-size:10px;background-color:#ccc;margin:0 -20px;padding:4px">${data.value}</div>`;
              }
              return data.value;
            },
            renderLoading: () => {
              return i18n.t("loading");
            },
            source: (searchTerm, renderList, mentionChar) => {
              let values;

              if (mentionChar === "\\") {
                values = this.variableList;
              }

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                      ~values[i].value
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase())
                  )
                    matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            },
            onSelect: (item, insertItem) =>
                this.onVariableInsert(item, insertItem),
          },
        },
      },
      // eslint-disable-next-line vue/no-reserved-keys
      _content: "",
      defaultOptions,
    };
  },
  methods: {
    applyGlobalSettings() {
      let fontFamily = GlobalEditorSettings.fontFamily
      let fontSize = GlobalEditorSettings.fontSize

      let globalEditorSettings = JSON.parse(localStorage.getItem(Constants.globalEditorSettings))
      if (globalEditorSettings !== null) {
        fontFamily = globalEditorSettings['fontFamily']
        fontSize = globalEditorSettings['fontSize']
      }

      this.applySettings({fontFamily: fontFamily, fontSize: fontSize}, false)

    },
    applySettings(settings, forceUpdate) {
      const htmlCollection = this.$refs['editor'].children
      for (let i = 0; i < htmlCollection.length; i++) {
        if (htmlCollection.item(0).className.includes('ql-editor')) {
          htmlCollection.item(0).style.fontFamily = settings.fontFamily
          htmlCollection.item(0).style.fontSize = settings.fontSize + 'px'
        }
      }
      if (forceUpdate) {
        this.$emit('force-update')
      }
    },
    onUnFocus() {
      this.$emit('unfocus', this.papirQuill.lastSelection)
    },
    saveLastRangeInStorage(range) {
      const data = {
        index: range.index,
        length: range.length,
        sectionId: this.sectionId
      }
      this.papirQuill.lastSelection = range
      localStorage.setItem(`last-section-editor-range`, JSON.stringify(data))
    },
    getRangeFromStorage() {
      const index = localStorage.getItem(`${this.sectionId}-range-index`)
      const length = localStorage.getItem(`${this.sectionId}-range-length`)
      return {index: index, length: length}
    },
    clearRange() {
      localStorage.removeItem(`${this.sectionId}-range-index`)
      localStorage.removeItem(`${this.sectionId}-range-length`)
    },
    // Init Quill instance
    initialize() {
      if (this.$el) {
        // Options
        let _options = Object.assign(
            {},
            this.defaultOptions,
            this.globalOptions,
            this.options
        );

        // Instance
        this.papirQuill.quill = new Quill(this.$refs.editor, _options);

        this.papirQuill.quill.enable(false);

        // Set editor content
        if (this.value || this.content) {
          this.papirQuill.quill.pasteHTML(this.value || this.content);
        }

        // Disabled editor
        // if (!this.disabled) {
        //   console.log("enabled")
        //   this.papirQuill.quill.enable(true);
        // }

        // Mark model as touched if editor lost focus
        this.papirQuill.quill.on("selection-change", (range) => {
          if (!range || this.disabled) {
            this.onUnFocus()
            this.papirQuill.quill.enable(false);
          } else {
            this.saveLastRangeInStorage(range)

            this.papirQuill.quill.enable(true);
            this.$store.commit('document/SAVE_LAST_INDEX_ON_EDITOR', {
              sectionId: this.sectionId,
              lastFocusedIndex: range.index
            })

          }
        });

        // Update model if text changes
        this.papirQuill.quill.on("text-change", (delta, oldDelta, source) => {
          let html = this.$refs.editor.children[0].innerHTML;
          const quill = this.papirQuill.quill;
          const text = quill.getText();
          if (html === "<p><br></p>") html = "";
          this._content = html;
          this.$emit("input", this._content);
          if (this.papirQuill.quill.getSelection() !== null) {
            this.saveLastRangeInStorage(quill.getSelection())
            let range = quill.getSelection()
            this.$emit("change", {delta, html, text, quill, range});
          } else {
            const range = this.getRangeFromStorage()
            quill.setSelection(range.index, range.length, 'silent')
            this.$emit("change", {delta, html, text, quill, range});
          }
        });

        // Emit ready event
        this.$emit("ready", this.papirQuill);
      }
    },
    onVariableInsert(item, insertItem) {
      this.$emit("onVariableInsert", item, insertItem);
    },
    openVariablesMenu() {
      this.papirQuill.quill.focus();
      let module = this.papirQuill.quill.getModule("mention");
      module.showMentionList();
    },
    createTable() {
      this.papirQuill.quill.focus();
      let tableModule = this.papirQuill.quill.getModule("table");
      tableModule.insertTable(3, 3);
    },
    addComment() {
      let selectionRange = this.papirQuill.quill.selection.savedRange;
      PEventBus.$emit("handleCommentQuote", this.papirQuill.quill.getText(selectionRange));
      //this.toogle()
    },

    removeSection() {
      this.$swal
          .fire({
            title: "Silmek İstediğinizden Emin Misiniz?",
            showCancelButton: true,
            confirmButtonText: "Sil",
            cancelButtonText: "iptal",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch('section/delete', this.sectionId)
                  .then(res => {
                    if (res.done) {
                      // this.loadSectionList()
                      PEventBus.$emit('load-sections')
                      this.$swal.fire("Silindi!", "", "success");
                    } else if (res.code === 4800) {
                      this.$swal.fire("Hata Oluştu!", "Belge En Az Bir Bölüme Sahip Olmalı!", "fail");
                    } else {
                      this.$swal.fire("Hata Oluştu!", "Bölüm Silinirken Bir Hata Oluştu!", "fail");
                    }
                  })
              this.clearRange()

            } else if (result.isDenied) {
              this.$swal.fire("İptal Edildi", "", "info");
            }
          });

    },
    notify(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          variant: 'info',
          icon: "CheckIcon"
        },
      }, {position: 'top-center'});
    },
    //  toggle() {
    //   if (this.isCustomizerOpen) {
    //     PEventBus.$emit('unselect-section')
    //   } else {
    //     this.openRight()
    //   }
    // },
    saveAsTemplateSelectedText() {
      let selectionRange = this.papirQuill.quill.selection.savedRange;

      PEventBus.$emit("update-left-tab", 2);
      this.$emit(
          "showSaveAsLibraryDataModal",
          false,
          this.papirQuill.quill.getText(selectionRange)
      );
    },
  },
  computed: {
    ...mapState({
      variableList: (state) => state.variables.variableList,
    }),
  },
  watch: {
    // Watch content change
    content(newVal, oldVal) {
      if (this.papirQuill.quill) {
        if (newVal && newVal !== this._content) {
          this._content = newVal;
          this.papirQuill.quill.pasteHTML(newVal);
        } else if (!newVal) {
          this.papirQuill.quill.setText("");
        }
      }
    },
    // Watch content change
    value(newVal, oldVal) {
      if (this.papirQuill.quill) {
        if (newVal && newVal !== this._content) {
          this._content = newVal;
          this.papirQuill.quill.pasteHTML(newVal);
        } else if (!newVal) {
          this.papirQuill.quill.setText("");
        }
      }
    },
    // Watch disabled change
    disabled(newVal, oldVal) {
      if (this.papirQuill.quill) {
        this.papirQuill.quill.enable(!newVal);
      }
    },
  },
};
</script>


<style lang="scss">
@import "~vue-context/src/sass/vue-context";

</style>

<style lang="css">
.ql-editor {
  font-family: Arial;
  font-size: 14px;
}
</style>
