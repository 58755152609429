<template>
  <div class="library-data-area">
    <b-overlay
        :show="loading"
        :variant="'light'"
        class="h-100"
        :opacity="0.65"
        rounded="sm">
      <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="sidebar-menu-list scroll-area p-1 mb-1 h-100">
        <b-card-text>
          <div class="p-1 justify-content-between align-content-center mb-1"
               v-if="Object.entries(itemList).length === 0">
            <empty-placeholder></empty-placeholder>
          </div>
          <div v-else>
            <div class=" justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('library.dataPool') }}
              </h6>
            </div>
            <div class="flex-column align-items-start"
                 v-for="item in itemList"
                 :key="item.id">
              <b-card border-variant="gray"
                      class="flex flex-row w-100 mb-1 justify-content-between align-items-lg-baseline align-baseline"
                      :title="item.title"
                      :sub-title="item.organizationName">
                <b-card-text>
                  <b-row>
                    <b-col cols="9">
                      <b-button v-b-tooltip.hover.bottom="item.private ? $t('document.onlyMe') : $t('document.public')"
                                variant="flat"
                                size="sm">
                        <feather-icon
                            :icon="item.private ? 'UserIcon' : 'UsersIcon'"
                            size="16"
                            class="align-middle text-body"
                        />
                      </b-button>
                      <b-button v-if="!item.section"
                                v-show="item.text !== null"
                                v-b-popover.hover.bottom="item.text"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                size="sm"
                                variant="flat-primary"
                                @click="doCopy(item.text)">
                        <feather-icon :icon="item.section ? 'ClipboardIcon' : 'FileTextIcon'"
                                      size="16"
                                      class="align-middle text-body">
                          {{ $t('section.viewTemplateText') }}
                        </feather-icon>
                      </b-button>
                    </b-col>

                    <b-col cols="3">
                      <b-dropdown
                          right
                          variant="link"
                          toggle-class="p-0">
                        <template #button-content>
                          <feather-icon
                              icon="MoreVerticalIcon"
                              size="20"
                              class="align-middle text-primary"
                          />
                        </template>
                        <b-dropdown-item
                            @click="openUpdateTitle(item)">
                          <span class="align-middle ml-50">{{ $t('library.rename') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="operable"
                            @click="useInSectionText(item)">
                          <span class="align-middle ml-50">{{ $t('library.addToText') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="document.lifecycleDetail.status.name === 'DRAFT'"
                            @click="createAsNewSection(item)">
                          <span class="align-middle ml-50">{{ $t('library.addAsNewSection') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="getUserId() === item.createdBy"
                            @click="deleteLibraryData(item)">
                          <span class="align-middle ml-50">{{ $t('library.remove') }}</span>
                        </b-dropdown-item>

                      </b-dropdown>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </div>
          </div>
        </b-card-text>
      </vue-perfect-scrollbar>
    </b-overlay>
    <b-modal
        ref="change-title-modal"
        hide-footer
        no-close-on-backdrop
        :title="$t('library.addToDataPool')">
      <validation-observer ref="saveLibraryData"
                           #default="{invalid}">
        <b-form @submit.prevent>
          <b-row align-h="start">
            <b-col cols="12">
              <b-form-group :label="$t('library.rename')"
                            label-for="library-item-title">
                <validation-provider #default="{ errors }"
                                     name="Title"
                                     vid="title"
                                     rules="required">
                  <b-form-input v-model="selectedItemTitle"
                                id="library-item-title"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="$t('template.context')"/>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ $t('required') }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group :label="$t('library.templateAuthQuestion')"
                            label-for="library-item-privacy">
                <b-form-checkbox v-model="selectedItemIsPrivate"
                                 checked="false"
                                 id="library-item-privacy"
                                 name="check-button"
                                 switch
                                 inline>
                  {{ $t('library.setPublic') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12" class="mt-1">
              <br/>
              <b-row align-h="end" class="px-1">
                <b-button @click="saveTitle()"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          :disabled="invalid"
                          variant="primary">
                  {{ $t('buttons.save') }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BModal,
  BOverlay,
  BRow,
  VBPopover,
  VBTooltip
} from 'bootstrap-vue'
import {mapState} from "vuex";
import {isDynamicRouteActive} from "@core/utils/utils";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import EmptyPlaceholder from "@/views/Documents/Editor/LibraryData/EmptyPlaceholder";
import {PEventBus} from "@/services/PEventBus";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import {getUserData} from '@/auth/utils'

export default {
  name: "LibraryDataList",
  components: {
    EmptyPlaceholder,
    BOverlay,
    BDropdown, BDropdownItem,
    BCardText,
    VuePerfectScrollbar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard
  },
  props: {
    documentId: String
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      sectionId: null,
      dataText: null,
      jsonContent: null,
      selectedItemTitle: null,
      selectedItemIsPrivate: null,
      selectedItemId: null,

      operable: false
    }
  },
  computed: {
    ...mapState({
      loading: state => state.library.loading,
      itemList: state => state.library.itemList,
      document: state => state.document.document
    }),
  },
  mounted() {
    PEventBus.$on('section-selected', (sectionId) => {
      this.sectionId = sectionId
      this.operable = true
    })
    PEventBus.$on('unselect-section', () => {
      this.sectionId = null
      this.operable = false
    })
    this.fetchLibraryData()
  },
  beforeDestroy() {
    PEventBus.$off('section-selected', (sectionId) => this.sectionId = sectionId)
    PEventBus.$off('unselect-section', () => this.sectionId = null)
  },
  methods: {
    fetchLibraryData() {
      this.$store.dispatch('library/fetchAll')
    },
    deleteLibraryData(template) {
      this.$store.dispatch('library/delete', template.id)
          .then(() => this.fetchLibraryData())
    },
    openUpdateTitle(item) {
      this.selectedItemTitle = item.title
      this.selectedItemId = item.id
      this.$refs['change-title-modal'].show()
    },
    saveTitle() {
      const payload = {
        title: this.selectedItemTitle,
        private: this.selectedItemIsPrivate,
      }
      this.$store.dispatch('library/updateTitle', {payload: payload, itemId: this.selectedItemId})
          .then((res) => {
            this.$refs['change-title-modal'].hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('messages.library.headerUpdated'),
                icon: 'EditIcon',
                variant: 'success',
              },
            }, {position: 'top-center'})
          })
    },
    useInSectionText(item) {
      const payload = {
        sectionId: this.sectionId,
        text: item.text
      }
      PEventBus.$emit('apply-selected-template', payload)
    },
    createAsNewSection(item) {
      PEventBus.$emit('apply-library-as-new-section', item)
    },
    getUserId() {
      return getUserData().userId
    },
    doCopy(text) {
      this.$copyText(text)
    }
  },

  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 100,
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style lang="scss" scoped>
.library-data-area {
  width: 333px;
}

.editor-left-side-icon-wrapper {
  .feather {
    color: red;
    height: 17px;
    width: 17px;
  }
}
</style>
