<template>
  <approval-timeline class="mt-1">
    <approval-timeline-item v-for="approver in approvers"
                            :key="approver.userId"
                            :title="approver.name"
                            :badge-text="approver.groupName"
                            :is-owner="true"
                            :subtitle="getStatusInfo(approver.approvalStatus)"
                            :icon="getStatusInfo(approver.approvalStatus).icon"
                            :time="getFormattedDate(approver.changedAt)"
                            :variant="getStatusInfo(approver.approvalStatus).variant"
                            :accessLevel="approver.accessLevel"
                            :user-id="approver.userId"
                            :section-id="sectionId"
                            :is-signer="approver.signer"
    />
  </approval-timeline>
</template>

<script>
import ApprovalTimeline from '@/views/Documents/Editor/Sections/Approvers/approval-timeline/ApprovalTimeline'
import ApprovalTimelineItem from '@/views/Documents/Editor/Sections/Approvers/approval-timeline/ApprovalTimelineItem'
import DateUtil from "@/services/DateUtil";
import i18n from "@/libs/i18n";

export default {
  name: "List",
  components: {
    ApprovalTimeline,
    ApprovalTimelineItem,
  },
  props: {
    sectionId: {
      type: String,
      default: null
    },
    approvers: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    getStatusInfo(status) {
      switch (status) {
        case 'WAITING':
          return {
            icon: 'ClockIcon',
            variant: "warning",
            text: i18n.t('section.lifecycle.waitingForApprove'),
            value: status
          };
        case 'RE_DEMANDED':
          return {
            icon: 'ClockIcon',
            variant: "warning",
            text: i18n.t('section.lifecycle.redemanded'),
            value: status
          };
        case 'APPROVED':
          return {icon: 'CheckIcon', variant: "success", text: i18n.t('section.lifecycle.approved'), value: status}
        case 'REJECTED':
          return {icon: 'XIcon', variant: "danger", text: i18n.t('section.lifecycle.rejected'), value: status}
        default:
          // code block
      }
      return {icon: 'HelpCircleIcon', variant: "gray"}
    }
  }
}
</script>

<style scoped>

</style>