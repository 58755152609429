<template>
  <div class="toc-list">
    <div v-if="loading">
      <loading :with-logo="false"></loading>
    </div>
    <vue-perfect-scrollbar
        v-else
        class=" h-100">
      <draggable
          v-model="sections"
          class="list-group list-group-flush cursor-pointer"
          :animation="500"
          tag="ul"
          :empty-insert-threshhold="500"
          @change="reorder"
          :move="handleMove">
        <transition-group type="transition" name="flip-list">
          <b-list-group-item
              v-for="(section, index) in sections" v-bind:key="section.sectionId"
              style="width: 100%; border-radius: 0; "
              class=" cursor-pointer py-0 pr-0"
              :class="[selectedSection.sectionId === section.sectionId ? 'selected' : '']"
              @click="selectSection(section)">
            <b-row class="w-95 m-0 d-flex py-25 px-0">
              <b-col md="10" class="justify-content-start my-auto">
                  <span
                      class="truncate editable-input d-inline-block  cursor-move"
                      v-show="!loading"
                      @keydown="handleEditableKeyPress"
                      @blur="updateTitle"
                      v-on:keyup.enter="$event.target.blur()"
                      v-on:keydown="onKeydown"
                      v-text="`${index + 1}. ` + section.header">
                  </span>

                <div class="justify-content-start align-items-start">
                  <b-badge variant="light-info" class="version-badge mr-25 p-25" :key="section.version">
                    v{{ section.version }}
                  </b-badge>
                  <div v-for="group in section.groups" v-bind:key="group.id" style="padding: 0">
                    <small>{{ group.name }}</small>
                  </div>
                  <small class="font-weight-bold text-black-50">{{ section.status.displayText }}</small>
                </div>
              </b-col>
              <b-col md="2" class="ml-auto float-right">
                <b-nav-item-dropdown
                    no-caret
                    right
                    class=" actions p-0 m-0"
                    style="list-style: none; z-index: 21;">
                  <template #button-content>
                    <v-icon name="ellipsis-h" style="color:rgba(9, 30, 66, 0.3); width: auto; height: 1.1em "></v-icon>
                  </template>
                  <b-dropdown-item
                      v-b-tooltip.hover.top="$t('section.approve')"
                      variant="flat-success"
                      v-if="section.approver && (section.approvalStatus === 'WAITING' || section.approvalStatus === 'RE_DEMANDED') && document.lifecycleDetail.status.name === 'WAITING_TO_APPROVE'"
                      @click="approve(section.sectionId)">
                    <feather-icon size="18"
                                  icon="CheckIcon"
                                  class="mr-50"/>
                    <span class="font-weight-bold">{{ $t('accept') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                      v-b-tooltip.hover.top="$t('reject')"
                      variant="flat-success"
                      v-if="section.approver && (section.approvalStatus === 'WAITING' || section.approvalStatus === 'RE_DEMANDED') && document.lifecycleDetail.status.name === 'WAITING_TO_APPROVE'"
                      @click="showRejectModal(section.sectionId)">
                    <feather-icon size="18"
                                  icon="XIcon"
                                  class="mr-50"/>
                    <span class="font-weight-bold">{{ $t('table.reject') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                      @click="chooseApprover(section.sectionId)">
                    <feather-icon size="18"
                                  icon="UserPlusIcon"
                                  class="mr-50"/>
                    <span class="font-weight-bold">{{ $t('section.addUser') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-divider/>
                  <b-dropdown-item
                      :disabled="section.accessLevel !== 'CREATOR' || section.status.name !== 'DRAFT' || document.lifecycleDetail.status.name !== 'DRAFT'"
                      @click="removeNumeration(section.sectionId)">
                    <span class="font-weight-bold ml-50">{{ $t('section.removeNumbering') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                      :disabled="section.accessLevel !== 'CREATOR' || section.status.name !== 'DRAFT' || document.lifecycleDetail.status.name !== 'DRAFT'"
                      @click="removeNumeration(section.sectionId)">
                    <span class="font-weight-bold ml-50">{{ $t('section.removeSectionTitle') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-divider/>
                  <b-dropdown-item
                      v-if="section.accessLevel === 'CREATOR' && !isLocked(section.status)"
                      v-b-tooltip.hover.top="$t('section.delete')"
                      :disabled="(section.accessLevel === 'WRITE' || section.accessLevel === 'READ') || section.status.name !== 'DRAFT' || document.lifecycleDetail.status.name !== 'DRAFT'"
                      @click="removeSection(section.sectionId)">
                    <feather-icon size="18"
                                  icon="TrashIcon"
                                  class="mr-50"/>
                    <span class="font-weight-bold">{{ $t('section.delete') }}</span>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </b-col>
            </b-row>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </vue-perfect-scrollbar>
    <b-modal lazy
             size="lg"
             ref="section-reject-modal"
             hide-footer
             no-close-on-backdrop
             :title="$t('section.rejectSection')">
      <section-reject-modal :document-id="documentId" :section-id="selectedSection.sectionId" @hide="hideRejectModal"/>
    </b-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {isDynamicRouteActive} from "@core/utils/utils";
import draggable from "vuedraggable";
import {
  BBadge,
  BCol,
  BDropdownDivider,
  BDropdownItem,
  BListGroupItem,
  BModal,
  BNavItemDropdown,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import {mapState} from "vuex";
import {PEventBus} from "@/services/PEventBus";
import Ripple from "vue-ripple-directive";
import Loading from "@/views/components/Loading/Loading";
import {getUserData} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import SectionRejectModal from "@/views/Documents/Editor/Sections/Approvers/SectionRejectModal"

export default {
  name: "index",
  props: {
    documentId: String,
    documentType: Object,
    workspaceId: String
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    Loading,
    BBadge,
    BDropdownDivider,
    draggable,
    BModal,
    BNavItemDropdown,
    BRow, BCol,
    VuePerfectScrollbar,
    BListGroupItem,
    SectionRejectModal,
    BDropdownItem,
  },
  computed: {
    ...mapState({
      templates: state => state.template.templates,
      loadingTemplates: state => state.template.loading,
      workspaces: state => state.workspaces.myWorkspaces,
      selectedSection: state => state.document.selectedSectionStatus,
      document: state => state.document.document
    }),
    sections: {
      get() {
        return this.$store.getters["document/getSections"];
      },
      set(value) {
        this.$store.commit("document/UPDATE_LIST", value);
      },
    },
  },
  data() {
    return {
      documentTypeLoading: false,
      loading: false,
    }
  },
  beforeCreate() {
    this.loading = true
  },
  mounted() {
    this.fetchMyWorkspaces()
    this.loading = false
  },
  methods: {
    isLocked(status) {
      return status.name === 'LOCKED'
    },
    onWorkspaceSelect(workspace) {
      console.log(workspace)
    },
    fetchMyWorkspaces() {
      this.$store.dispatch('workspaces/fetchOrganizations')
    },
    addLabel(label) {
      this.$store.dispatch('document/addToLabels', label);
    },
    removeLabel(label) {
      this.$store.dispatch('document/removeFromLabels', label);
    },
    reorder() {
      this.$store.dispatch('document/reorderSections')
      // this.notify(i18n.t("messages.section.updatedSequence"));
      // this.fail();
    },
    selectSection(section) {
      if (this.selectedSection.sectionId === section.sectionId) {
        PEventBus.$emit(`${section.sectionId}-unselect-section`)
      } else {
        PEventBus.$emit(`${section.sectionId}-select-section`)
      }
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    removeSection(sectionId) {
      PEventBus.$emit('delete-section', sectionId)
    },
    chooseApprover(sectionId) {
      this.$store.dispatch('section/fetchAllApprovers', sectionId)
      this.$store.commit("document/SET_APPROVER_SELECTION", {toggle: true, sectionId: sectionId})
    },
    approve(sectionId) {
      const payload = {
        documentId: this.documentIdFromUrl(),
        sectionId: sectionId
      }

      this.$store.dispatch('reviews/approve', payload)
          .then(() => PEventBus.$emit("reload-document"))
          .then(() => PEventBus.$emit("load-sections"))
          .then(() => this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.section.approved'),
              icon: 'CheckIcon',
              variant: 'success',
            },
          }, {position: 'top-center'}))
    },
    showRejectModal(sectionId) {
      this.$refs["section-reject-modal"].show()
    },

    hideRejectModal() {
      this.$refs["section-reject-modal"].hide()
    },
    removeNumeration(sectionId) {
      PEventBus.$emit('remove-section-header-numeration', sectionId)
    },
    getUserId() {
      return getUserData().userId
    },
    handleMove() {
      return this.document.owner.id === this.getUserId()
    },
    handleEditableKeyPress(e) {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    },
    updateTitle(event) {
      this.$store.dispatch('section/updateHeader', {
        sectionId: this.selectedSection.sectionId,
        header: event.target.textContent
      }).then(res => {
      })
    },
    onKeydown(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 100,
    }
    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style lang="scss" scoped>
[dir] .list-group-item {
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    background-color: rgba(9, 30, 66, 0.04);

    .draggable-item {
      visibility: visible;
    }

    .actions {
      visibility: visible;
    }
  }
}
.version-badge  {
  background-color: whitesmoke;
  color:white;
  animation: onchange 1s;
}
@keyframes onchange {
  from {
    background-color: #D0D4DB;
  }
  to {
    background-color: #59E0F0;
  }
}
.selected {
  background-color: rgba(9, 30, 66, 0.08);
}

.toc-list {
  width: 100%;
}

.toc-list .draggable-item {
  visibility: hidden;
}

.toc-list .actions {
  visibility: hidden;
}

.editable-input {
  text-overflow: ellipsis;
  text-wrap: none;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  display: inline;
  font-weight: 600;
  color: #4b4b4b;
  font-size: 1.1em;

  br {
    display: none;
  }
}

.dropdown-toggle::after {
  border: none;
}
</style>
