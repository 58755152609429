<template>
  <validation-observer ref="StopApprovalProcessFormRef"
                       #default="{invalid}">
    <b-form @submit.prevent>
      <b-row align-h="start">
        <b-col v-if="loadingApprovers" cols="12">
          <div class="mx-15 my-2">
            <b-spinner variant="primary" size="sm"/>
            <strong class="ml-1">{{ $t('loading') }}</strong>
          </div>
        </b-col>
        <b-col cols="12" v-else>
          <b-badge pill
                   v-if="!anySignerExists"
                   variant="light-danger"
                   class="mb-2">
            {{ $t('document.lifecycle.noSigner') }}
          </b-badge>
          <h5 class="mb-1">
            {{ $t('document.lifecycle.startApproveProcessQuestion') }}
          </h5>
          <b-table
              :items="approvers"
              :small="false"
              :fields="tableColumns"
              primary-key="id"
              :hover="true"
              :head-variant="null"
              show-empty
              :empty-text="$t('document.noDocumentFoundForCriteria')"
              class="position-relative">
          </b-table>
          <h5>
            {{ $t('document.lifecycle.approveProcessWarning') }}
          </h5>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <br/>
          <b-row align-h="end" class="px-1">
            <b-button @click="start"
                      style="width: 100px"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="start"
                      :disabled="invalid"
                      variant="danger">
              <b-spinner small v-if="processing"/>
              <span v-else> {{ $t('document.lifecycle.start') }}</span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {BBadge, BButton, BCol, BForm, BRow, BSpinner, BTable, VBTooltip} from "bootstrap-vue";
import {ValidationObserver} from "vee-validate";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";

export default {
  name: "StartApprovalProcessModal",
  components: {
    BRow,
    BCol,
    BForm,
    BTable,
    BButton,
    BSpinner,
    BBadge,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    documentId: String
  },
  data() {
    return {
      processing: false,
      approvers: [],
      tableColumns: [
        {key: 'name', label: i18n.t('table.approver')},
        {key: 'groupName', label: i18n.t('table.group')},
        {key: 'accessLevel', label: i18n.t('table.authority')},
        {key: 'sectionHeader', label: i18n.t('table.section')},
        {key: 'signer', label: i18n.t('table.signAuthority')},
      ],
      loadingApprovers: false,
      anySignerExists: false,
    }
  },
  mounted() {
    this.fetchApprovers()
  },
  methods: {
    start() {
      this.processing = true
      this.$store.dispatch('documentLifecycle/startApprovalProcess', this.documentId)
          .then((res) => {
            this.processing = false
            this.success()
            this.$emit('refetchDocument')
            this.$emit('close')
            this.fetchDocumentReminders()
          })
          .catch((err) => {
            this.processing = false
            this.$emit('close')
            this.fail(err)
          })
    },
    fetchDocumentReminders() {
      this.$store.dispatch('document/getDocumentHistory', this.documentId);
    },
    fetchApprovers() {
      this.loadingApprovers = true
      this.$store.dispatch('document/fetchApprovers', this.documentId)
          .then(approvers => {
            Object.keys(approvers).forEach(key => {
              approvers[key].approverList.forEach(approver => {
                let a = {
                  name: approver.name,
                  groupName: approver.groupName,
                  accessLevel: this.mapAccessLevel(approver.accessLevel),
                  sectionHeader: approvers[key].sectionHeader,
                  signer: approver.signer ? i18n.t('exists') : i18n.t('notExists')
                }
                this.approvers.push(a)

                this.anySignerExists = approver.signer ? approver.signer : this.anySignerExists
              })
            })
            this.loadingApprovers = false
          })
    },
    success() {
      let icon = 'CheckIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.document.startedApproveProcess'),
              icon,
              text: i18n.t('messages.document.startedApproveProcessDescriptionMessage'),
              variant: 'info',
            },
          }, {position: 'top-center'}
      )
    },
    fail(err) {
      let icon = 'AlertTriangleIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.notification'),
              icon,
              text: `${err.data.message}`,
              variant: 'danger',
            },
          }, {position: 'top-center'}
      )
    },
    mapAccessLevel(accessLevel) {
      return accessLevel === 'READ' ? i18n.t('statics.readOnly')
          : accessLevel === 'WRITE' ? i18n.t('statics.editor')
              : accessLevel === 'APPROVER' ? i18n.t('statics.approver')
                  : accessLevel === 'CREATOR' ? i18n.t('statics.creator')
                      : accessLevel
    }
  }
}
</script>

<style scoped>

</style>