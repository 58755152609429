import _Quill from "quill";

const Quill = window.Quill || _Quill

import {PEventBus} from "@/services/PEventBus";

const Embed = Quill.import('blots/embed');
import store from '@/store'

class DynamicVariableBlot extends Embed {
    static create(data) {
        const node = super.create();

        console.log(data)

        const userInputWrapper = document.createElement("span");

        const divElement = document.createElement("div")
        divElement.className = "ql-custom-tooltip";
        // //
        const tooltipElement = document.createElement("span")
        tooltipElement.className = "tooltiptext";
        tooltipElement.innerHTML = data.value;

        divElement.appendChild(tooltipElement)

        if (data.userInput != null) {
            userInputWrapper.className = 'mention-userInput';
            userInputWrapper.classList.add(data['color'])
            userInputWrapper.innerHTML = data.displayText;
            divElement.appendChild(userInputWrapper)
            node.appendChild(divElement);
        }

        PEventBus.$on(`${data.id}-variable-updated`, (updatedValue) => {
            userInputWrapper.className = "mention-userInput";
            userInputWrapper.classList.add(data['color'])
            userInputWrapper.innerHTML = updatedValue
            divElement.appendChild(userInputWrapper)
            node.appendChild(divElement);
        });

        if (!data['readonly'] || data['readonly'] === 'false') {
            userInputWrapper.addEventListener('click', function (e) {
                e.stopPropagation()
                e.preventDefault()
                // e.target should always be node since it is attached to node
                let blot = Quill.find(node);
                PEventBus.$emit(`${data['sectionId']}-variable-clicked`, blot, data)
            });
        }

        return DynamicVariableBlot.setDataValues(node, data);
    }

    static setDataValues(element, data) {
        const domNode = element;
        Object.keys(data).forEach(key => {
            domNode.dataset[key] = data[key];
        });
        return domNode;
    }

    static value(domNode) {
        return domNode.dataset;
    }

    remove() {
        console.log('remove')
        PEventBus.$emit(`${this.domNode.dataset.sectionId}-remove-variable`, this.domNode.dataset)
        super.remove()
    }

    attach() {
        super.attach();
        if (!this.mounted) {
            this.mounted = true;
            this.scroll.domNode.dispatchEvent(new CustomEvent('blot-mounted', {
                bubbles: true,
                detail: this
            }))
        }
    }

    detach() {
        console.log('detach')
        this.mounted = false;
        this.domNode.dispatchEvent(new CustomEvent('blot-unmounted', {
            bubbles: true,
            detail: this.domNode.dataset
        }))
        super.detach()
    }
}

DynamicVariableBlot.blotName = "mention";
DynamicVariableBlot.tagName = "span";
DynamicVariableBlot.className = "mention";

Quill.register(DynamicVariableBlot)
