<template>
  <div>
    <b-modal ref="save-as-template-modal"
             hide-footer
             no-close-on-backdrop
             :title="$t('document.createTemplateFromDocument')">
      <validation-observer ref="saveLibraryData"
                           #default="{invalid}">
        <b-form @submit.prevent>
          <b-row align-h="start">
            <b-col v-if="documentType === ''">
              <small class="text-danger">{{ $t('document.documentRequiresType') }}</small>
            </b-col>
            <!-- document title -->
            <b-col cols="12">
              <b-form-group :label="$t('document.enterName')"
                            label-for="fh-doc-name">
                <validation-provider #default="{ errors }"
                                     name="Title"
                                     vid="title"
                                     rules="required">
                  <b-form-input v-model="title"
                                id="fh-doc-name"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="$t('template.context')"/>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ $t('required') }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group class="mt-1 mb-2"
                            :label="$t('document.chooseTemplateUsers')"
                            label-for="fh-doc-type">
                <v-select v-model="selectedTemplateUsageType"
                          :options="templateTypeOptions"
                          label="name"
                          :placeholder="$t('document.templateUsage')">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col v-if="selectedTemplateUsageType !== null && selectedTemplateUsageType.id === 3" cols="12">
              <b-form-group class="mt-1 mb-2"
                            :label="$t('template.organization')"
                            label-for="fh-doc-type">
                <v-select v-model="selectedOrganization"
                          :options="organizations"
                          label="name"
                          :placeholder="$t('organization.context')">
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <br/>
              <b-row align-h="end" class="px-1">
                <b-button @click="createFromDocument"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          :disabled="invalid || documentType === ''"
                          variant="primary">
                  <b-spinner small v-if="loading"/>
                  <span v-else>{{ $t('buttons.create') }}</span>
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BForm, BFormGroup, BFormInput, BModal, BRow, BSpinner,} from "bootstrap-vue";
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {PEventBus} from "@/services/PEventBus";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";

export default {
  name: "index",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    Ripple,
  },
  props: {
    documentId: String,
    documentType: String
  },
  data() {
    return {
      loading: false,
      title: null,
      isPublic: false,
      selectedOrganization: null,
      selectedTemplateUsageType: null,
      thumbnailImage: null,
      templateTypeOptions: [
        {
          id: 1,
          name: i18n.t('document.onlyMe')
        },
        {
          id: 2,
          name: i18n.t('document.public')
        }
      ]
    }
  },
  computed: {
    ...mapState({
      organizations: state => state.workspaces.myWorkspaces
    }),
    validation() {
      return true
    },
    validationOccupation() {
      return true
    },
  },
  mounted() {
    PEventBus.$on('handle-add-template', this.handleAddTemplate)
    PEventBus.$on('show-create-template-modal', this.handleShowModal)
  },
  destroyed() {
    PEventBus.$off('show-create-template-modal', this.handleShowModal)
  },
  created() {
    this.getTemplateTypeOptions()
  },
  methods: {
    getTemplateTypeOptions() {
      if (this.organizations.length > 0) {
        this.templateTypeOptions.push({
          id: 3,
          name: i18n.t('document.chosenWorkspaces')
        })
      }
    },
    handleShowModal(title) {
      this.title = title
      this.$refs['save-as-template-modal'].show()
    },
    createFromDocument() {
      this.loading = true
      setTimeout(() => {
        this.takeThumbnail()
            .then(() => {
              const request = {
                organizationId: this.selectedOrganization === null ? null : this.selectedOrganization.organizationId,
                documentId: this.documentId,
                title: this.title,
                isPublic: this.selectedTemplateUsageType === null ? false : this.selectedTemplateUsageType.id === 2,
                thumbnailImage: this.thumbnailImage
              }
              this.$store.dispatch('template/createFromDocument', request)
                  .then(() => {
                    this.loading = false
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: i18n.t('messages.template.created'),
                        icon: 'LayoutIcon',
                        variant: 'success',
                      },
                    }, {position: 'top-center'})
                    this.$refs['save-as-template-modal'].hide()
                  })
            })

      }, 20);
    },
    async takeThumbnail() {
      //const el = this.$parent.$refs.$refs.editorCenter
      const el = document.getElementById('editor-area')
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.

      this.thumbnailImage = await this.$html2canvas(el)
      this.thumbnailImage = this.thumbnailImage.toDataURL('image/png', 0.1)
          .replace("image/png", "image/octet-stream")
      //this.$store.dispatch('document/updateThumbnail', this.output)
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>

</style>