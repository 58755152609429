<template>
  <div>
    <b-overlay
        id="loading-document-type"
        :show="loading"
        :variant="'light'"
        :opacity="0.65"
        rounded="sm">
      <vue-simple-suggest
          pattern="\w+"
          v-model="input"
          :list="queriedLabels"
          :max-suggestions="10"
          :min-length="2"
          :debounce="200"
          :filter-by-query="false"
          :prevent-submit="true"
          :controls="{
                  selectionUp: [38, 33],
                  selectionDown: [40, 34],
                  select: [13, 36],
                  hideList: [27, 35]
                }"
          mode="input"
          :nullable-select="true"
          ref="suggestComponent"
          :placeholder="$t('document.linkDocument')"
          value-attribute="documentId"
          display-attribute="documentName"
          @select="onSuggestSelect">

        <template slot="misc-item-above" slot-scope="{ suggestions, query }">

          <div class="misc-item">
            <span>{{$t('document.searching')}} '{{ query }}'.</span>
          </div>

          <template v-if="suggestions.length > 0">
            <div class="misc-item">
              <span>{{ suggestions.length }} {{$t('document.suggestions')}}.</span>
            </div>
            <hr>
          </template>

          <div class="flex justify-between misc-item" v-else-if="!loading">
            <span>{{$t('document.noResult')}}</span>
          </div>
        </template>

        <div slot="suggestion-item" slot-scope="scope"
             :title="scope.suggestion.documentName + ' / ' + scope.suggestion.documentCode">
          <div class="text">
            <span v-html="boldenSuggestion(scope) + ' / ' + scope.suggestion.documentCode"></span>
          </div>
        </div>
      </vue-simple-suggest>
      <p-label class="mt-1" @remove-label="removeLabel" hide-remove></p-label>
    </b-overlay>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import PLabel from "./Label";
import {BOverlay} from 'bootstrap-vue'

export default {
  components: {VueSimpleSuggest, PLabel, BOverlay},
  props: {
    documentId: {
      type: String
    }
  },
  computed: {
    queriedLabels() {
      return this.$store.state.document.queryDocResult
    }
  },
  data() {
    return {
      linkedDocumentId: null,
      header: null,

      loading: false,
      input: null
    }
  },
  watch: {
    input: {
      immediate: true,
      deep: true,
      handler(val, oldValue) {
        if (val != null && val.length > 2) {
          this.queryDocuments(val)
        }
      }
    }
  },
  methods: {
    queryDocuments(queryText) {
      const payload = {
        documentId: this.documentId,
        query: queryText
      }

      this.loading = true
      this.$store.dispatch('document/simpleQuery', payload)
      this.loading = false
    },
    removeLabel(labelToBeRemoved) {
      this.loading = true
      this.$store.dispatch('do/removeLabel', {
        documentId: this.documentId,
        label: labelToBeRemoved
      }).then(() => {
        this.$emit('removeLabelFromDocument', labelToBeRemoved)
        this.loading = false
      })
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const {suggestion, query} = scope;
      let result = this.$refs.suggestComponent.displayProperty(suggestion);
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [''];
      return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3');
    },
    onSuggestSelect(suggest) {
      this.$emit('select', suggest)
    },
  }
}
</script>

