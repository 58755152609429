<template>
  <div class="attachments-area">
    <b-overlay
        :show="loading"
        :variant="'light'"
        class="h-100"
        :opacity="0.65"
        rounded="sm">
      <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="h-100 ">
        <b-form-file v-model="waitingToUploadAttachments"
                     id="fileInputId"
                     class="hidden"
                     multiple
                     @change="setFile"
                     :placeholder="$t('document.dragFileHere')"
                     :drop-placeholder="$t('document.dropHere')"/>
        <div class="p-1 justify-content-between align-content-center mb-1"
             v-if="!loading && Object.entries(attachments).length === 0 && Object.entries(waitingToUploadAttachments).length === 0">
          <empty-placeholder @show-files="clickFileInput"></empty-placeholder>
        </div>
        <div v-if="Object.entries(attachments).length > 0 || Object.entries(waitingToUploadAttachments).length > 0"
             class="py-1 file-list-area">
          <div class="justify-content-start pl-2 pb-2 w-100 d-flex mt-2">
            <b-button style="height: 40px; "
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      @click="clickFileInput"
                      class=" btn-sm-block  elevated-btn small px-2 "
                      variant="primary">
              {{ $t('add') }}
            </b-button>
          </div>

          <div v-if="Object.entries(waitingToUploadAttachments).length === 0" class="w-100 px-2">
            <b-list-group-item
                v-for="attachment in attachments"
                :key="attachment.id"
                class="flex flex-row w-100 mt-1 justify-content-start mt-2 p-0">
              <b-row>
                <b-col lg="10">
                  <strong class="text-black">{{ attachment.filename }}</strong>
                  <br/>
                  <small class="align-middle">{{ getFormattedTime(attachment.createdAt) }}</small>
                  <br/>
                  <small class="align-middle">{{ getFileType(attachment.fileType) }}</small>
                </b-col>
                <b-col lg="2">
                  <b-dropdown
                      right
                      variant="link"
                      toggle-class="p-0">
                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="20"
                          class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                        @click="getOne(attachment.attachmentId)">
                      <span class="align-middle">{{ $t('attachment.open') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="deleteAttachment(attachment.attachmentId)">
                      <span class="align-middle">{{ $t('attachment.remove') }}</span>
                    </b-dropdown-item>

                  </b-dropdown>
                </b-col>
              </b-row>
            </b-list-group-item>
          </div>
          <div v-else class="w-100 px-2">
            <b-form-input
                v-model="title"
                :placeholder="$t('document.attachmentsDescription')"/>
            <b-list-group-item
                v-for="attachment in waitingToUploadAttachments"
                :key="attachment.id"
                class="flex flex-row w-100 mt-1 justify-content-start mt-2 p-0">
              <strong class="text-dark">{{ attachment.name }}</strong>
              <br/>
              <small class="align-items-start text-black-50">{{ attachment.type }}, {{
                  getSize(attachment.size)

                }}</small>
            </b-list-group-item>
            <div class="w-100 d-flex mt-2 justify-content-end">
              <b-button
                  @click="removeFiles"
                  pill class="px-2 mr-1 py-50"
                  variant="flat-danger">
                {{ $t('cancel') }}
              </b-button>
              <b-button
                  @click="uploadAttachments"
                  pill class="px-2 py-50">
                {{ $t('upload') }}
              </b-button>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </b-overlay>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BFormInput,
  BListGroupItem,
  BOverlay,
  BRow,
  VBPopover
} from 'bootstrap-vue'
import {mapState} from "vuex";
import {isDynamicRouteActive} from "@core/utils/utils";
import Ripple from 'vue-ripple-directive'
import EmptyPlaceholder from "@/views/Documents/Editor/Attachment/EmptyPlaceholder";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from "@vue/composition-api";
import DateUtil from '@/services/DateUtil'
import {PEventBus} from "@/services/PEventBus";
import {MiscUtils} from "@/services/Utils";

export default {
  name: "LibraryDataList",
  components: {
    EmptyPlaceholder,
    BOverlay,
    BFormFile,
    BDropdown, BDropdownItem,
    VuePerfectScrollbar,
    BListGroupItem,
    BFormInput,
    BRow,
    BButton,
    BCol,
  },
  props: {
    documentId: String
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      sectionId: null,
      dataText: null,
      jsonContent: null,
      selectedItemTitle: null,
      selectedItemIsPrivate: null,
      selectedItemId: null,
      waitingToUploadAttachments: [],
      title: null,
      codeDragDrop: null
    }
  },
  computed: {
    ...mapState({
      loading: state => state.attachment.loading,
      attachments: state => state.attachment.attachments,
      document: state => state.document.document
    }),
  },
  watch: {
    file: {
      handler(newVal, oldVal) {

      }
    }
  },
  mounted() {
    this.fetchAttachments()
  },
  methods: {
    getSize(bytes) {
      return MiscUtils.formatBytes(bytes)
    },
    clickFileInput() {
      document.getElementById('fileInputId').click()
    },
    setFile(event) {
      this.waitingToUploadAttachments = event.target.files;
    },
    getFormattedTime(time) {
      return DateUtil.getFormattedTime(time)
    },
    getFileType(fileType) {
      return fileType === 'ATTACHMENT' ? 'Ek Dosya' :
          fileType === 'DIGITAL_COPY' ? 'Dijital Kopya' :
              fileType === 'WET_SIGNED' ? 'Islak İmzalı Kopya' : 'Geçici'
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    fetchAttachments() {
      this.$store.dispatch('attachment/fetchAll', this.documentIdFromUrl())
    },
    getOne(attachmentId) {
      this.$store.dispatch('attachment/fetchById', attachmentId)
    },
    removeFiles() {
      this.waitingToUploadAttachments = []
    },
    deleteAttachment(attachmentId) {
      this.$store.dispatch('attachment/delete', attachmentId)
          .then(() => this.fetchAttachments())
          .then(() => this.fetchDocumentBadges())
    },
    uploadAttachments() {

      const formData = {
        documentId: this.documentIdFromUrl(),
        title: this.title,
        file: this.waitingToUploadAttachments[0],
      }

      this.$store.dispatch('attachment/addAttachment', formData)
          .then(() => PEventBus.$emit("reload-document"))
          .then(() => {
            this.removeFiles()
            this.fetchAttachments()
            this.fetchDocumentBadges()
          })
    },
    fetchDocumentBadges() {
      this.$store.dispatch('document/fetchDocumentStatistics', this.documentIdFromUrl())
    },

  },

  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 100,
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss" scoped>
.attachments-area {
  width: 100%;
  overflow-y: hidden;
}

.file-list-area {
  height: 90vh;
}


.editor-left-side-icon-wrapper {
  .feather {
    color: red;
    height: 17px;
    width: 17px;
  }
}
</style>
