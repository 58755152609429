<template>
  <div class="history-list-area">
    <vue-perfect-scrollbar class="scroll-area mb-1 h-100">
      <div class="py-1">
        <b-col>
          <app-timeline>
            <app-timeline-item v-for="(item,index) in history"
                               v-bind:key="index"
                               class="w-100">
              <div class="w-100">
                <b-row class="pl-1 pr-1">
                  <div class="d-flex flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <span style="font-size: 19px; font-weight: bold" v-text="$t('documentStates.'.concat(item.changeTo))"/>
                    <h6 v-text="$t('documentStates.'.concat(item.changeFrom))"/>
                  </div>

                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-25 mb-sm-0">
                    <small v-b-tooltip.hover.bottom="getFormattedDate(item.changedAt)"
                           class="timeline-item-time text-wrap text-muted"
                           v-text="getUpdatedAtMessage(item)"/>
                    <b-badge v-if="item.note !== undefined"
                             class="text-truncate"
                             variant="light-info">
                      {{$t('section.rejectReason')}} {{ item.note }}
                    </b-badge>
                  </div>
                </b-row>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {BBadge, BCol, BRow, VBTooltip} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import DateUtil from '@/services/DateUtil'
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import i18n from "@/libs/i18n";

export default {
  name: "List",
  components: {
    BRow,
    BCol,
    BBadge,
    VuePerfectScrollbar,
    AppTimeline,
    AppTimelineItem
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    ...mapState({
      history: state => state.document.history,
      documentStatus: state => state.document.document.lifecycleDetail.status.name
    })
  },
  created() {
    this.fetchDocumentReminders()
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    fetchDocumentReminders() {
      this.$store.dispatch('document/getDocumentHistory', this.documentIdFromUrl());
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    getFromNow(date) {
      return DateUtil.fromNow(date);
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    getUpdatedAtMessage(item) {
      return i18n.tc('document.updatedBy', item.changedBy, {
        user: item.changedBy,
        time: this.getFromNow(item.changedAt)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
// Bootstrap includes
@import '~@core/scss/base/components/include';
// Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;

          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }

        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;

  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

.history-list-area {
  width: 100%;
  background-color: transparent;
}

</style>
