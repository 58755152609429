var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"id":"loading-document-type","show":_vm.loading,"variant":'light',"opacity":0.65,"rounded":"sm"}},[_c('vue-simple-suggest',{ref:"suggestComponent",attrs:{"pattern":"\\w+","list":_vm.queriedLabels,"max-suggestions":10,"min-length":2,"debounce":200,"filter-by-query":false,"prevent-submit":true,"controls":{
                selectionUp: [38, 33],
                selectionDown: [40, 34],
                select: [13, 36],
                hideList: [27, 35]
              },"mode":"input","nullable-select":true,"placeholder":_vm.$t('document.linkDocument'),"value-attribute":"documentId","display-attribute":"documentName"},on:{"select":_vm.onSuggestSelect},scopedSlots:_vm._u([{key:"misc-item-above",fn:function(ref){
              var suggestions = ref.suggestions;
              var query = ref.query;
return [_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('document.searching'))+" '"+_vm._s(query)+"'.")])]),(suggestions.length > 0)?[_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v(_vm._s(suggestions.length)+" "+_vm._s(_vm.$t('document.suggestions'))+".")])]),_c('hr')]:(!_vm.loading)?_c('div',{staticClass:"flex justify-between misc-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('document.noResult')))])]):_vm._e()]}},{key:"suggestion-item",fn:function(scope){return _c('div',{attrs:{"title":scope.suggestion.documentName + ' / ' + scope.suggestion.documentCode}},[_c('div',{staticClass:"text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.boldenSuggestion(scope) + ' / ' + scope.suggestion.documentCode)}})])])}}]),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_c('p-label',{staticClass:"mt-1",attrs:{"hide-remove":""},on:{"remove-label":_vm.removeLabel}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }