import _Quill from "quill";
import {PEventBus} from "@/services/PEventBus";

const Quill = window.Quill || _Quill

const Embed = Quill.import('blots/embed');

class PapirListItem extends Embed {
    static create(value) {
        const node = super.create();

        node.dataset.text = value.text
        node.dataset.index = value.parentIndex
        node.dataset.lineIndex = value.lineIndex
        node.dataset.headingClass = value.headingClass
        node.dataset.numbering = value.numbering
        node.dataset.level = value.level

        const labelNode = document.createTextNode(value.numbering)

        const wrapper = document.createElement('span')

        node.addEventListener('keydown', (event) => {
            console.log(event)
            if (event.key === 'Backspace') {
                //your condition check can be given here
                if (confirm('are you sure you want to delete')) {
                    return;
                } else {
                    event.preventDefault();
                }
            }
        })
        node.addEventListener('click', (event) => {
            console.log(event)

        })
        wrapper.setAttribute('contenteditable', 'false')
        wrapper.style.fontWeight = 'bolder'
        wrapper.appendChild(labelNode)

        node.appendChild(wrapper)

        return PapirListItem.setDataValues(node, value);
    }

    static value(domNode) {
        return domNode.dataset;
    }

    remove() {
        console.log('remove')
        super.format
        super.remove()
    }

    static setDataValues(element, data) {
        const domNode = element;
        Object.keys(data).forEach(key => {
            domNode.dataset[key] = data[key];
        });
        return domNode;
    }
}

PapirListItem.blotName = 'papirNumbering';
PapirListItem.className = 'numberingStyle'
PapirListItem.tagName = 'span';

Quill.register(PapirListItem)
