<template>
  <div>
    <div class="row m-0 w-100 d-flex mb-2">
      <div class="col-md-1 pr-1 w-100 p-0">
        <vue-skeleton-loader
            type="rect"
            class=" w-100"
            :height="15"
            animation="fade">
        </vue-skeleton-loader>
      </div>
      <div class="col-md-11 w-100 p-0">
        <vue-skeleton-loader
            type="rect"
            class="w-100"
            :height="15"
            animation="fade">
        </vue-skeleton-loader>
      </div>
    </div>
    <vue-skeleton-loader
        type="rect"
        class="w-100"
        :height="15"
        animation="fade">
    </vue-skeleton-loader>
    <div class="row m-0 w-100 d-flex mt-50">
      <div class="col-md-4 w-100 p-0">
        <vue-skeleton-loader
            type="rect"
            class="w-100"
            :height="15"
            animation="fade">
        </vue-skeleton-loader>
      </div>
      <div class="col-md-8 w-100">
        <vue-skeleton-loader
            type="rect"
            class="w-100"
            :height="15"
            animation="fade">
        </vue-skeleton-loader>
      </div>
    </div>
    <vue-skeleton-loader
        type="rect"
        :width="400"
        :height="15"
        class="mt-50"
        animation="fade">
    </vue-skeleton-loader>
    <vue-skeleton-loader
        type="rect"
        :width="600"
        :height="15"
        class="mt-1"
        animation="fade">
    </vue-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "LoadingPlaceholder"
}
</script>

<style scoped>

</style>
