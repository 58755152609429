<template>
  <div>
    <div class="row align-items-center editor-nav" style="box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);">
      <slot name="header"></slot>
    </div>
    <div class="row p-0 m-0">
      <div class="col flex justify-content-center center-area">
        <slot name="content-area"/>
      </div>
      <div class="right-side" >
        <slot name="version-list"/>
      </div>
    </div>
  </div>
</template>

<script>
import {BModal} from 'bootstrap-vue'

export default {
  name: "VersionsViewLayout",
  components: {},
  mounted() {
  }
}
</script>

<style lang="css" scoped>

.center-area {
  position: fixed;
  margin-top: 79px;
  height: calc(100vh - 79px);
  width: calc(100vw - 328px);
  left: 0;
}

.right-side {
  width: 340px;
  background-color: white;
  overflow-y: scroll;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  height: calc(100vh - 130px);
  bottom: 0;
  right: 0;
}

.right-side::-webkit-scrollbar {
  width: 5px; /* Remove scrollbar space */
  background: transparent;
}

.right-side::-webkit-scrollbar-thumb {
  background: lightgray;
}

</style>
