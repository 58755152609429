import { render, staticRenderFns } from "./VersionsViewLayout.vue?vue&type=template&id=7ae60c74&scoped=true&"
import script from "./VersionsViewLayout.vue?vue&type=script&lang=js&"
export * from "./VersionsViewLayout.vue?vue&type=script&lang=js&"
import style0 from "./VersionsViewLayout.vue?vue&type=style&index=0&id=7ae60c74&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ae60c74",
  null
  
)

export default component.exports