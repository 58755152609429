<template>
  <b-dropdown
      ref="variablesDropdown"
      @hide="onDropdownHide"
      @show="onShown"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      no-caret
      class="text-primary"
      style="margin-right: 3px"
      no-flip
      :disabled="disabled"
      left
      variant="flat-dark">
    <template #button-content style="z-index: -1 !important; padding: 0">
      <v-icon @click="toggle" name="hashtag"></v-icon>
    </template>

    <b-dropdown-form style="width: 330px;">
      <div class="w-100 flex">
        <div class="mb-2">
          <div class="d-flex w-100 m-0 p-0 row justify-content-between">
            <h6 class="section-label">
              {{ $t('variable.add') }}
            </h6>
            <feather-icon
                size="18"
                icon="XIcon"
                class="cursor-pointer"
                @click="hide"/>
          </div>

          <small class="text-muted">
            {{ $t('variable.selectDescription') }}
          </small>
        </div>
        <v-select
            class="w-100 mb-2"
            v-model="selectedVariable"
            :options="variables"
            label="description"
            :placeholder="$t('variable.search')"
            :multiple="false">
          <template v-slot:option="option">
            {{ option.description }}
          </template>
        </v-select>
        <h6 class="section-label">
          {{ $t('variable.assignColor') }}
        </h6>
        <div class="mb-2 mt-1 w-100 flex container-fluid row ">
          <div v-for="color in variableColorClasses" :key="color">
            <span style="vertical-align: middle" :class="[`cursor-pointer mr-50 mb-25 dot ${color}`]"
                  @click="selectColor(color)">
               <feather-icon v-show="isColorSelected(color)" size="14" class="text-white font-weight-bold"
                             icon="CheckIcon"/>
            </span>
          </div>
        </div>
      </div>

      <div class="d-flex w-100 justify-content-center mt-1">
        <b-button
            v-b-tooltip.hover.top="$t('variable.accept')"
            style="background-color: rgba(40, 199, 111, 0.15)"
            pill
            variant="flat-success"
            :disabled="selectedVariable == null"
            class="rounded-sm circle-btn px-2 p-0"
            @click="select">
          <div class="positioned-lock-icon-wrapper">
            <feather-icon icon="PlusIcon"/>
          </div>
        </b-button>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import {mapState} from "vuex";
import {
  BDropdown,
  BDropdownForm,
  VBTooltip,
  BButton,
  VBPopover
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {ColorUtils} from "@/services/Utils";

export default {
  name: "List",
  components: {
    vSelect,
    BDropdown,
    BButton,
    BDropdownForm,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    disabled: Boolean
  },
  data() {
    return {
      selectedVariable: null,
      closable: false,
      isOpen: false,
      selectedColor: null,
    }
  },
  watch: {
    selectedVariable: {
      handler(val) {
        console.log(val)
        this.selectedColor = val.defaultColorType
      }
    }
  },
  computed: {
    ...mapState({
      document: state => state.document.document,
      variables: state => state.variables.variableList
    }),
    variableColorClasses() {
      return ColorUtils.variableColorClasses
    }
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color
    },
    isColorSelected(color) {
      if (this.selectedColor !== null) {
        return this.selectedColor === color
      }
    },
    toggle() {
      if (this.isOpen) {
        this.hide()
      }
    },
    hide() {
      this.closable = true
      this.$refs.variablesDropdown.hide(true)
    },
    select() {
      console.log(this.selectedColor)
      this.$emit('onSelect', this.selectedVariable, this.selectedColor)
      this.hide()
    },
    onDropdownHide(bvEvent) {
      if (!this.closable) {
        bvEvent.preventDefault();
      }
    },
    onShown(event) {
      console.log('onshown')
      if (this.isOpen) {
        console.log('hello')
        this.isOpen = false
        this.hide()
      } else {
        this.closable = false
        this.isOpen = true
      }
    }
  }
}
</script>

<style scoped>

</style>
