<template>
  <div>
    <vue-simple-suggest pattern="\w+"
                        v-model="userInput"
                        :list="suggestedUsers"
                        :max-suggestions="10"
                        :min-length="2"
                        :debounce="200"
                        :filter-by-query="false"
                        :prevent-submit="true"
                        :controls="{
                          selectionUp: [38, 33],
                          selectionDown: [40, 34],
                          select: [13, 36],
                          hideList: [27, 35]
                        }"
                        mode="input"
                        display-attribute="fullName"
                        value-attribute="id"
                        :nullable-select="true"
                        ref="suggestComponent"
                        :placeholder="placeholderText"
                        @select="onSuggestSelect">

      <template slot="misc-item-above" slot-scope="{ suggestions, query }">
        <div class="misc-item">
          <span>{{ $t('group.userSearching') }} '{{ query }}'.</span>
        </div>

        <template v-if="suggestions.length > 0">
          <div class="misc-item">
            <span>{{ suggestions.length }} {{ $t('group.suggestions') }}</span>
          </div>
          <hr>
        </template>

        <div class="flex justify-between misc-item" v-else-if="!loading">
          <span>{{ $t('group.noResult') }}</span>
        </div>
      </template>

      <div slot="suggestion-item" slot-scope="scope" :title="scope.suggestion.description">
        <div class="text">
          <span v-html="boldenSuggestion(scope)"></span>
        </div>
      </div>

      <div class="misc-item" slot="misc-item-below" v-if="loading">
        <span>{{ $t('group.loading') }}</span>
      </div>
    </vue-simple-suggest>

  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'

export default {
  components: {VueSimpleSuggest},
  name: "UserSelection",
  props: {
    placeholderText: {
      type: String
    },
    workspaceId: String,
  },
  data() {
    return {
      userInput: null,
      loading: false,
    }
  },
  computed: {
    suggestedUsers() {
      return this.$store.getters['user/suggestedUsers']
    }
  },
  watch: {
    userInput(val) {
      if (val != null && val.length > 2) {
        this.queryUsers(val)
      }
    }
  },
  methods: {
    queryUsers(query) {
      this.loading = true
      const payload = {
        workspaceId: this.workspaceId,
        query: query
      }
      this.$store.dispatch('user/query', payload)
          .then(() => {
            this.loading = false
          })
    },
    remove(userToBeRemove) {

    },
    onSuggestSelect(suggestion) {
      this.$emit('select', suggestion)
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const {suggestion, query} = scope;
      let result = this.$refs.suggestComponent.displayProperty(suggestion);
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [''];
      return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3');
    },
  }
}
</script>

<style scoped>

</style>
