import _Quill from "quill";

const Quill = window.Quill || _Quill

import './numbering.scss'
import './Blots/papir-numbering-blot'
import {Placement, positionElements} from "positioning"

const iconAddColRight = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg class="icon" width="20px" height="20px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#595959" d="M73.142857 336.64h526.628572v43.885714H73.142857zM73.142857 643.657143h526.628572v43.885714H73.142857zM336.457143 117.028571h43.885714v789.942858h-43.885714zM204.8 73.142857h614.4a131.657143 131.657143 0 0 1 131.657143 131.657143v614.4a131.657143 131.657143 0 0 1-131.657143 131.657143H204.8A131.657143 131.657143 0 0 1 73.142857 819.2V204.8A131.84 131.84 0 0 1 204.8 73.142857z m0 43.885714a87.771429 87.771429 0 0 0-87.771429 87.771429v614.4a87.771429 87.771429 0 0 0 87.771429 87.771429h614.4a87.771429 87.771429 0 0 0 87.771429-87.771429V204.8a87.771429 87.771429 0 0 0-87.771429-87.771429zM819.2 73.142857h-219.428571v877.714286h219.428571a131.657143 131.657143 0 0 0 131.657143-131.657143V204.8A131.84 131.84 0 0 0 819.2 73.142857z m44.068571 460.982857h-65.828571v65.828572H753.371429v-65.828572h-65.828572V490.057143h65.828572v-65.828572h44.068571v65.828572h65.828571z" /></svg>';

const DEFAULT_PLACEMENT = [
    'bottom-left',
    'bottom-right',
    'top-left',
    'top-right',
    'auto',
];

class PapirNumberingModule {
    TOGGLE_TEMPLATE = `<svg width="9" height="9" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><g><path d="M8,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S12.411,22,8,22z"/><path d="M52,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S56.411,22,52,22z"/><path d="M30,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S34.411,22,30,22z"/></g></svg>`;
    DEFAULTS = {
        maxRowCount: -1,
    };
    menuItems = [
        {
            title: 'TEST',
            icon: iconAddColRight,
            handler: function () {
                console.log('TEST TEST TEST !')
            },
        },
    ]

    constructor(quill, options) {
        this.quill = quill;
        this.menu = null;
        this.options = {...this.DEFAULTS, ...options}
        // Object.assign(this.options, options, {
        //     dataAttributes: Array.isArray(options.dataAttributes)
        //         ? this.options.dataAttributes.concat(options.dataAttributes)
        //         : this.options.dataAttributes
        // });
        // quill.on("text-change", this.onTextChange.bind(this));
        // quill.on("selection-change", this.onSelectionChange.bind(this));

        this.isNumberingLine.bind(this)

        console.log('hellooooo')

        this.toggle = quill.addContainer('ql-numbering-toggle');
        this.toggle.classList.add('ql-numbering-toggle_hidden');
        this.toggle.innerHTML = this.TOGGLE_TEMPLATE;
        this.toggle.addEventListener('click', this.toggleClickHandler.bind(this));
        quill.on('editor-change', this.editorChangeHandler.bind(this));

        quill.root.addEventListener('contextmenu', this.contextMenuHandler.bind(this));
    }

    isNumberingLine(range) {
        if (!range) {
            range = this.quill.getSelection();
        }
        if (!range) {
            return false;
        }
        const formats = this.quill.getFormat(range.index);
        return !!(formats['papirNumbering'] && !range.length);
    }

    toggleClickHandler(e) {
        console.log('hello')
        this.toggleMenu();

        e.preventDefault();
        e.stopPropagation();
    }

    toggleMenu() {
        console.log('show menu')
        if (this.menu) {
            this.hideToggle();
        } else {
            console.log('show menu')
            this.showMenu();
        }
    }


    onTextChange(delta, oldDelta, source) {
        if (source === "user") {
            this.onSomethingChange();
        }
    }

    contextMenuHandler(evt) {
        if (!this.isNumberingLine()) {
            return true;
        }
        evt.preventDefault();
        this.showMenu();
    }

    editorChangeHandler(type, range, oldRange, source) {
        if (type === 'selection-change') {
            this.detectButton(range);
        }
        if (source === "user") {
            this.onSomethingChange();
        }
    }

    onSelectionChange(range) {
        if (range && range.length === 0) {
            this.onSomethingChange();
        }
    }

    detectButton(range) {
        if (range == null) {
            return;
        }

        const show = this.isNumberingLine(range);
        if (show) {
            const [cell, offset] = this.quill.getLine(range.index);
            const containerBounds = this.quill.container.getBoundingClientRect();
            let bounds = cell.domNode.getBoundingClientRect();
            bounds = {
                bottom: bounds.bottom - containerBounds.top,
                height: bounds.height,
                left: bounds.left - containerBounds.left,
                right: bounds.right - containerBounds.left,
                top: bounds.top - containerBounds.top,
                width: bounds.width,
            };

            this.showToggle(bounds);
        } else {
            this.hideToggle();
            this.hideMenu();
        }
    }

    showToggle(position) {
        this.position = position;
        this.toggle.classList.remove('ql-numbering-toggle_hidden');
        this.toggle.style.top = `${position.top}px`;
        this.toggle.style.left = `${position.left}px`;
    }

    hideToggle() {
        this.toggle.classList.add('ql-numbering-toggle_hidden');
    }


    onSomethingChange() {
        //console.log('onSomethingChange in PapirNumberingModule')
    }

    showMenu() {
        this.hideMenu();
        this.menu = this.quill.addContainer('ql-numbering-menu');

        this.menuItems.forEach((it) => {
            this.menu.appendChild(this.createMenuItem(it));
        });
        positionElements(this.toggle, this.menu, DEFAULT_PLACEMENT, false);
        document.addEventListener('click', this.docClickHandler);
    }

    createMenuItem(item) {
        const node = document.createElement('div');
        node.classList.add('ql-numbering-menu__item');

        const iconSpan = document.createElement('span');
        iconSpan.classList.add('ql-numbering-menu__item-icon');
        iconSpan.innerHTML = item.icon;

        const textSpan = document.createElement('span');
        textSpan.classList.add('ql-numbering-menu__item-text');
        textSpan.innerText = item.title;

        node.appendChild(iconSpan);
        node.appendChild(textSpan);
        node.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.quill.focus();
                item.handler();
                this.hideMenu();
                this.detectButton(this.quill.getSelection());
            },
            false
        );
        return node;
    }

    docClickHandler = () => this.hideMenu;

    hideMenu() {
        if (this.menu) {
            this.menu.remove();
            this.menu = null;
            document.removeEventListener('click', this.docClickHandler);
        }
    }

    destroy() {
        this.hideMenu();
        this.quill.off('editor-change', this.editorChangeHandler);
        this.quill.root.removeEventListener('contextmenu', this.contextMenuHandler);
        this.toggle.removeEventListener('click', this.toggleClickHandler);
        this.toggle.remove();
        this.toggle = null;
        this.options = this.DEFAULTS;
        this.menu = null;
        this.table = null;
        this.quill = null;
    }

}


Quill.register('modules/papirNumbering', PapirNumberingModule)
