<template>
  <div>
    <b-overlay
        id="loading-document-type"
        :show="loading"
        :variant="'light'"
        :opacity="0.65"
        rounded="sm">
      <vue-simple-suggest
          pattern="\w+"
          v-model="labelInput"
          :list="queriedLabels"
          :max-suggestions="10"
          :min-length="2"
          :debounce="200"
          :filter-by-query="false"
          :prevent-submit="true"
          :controls="{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        hideList: [27, 35]
      }"
          mode="input"
          :nullable-select="true"
          ref="suggestComponent"
          :placeholder="$t('document.addLabel')"
          @select="onSuggestSelect">

        <template slot="misc-item-above" slot-scope="{ suggestions, query }">
          <div class="misc-item">
            <span>{{ $t('label.labelSearching') }} '{{ query }}'.</span>
          </div>

          <template v-if="suggestions.length > 0">
            <div class="misc-item">
              <span>{{ suggestions.length }} {{ $t('label.suggestions') }}</span>
            </div>
            <hr>
          </template>

          <div class="flex justify-between misc-item" v-else-if="!loading">
            <span>{{ $t('label.noResult') }} <span class="font-weight-bolder"> [enter]</span> a bas ve ekle</span>
            <b-button v-show="false" @click="addLabel">{{$t('add')}}</b-button>
          </div>
        </template>

        <div slot="suggestion-item" slot-scope="scope" :title="scope.suggestion.description">
          <div class="text">
            <span v-html="boldenSuggestion(scope)"></span>
          </div>
        </div>
      </vue-simple-suggest>
      <p-label class="mt-1" @remove-label="removeLabel" hide-remove></p-label>
    </b-overlay>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import PLabel from "./Label";
import {BButton, BOverlay} from 'bootstrap-vue'
import {mapState} from "vuex";

export default {
  components: {VueSimpleSuggest, PLabel, BButton, BOverlay},
  props: {
    documentId: {
      type: String
    }
  },
  computed: {
    queriedLabels() {
      return this.$store.state.label.queriedLabels
    }
  },
  data() {
    return {
      loading: false,
      labelInput: null,
      labelList: []
    }
  },
  watch: {
    labelInput: {
      immediate: true,
      deep: true,
      handler(val, oldValue) {
        if (val != null && val.length > 2) {
          this.queryLabels(val)
        }
      }
    }
  },
  methods: {
    queryLabels(queryText) {
      this.loading = true
      this.$store.dispatch('label/query', queryText)
      this.loading = false
    },
    removeLabel(labelToBeRemoved) {
      this.loading = true
      this.$store.dispatch('label/removeLabel', {
        documentId: this.documentId,
        label: labelToBeRemoved
      }).then(() => {
        this.$emit('removeLabelFromDocument', labelToBeRemoved)
        this.loading = false
      })
    },
    onSuggestSelect(suggest) {
      let labelToBeAdd = ''
      if (suggest) {
        labelToBeAdd = suggest
      } else {
        labelToBeAdd = this.labelInput
      }
      this.saveLabel(labelToBeAdd)
    },
    addLabel() {
      this.saveLabel(this.labelInput)
    },
    saveLabel(labelToBeAdd) {
      this.loading = true
      this.$store.dispatch('label/addToDocument', {
        documentId: this.documentId,
        request: {label: labelToBeAdd}
      }).then(() => {
        this.$emit('pushToLabels', labelToBeAdd)
        this.loading = false
      })
      this.labelInput = null
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const {suggestion, query} = scope;
      let result = this.$refs.suggestComponent.displayProperty(suggestion);
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [''];
      return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3');
    },
  }
}
</script>

