<template>
  <validation-observer ref="FinishSigningProcessFormRef">
    <b-form @submit.prevent>
      <b-col align-h="start">
        <h5 class="mb-1">
          {{ $t('document.lifecycle.signProcessCompleteWarning') }}
        </h5>

        <b-button style="height: 40px; "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="clickFileInput"
                  class=" btn-sm-block  elevated-btn small px-2"
                  variant="primary">
          {{ $t('add') }}
        </b-button>
        <b-form-file v-model="waitingToUploadFile"
                     id="fileInputId"
                     class="hidden"
                     multiple
                     @change="setFile"
                     :placeholder="$t('document.dragFileHere')"
                     :drop-placeholder="$t('document.dropHere')"/>
        <div v-if="waitingToUploadFile !== null" class="pt-2">
          <strong class="text-dark">{{ waitingToUploadFile[0].name }}</strong>
          <br/>
          <small class="align-items-start text-black-50">
            {{ waitingToUploadFile[0].type }}
            <br/>
            {{ getSize(waitingToUploadFile[0].size) }}
          </small>
        </div>

        <!-- submit and reset -->
        <br/>
        <b-row align-h="end" class="px-1">
          <b-button @click="finishSigning"
                    :disabled="waitingToUploadFile === null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="start"
                    variant="danger">
            {{ $t('document.lifecycle.finish') }}
          </b-button>
        </b-row>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import {BButton, BCol, BForm, BFormFile, BRow, VBTooltip} from "bootstrap-vue";
import {ValidationObserver} from "vee-validate";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import {mapState} from "vuex";
import {MiscUtils} from "@/services/Utils";
import {PEventBus} from "@/services/PEventBus";

export default {
  name: "FinishSigningProcessModal",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    ValidationObserver,
    BFormFile
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    documentId: String
  },
  data() {
    return {
      processing: false,
      reason: null,
      approvers: [],
      loadingSigners: false,

      selectedDocumentSignerId: null,
      externalUser: null,

      waitingToUploadFile: null
    }
  },
  mounted() {
    this.getDocumentSignerInfo()
  },
  computed: {
    ...mapState({
      document: state => state.document.document,
      possibleDocumentSigners: state => state.document.possibleDocumentSigners
    })
  },
  methods: {
    getSize(bytes) {
      return MiscUtils.formatBytes(bytes)
    },
    clickFileInput() {
      document.getElementById('fileInputId').click()
    },
    setFile(event) {
      this.waitingToUploadFile = event.target.files[0];
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    finishSigning() {
      this.processing = true
      const formData = {
        documentId: this.documentIdFromUrl(),
        file: this.waitingToUploadFile[0],
      }

      this.$store.dispatch('documentLifecycle/finishSigningProcess', formData)
          .then(() => {
            this.processing = false
            this.success()
            this.$emit('refetchDocument')
            PEventBus.$emit('load-sections')
            this.$emit('close')
          })
          .catch(err => {
            if (err.errorCode === 4800) {
              this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: i18n.t('messages.errorOccurred'),
                      icon: 'AlertTriangleIcon',
                      text: i18n.t('messages.document.noPermissionForSignDocument'),
                      variant: 'danger',
                    },
                  }, {position: 'top-center'}
              )
            }
            this.processing = false
            this.$emit('close')
            this.fail(err)
          })
    },
    success() {
      let icon = 'CheckIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.document.finishedSignProcess'),
              icon,
              text: i18n.t('messages.document.finishedSignProcessDescriptionMessage'),
              variant: 'info',
            },
          }, {position: 'top-center'}
      )
    },
    fail(err) {
      let icon = 'AlertTriangleIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.errorOccurred'),
              icon,
              text: `${err.data.message}`,
              variant: 'danger',
            },
          }, {position: 'top-center'}
      )
    },
    getDocumentSignerInfo() {
      this.loadingSigners = true
      this.$store.dispatch('document/getDocumentSignerInfo', this.documentId)
      this.loadingSigners = false
    },
    handleSignerSelection(signer) {
      // reset if any given
      this.externalUser = null

      this.possibleDocumentSigners.forEach(s => s.selected = s.userId === signer.userId)

      this.selectedDocumentSignerId = signer.userId
    },
    handleUserSelection(event) {
      // reset if any checked
      this.possibleDocumentSigners.forEach(s => s.selected = false)

      this.externalUser = event
      this.selectedDocumentSignerId = event.id
    }
  }
}
</script>

<style scoped>

</style>