<template>
    <b-modal
            scrollable
            ref="save-as-template-modal"
            hide-footer
            no-close-on-backdrop
            :title="$t('template.add')"
            @close="hideModal">
        <validation-observer ref="saveLibraryData"
                             #default="{invalid}">
            <b-form @submit.prevent>
                <b-row align-h="start">
                    <b-col cols="12">
                        <b-form-group class="mt-1 mb-2"
                                      :label="$t('template.organization')"
                                      label-for="fh-doc-type">
                            <v-select v-model="selectedOrganization"
                                      :options="organizations"
                                      label="name"
                                      :placeholder="$t('organization.context')">
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <!-- document title -->
                    <b-col cols="12">
                        <b-form-group :label="$t('template.name')"
                                      label-for="fh-doc-name">
                            <validation-provider #default="{ errors }"
                                                 name="Title"
                                                 vid="title"
                                                 rules="required">
                                <b-form-input v-model="title"
                                              id="fh-doc-name"
                                              :state="errors.length > 0 ? false:null"
                                              :placeholder="$t('template.context')"/>
                              <small v-if="errors.length > 0" class="text-danger">
                                {{ $t('required') }}
                              </small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-checkbox v-model="isPrivate"
                                         checked="false"
                                         name="check-button"
                                         switch
                                         inline>
                            {{$t('document.setPublic')}}
                        </b-form-checkbox>
                    </b-col>

                    <!-- submit and reset -->
                    <b-col cols="12">
                        <br/>
                        <b-row align-h="end" class="px-1">
                            <b-button @click="save"
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      type="submit"
                                      :disabled="invalid"
                                      variant="primary">
                                {{ $t('buttons.save') }}
                            </b-button>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
  import {BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BRow, BModal} from "bootstrap-vue";
  import vSelect from 'vue-select'
  import Ripple from "vue-ripple-directive";
  import {ValidationObserver, ValidationProvider} from 'vee-validate'
  import {required} from '@validations'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import {PEventBus} from "@/services/PEventBus";
  import {mapState} from "vuex";
  import i18n from "@/libs/i18n";

  export default {
    name: "SaveAsLibraryData",
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BModal,
      BButton,
      BFormCheckbox,
      ValidationProvider,
      ValidationObserver,
      vSelect
    },
    directives: {
      Ripple,
    },
    props: {},
    data() {
      return {
        title: null,
        isPrivate: false,
        selectedOrganization: null,
        templateText: null,
        jsonContent: null,
        isSection: false,
        required
      }
    },
    computed: {
      ...mapState({
        organizations: state => state.workspaces.myWorkspaces
      }),
      validation() {
        return true
      },
      validationOccupation() {
        return true
      },
    },
    mounted() {
      PEventBus.$on('handle-add-template', this.handleAddTemplate)
    },
    beforeDestroy() {
      PEventBus.$off('handle-add-template', this.handleAddTemplate)
    },
    methods: {
      handleAddTemplate({jsonContent, textContent, isSection}) {
        this.jsonContent = jsonContent
        this.textContent = textContent
        this.isSection = isSection
        this.openModal()
      },
      openModal() {
        this.$refs['save-as-template-modal'].show()
      },
      hideModal() {
        this.title = null
        this.selectedOrganization = null
        this.isPrivate = false
        this.isSection = false
        this.templateText = null
        this.jsonContent = null
        this.$refs['save-as-template-modal'].hide()
      },
      save() {
        this.$refs.saveLibraryData.validate()
            .then(success => {
              if (success) {
                const payload = {
                  title: this.title,
                  textContent: this.textContent,
                  jsonContent: this.jsonContent,
                  isPrivate: this.isPrivate,
                  section: this.isSection,
                  organizationId: this.selectedOrganization.organizationId
                }

                this.$store.dispatch('library/save', payload)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: i18n.t('messages.library.added'),
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      }, {position: 'top-center'})
                      this.hideModal()
                    })
                    .catch(err => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: i18n.t('messages.library.failedOnAdd'),
                          icon: 'AlertCircleIcon',
                          variant: 'warning',
                        },
                      }, {position: 'top-center'})
                      this.hideModal()
                    })
              }
            });
      },
    }
  }
</script>

<style scoped>

</style>