import _Quill from "quill";

const Quill = window.Quill || _Quill
let Embed = Quill.import('blots/embed');

class Heading extends Embed {
    static create(value){
        let node = super.create();
        const indexPrefix = document.createElement("span")
        indexPrefix.innerHTML = '1. '
        node.appendChild(indexPrefix)
        const headingText = document.createElement("span")
        headingText.contentEditable = "true"
        headingText.innerHTML = value.value
        node.appendChild(headingText)
        node.setAttribute('class','headingfirst');

        headingText.addEventListener('blur', function(event) {
            node.innerHTML = event.target.innerHTML
        });

        return Heading.setDataValues(node, value);
    }

    static setDataValues(element, data) {
        const domNode = element;
        Object.keys(data).forEach(key => {
            domNode.dataset[key] = data[key];
        });
        return domNode;
    }

    static value(domNode) {
        return domNode.dataset;
    }
}


Heading.blotName = 'headingfirst';
Heading.tagName =  'div';

Quill.register(Heading);
