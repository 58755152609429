<template>
  <b-overlay
      :show="loading"
      :variant="'light'"
      :opacity="0.65"
      rounded="sm">
    <v-select
        v-if="document.lifecycleDetail.status.name === 'DRAFT' && requesterAccessLevel === 'CREATOR'"
        v-model="selectedGroups"
        :options="eligibleGroups"
        :label="'name'"
        :placeholder="placeholder"
        v-on:option:selecting="handleSelection($event)"
        v-on:option:deselecting="handleDeselection($event)"
        multiple>
    </v-select>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import {BOverlay} from 'bootstrap-vue'
import {PEventBus} from "@/services/PEventBus";
import i18n from "@/libs/i18n";
import {mapState} from "vuex";

export default {
  name: "GroupsLookupSelect",
  components: {vSelect, BOverlay},
  watch: {
    approvers: {
      immediate: true,
      handler() {
        this.selectedGroups = []
        if (this.approvers !== undefined) {
          let selectedOnes = this.approvers
              .map(approver => {
                return {
                  key: approver.groupId,
                  name: approver.groupName
                }
              });

          this.selectedGroups = Array.from(new Set(selectedOnes.map(a => a.key)))
              .map(key => {
                return selectedOnes.find(a => a.key === key)
              })
        }
      },
    }
  },
  props: {
    approvers: null,
    workspaceId: String,
    placeholder: String,
    multiple: {type: Boolean, default: false},
    requesterAccessLevel: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedGroups: [],
      loading: false
    }
  },
  created() {
    this.loadGroups();
  },
  computed: {
    ...mapState({
      document: (state) => state.document.document,
      groups: state => state.workspaces.groups
    }),
    eligibleGroups() {
      let groups = []

      this.groups.forEach(a => {
        let approvers = a.users.filter(u => u.isApprover);

        if (approvers.length !== 0) {
          groups.push({
            id: a.id,
            name: "(" + approvers.length + i18n.t('group.approver') + ") " + a.name
          })
        }
      });

      return groups
    },
  },
  mounted() {
    PEventBus.$on('section-selected', () => {
      this.loadGroups();
      this.selectedGroups = []
    })
  },
  methods: {
    handleSelection(value) {
      this.$emit('handleSelect', value)
    },
    handleDeselection(value) {
      this.$emit('handleDeselect', value)
    },
    loadGroups() {
      this.loading = true
      this.$store.dispatch('workspaces/fetchGroups', this.workspaceId)
          .then(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>