var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-simple-suggest',{ref:"suggestComponent",attrs:{"pattern":"\\w+","list":_vm.suggestedUsers,"max-suggestions":10,"min-length":2,"debounce":200,"filter-by-query":false,"prevent-submit":true,"controls":{
                        selectionUp: [38, 33],
                        selectionDown: [40, 34],
                        select: [13, 36],
                        hideList: [27, 35]
                      },"mode":"input","display-attribute":"fullName","value-attribute":"id","nullable-select":true,"placeholder":_vm.placeholderText},on:{"select":_vm.onSuggestSelect},scopedSlots:_vm._u([{key:"misc-item-above",fn:function(ref){
                      var suggestions = ref.suggestions;
                      var query = ref.query;
return [_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('group.userSearching'))+" '"+_vm._s(query)+"'.")])]),(suggestions.length > 0)?[_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v(_vm._s(suggestions.length)+" "+_vm._s(_vm.$t('group.suggestions')))])]),_c('hr')]:(!_vm.loading)?_c('div',{staticClass:"flex justify-between misc-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('group.noResult')))])]):_vm._e()]}},{key:"suggestion-item",fn:function(scope){return _c('div',{attrs:{"title":scope.suggestion.description}},[_c('div',{staticClass:"text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.boldenSuggestion(scope))}})])])}}]),model:{value:(_vm.userInput),callback:function ($$v) {_vm.userInput=$$v},expression:"userInput"}},[(_vm.loading)?_c('div',{staticClass:"misc-item",attrs:{"slot":"misc-item-below"},slot:"misc-item-below"},[_c('span',[_vm._v(_vm._s(_vm.$t('group.loading')))])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }