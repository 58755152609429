<template>
  <b-dropdown-group id="signing-actions"
                    :header="$t('document.documentLifecycle')">
    <b-dropdown-item v-if="isStartApprovalProcessAvailable"
                     @click="$emit('startApprovalProcess')"
                     style="font-size: 1.1em;"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="CheckIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.startApproveProcess') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isStopApprovalProcessAvailable"
                     @click="$emit('stopApprovalProcess')"
                     style="font-size: 1.1em;"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="EditIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.stopApproveProcess') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isStopSignProcessAvailable"
                     @click="$emit('stopSigningProcess')"
                     style="font-size: 1.1em;"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="CheckIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.restartApproveProcess') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isStartSignProcessAvailable"
                     @click="$emit('startSigningProcess')"
                     style="font-size: 1.1em;"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="PenToolIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.startSignProcess') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isFinishSigningProcessAvailable"
                     @click="$emit('finishSigningProcess')"
                     style="font-size: 1.1em;"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="PenToolIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.finishSignProcess') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isPauseAvailable"
                     @click="$emit('pause')"
                     style="font-size: 1.1em;"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="PauseIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.stop') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isCancelAvailable"
                     style="font-size: 1.1em;"
                     @click="$emit('cancel')"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="XCircleIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.cancel') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isCompleteActionAvailable"
                     style="font-size: 1.1em;"
                     @click="$emit('completeManually')"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="CheckIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.repeal') }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="isArchiveActionAvailable"
                     style="font-size: 1.1em;"
                     @click="$emit('archive')"
                     link-class="d-flex align-items-center">
      <feather-icon size="18"
                    icon="ArchiveIcon"
                    class="mr-1"/>
      <span class="font-weight-bold">{{ $t('document.archive') }}</span>
    </b-dropdown-item>
  </b-dropdown-group>
</template>

<script>
import {BDropdownGroup, BDropdownItem} from 'bootstrap-vue'

export default {
  name: "DocumentStatusActions",
  components: {
    BDropdownGroup,
    BDropdownItem,
  },
  props: {
    availableActions: Array
  },
  computed: {
    isStartApprovalProcessAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'START_APPROVAL_PROCESS') {
          available = true
        }
      })
      return available
    },
    isStopApprovalProcessAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'STOP_APPROVAL_PROCESS') {
          available = true
        }
      })
      return available
    },
    isStartSignProcessAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'START_SIGN_PROCESS') {
          available = true
        }
      })
      return available
    },
    isStopSignProcessAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'STOP_SIGN_PROCESS') {
          available = true
        }
      })
      return available
    },
    isCancelAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'CANCEL') {
          available = true
        }
      })
      return available
    },
    isPauseAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'PAUSE') {
          available = true
        }
      })
      return available
    },
    isCompleteActionAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'COMPLETE_MANUALLY') {
          available = true
        }
      })
      return available
    },
    isArchiveActionAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'ARCHIVE') {
          available = true
        }
      })
      return available
    },
    isFinishSigningProcessAvailable() {
      let available = false
      this.availableActions.forEach(value => {
        if (value.name === 'SIGN_BY_SIGNER') {
          available = true
        }
      })
      return available
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
