<template>
  <b-overlay
      :show="loading"
      :variant="'light'"
      class="h-100"
      :opacity="0.65"
      rounded="sm">
    <vue-perfect-scrollbar
        class="sidebar-menu-list scroll-area p-1 mb-1 h-100">
      <document-suggest :document-id="document.documentId"
                        style="width: 320px;"
                        @select="onSuggestSelect"/>


      <b-row v-if="linkedDocument !== null" class="p-1">
        <b-col cols="8">
          <b-form-group
              :label="linkedDocument.documentName + ' / ' + linkedDocument.documentCode"
              label-for="fh-doc-name">
            <b-form-input v-model="linkedDocument.header"
                          id="fh-doc-name"
                          :placeholder="$t('document.title')"/>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="pr-2">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="rounded-circle"
                    @click="addLink"
                    variant="flat-success">
            <feather-icon icon="LinkIcon"/>
          </b-button>
        </b-col>
        <b-col cols="1">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="rounded-circle"
                    @click="removeElement"
                    variant="flat-danger">
            <feather-icon icon="XIcon"/>
          </b-button>
        </b-col>

      </b-row>

      <div class="p-1 justify-content-between align-content-center mb-1"
           v-if="Object.entries(relatedDocuments).length === 0">
        <empty-placeholder></empty-placeholder>
      </div>
      <div v-else class="py-1">
        <b-col style="width: 333px;">
          <b-row v-for="(doc,index) in relatedDocuments"
                 v-bind:key="index"
                 class="p-1 w-100">
            <b-card class="w-100 pl-1 pr-1">
              <b-row class="align-items-center justify-content-between">
                <b-avatar size="32"
                          :variant="`light-${getStatusColor(doc.relatedDocumentStatus).variant}`">
                  <feather-icon :icon="getStatusColor(doc.relatedDocumentStatus).icon"/>
                </b-avatar>
                <b-badge v-if="doc.accessable"
                         variant="light-info"
                         class="h-100 cursor-pointer"
                         :to="{ name: 'editor', params: { id: doc.relatedDocumentId }}" target="_blank">
                  #{{ doc.relatedDocumentCode }}
                </b-badge>
                <b-badge v-else
                         variant="light-info"
                         class="h-100 cursor-pointer">
                  #{{ doc.relatedDocumentCode }}
                </b-badge>
              </b-row>
              <b-row>
                <div class="font-weight-bold text-left pt-1">
                  {{ doc.header }}
                </div>
              </b-row>
              <b-row>
                <div class="font-weight-bold text-left pt-1">
                  {{ doc.relatedDocumentName }}
                </div>
              </b-row>
              <b-row>
                <b-col cols="9">
                  <b-badge pill
                           :variant="`light-${resolveDocumentTypeVariant(doc.relatedDocumentType.displayText)}`"
                           class="text-capitalize">
                    {{
                      doc.relatedDocumentType.displayText == null ? $t('undefined') : doc.relatedDocumentType.displayText
                    }}
                  </b-badge>
                </b-col>

                <b-col cols="3">
                  <b-dropdown
                      right
                      variant="link"
                      toggle-class="p-0">
                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="20"
                          class="align-middle text-primary"
                      />
                    </template>
                    <b-dropdown-item
                        @click="removeDocumentRelation(doc.relatedDocumentId)">
                      <span class="align-middle ml-50">Kaldır</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>


              </b-row>
            </b-card>
          </b-row>
        </b-col>
      </div>
    </vue-perfect-scrollbar>
  </b-overlay>
</template>

<script>
import {mapState} from "vuex";
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BOverlay,
  BRow, VBTooltip
} from "bootstrap-vue";
import EmptyPlaceholder from "@/views/Documents/Editor/DocumentLink/EmptyPlaceholder";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import DocumentSuggest from "@/views/components/Select/DocumentSuggest";
import Ripple from "vue-ripple-directive";

export default {
  name: "List",
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    EmptyPlaceholder,
    DocumentSuggest
  },
  computed: {
    ...mapState({
      document: state => state.document.document,
      relatedDocuments: state => state.document.relatedDocuments
    })
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      linkedDocument: null
    }
  },
  created() {
    this.fetchDocumentRelations()
  },
  methods: {
    getStatusColor(status) {
      if (status === 'INITIAL') return {variant: 'info', icon: 'FileIcon'}
      if (status === 'DRAFT') return {variant: 'secondary', icon: 'EditIcon'}
      if (status === 'APPROVED') return {variant: 'success', icon: 'CheckIcon'}
      if (status === 'WAITING_TO_APPROVE') return {variant: 'warning', icon: 'ClockIcon'}
      if (status === 'WAITING_TO_SIGN') return {variant: 'danger', icon: 'PenToolIcon'}
      if (status === 'SIGNED') return {variant: 'success', icon: 'ArrowDownCircleIcon'}
      if (status === 'CANCELLED') return {variant: 'danger', icon: 'XIcon'}
      if (status === 'ARCHIVED') return {variant: 'dark', icon: 'ArchiveIcon'}
      return {variant: 'dark', icon: 'HelpCircleIcon'}
    },
    resolveDocumentTypeVariant(type) {
      if (type === '' || type === null) return 'warning'
      return 'info'
    },
    onSuggestSelect(suggest) {
      this.linkedDocument = {
        documentId: suggest.documentId,
        documentName: suggest.documentName,
        documentCode: suggest.documentCode,
        header: null
      }
    },
    addLink() {
      this.loading = true
      const payload = {
        documentId: this.document.documentId,
        request: {
          documentId: this.linkedDocument.documentId,
          header: this.linkedDocument.header
        }
      }

      this.$store.dispatch('document/addToDocumentLink', payload)
          .then(() => {
            // PEventBus.$emit("reload-document")
            this.fetchDocumentRelations()
            this.loading = false
          })
          .then(() => this.fetchDocumentBadges())
      this.input = null

      this.removeElement()
    },
    removeElement() {
      this.linkedDocument = null
    },
    fetchDocumentRelations() {
      this.$store.dispatch('document/getDocumentRelations', this.document.documentId)
    },
    fetchDocumentBadges() {
      this.$store.dispatch('document/fetchDocumentStatistics', this.document.documentId)
    },
    removeDocumentRelation(relatedDocId) {
      const payload = {
        documentId: this.document.documentId,
        request: {
          documentId: relatedDocId
        }
      }

      this.$store.dispatch('document/removeDocumentRelation', payload)
          .then(() => {
            // PEventBus.$emit("reload-document")
            this.fetchDocumentRelations()
            this.loading = false
          })
          .then(() => this.fetchDocumentBadges())

    }
  }
}
</script>

<style scoped>

</style>