<template>
  <validation-observer ref="StopSigningProcessFormRef">
    <b-form @submit.prevent>
      <b-col align-h="start">
        <b-row v-if="loadingSigners">
          <b-col>
            <div class="mx-15 my-2">
              <b-spinner variant="primary" size="sm"/>
              <strong class="ml-1">{{ $t('loading') }}</strong>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col v-if="possibleDocumentSigners !== null && possibleDocumentSigners !== []">
            <h5 class="mb-1">
              {{ $t('document.lifecycle.startSignProcessQuestion') }}
            </h5>

            <app-collapse accordion>
              <app-collapse-item :title="$t('document.lifecycle.viewSigners')" :is-visible="true">
                <div class="p-2">
                  <ul>
                    <li v-for="(signer,ix) in possibleDocumentSigners" :key="ix">
                      <b-form-checkbox v-model="signer.selected"
                                       @change="handleSignerSelection(signer)"
                                       @click.native.stop>
                        <strong>{{ signer.fullName }}</strong>
                      </b-form-checkbox>
                    </li>
                  </ul>
                </div>
              </app-collapse-item>
              <app-collapse-item :title="$t('document.chooseAnotherUser')">
                <user-suggestion :placeholder-text="$t('chooseUser')"
                                 :workspace-id="document.idWorkspace"
                                 @select="handleUserSelection"
                                 class="p-2"/>

                <b-col v-if="externalUser !== null" class="pl-3">
                  <b-row>
                    <strong>{{ $t('username') }}: </strong> {{ externalUser.fullName }}
                  </b-row>
                  <b-row>
                    <strong>{{ $t('email') }}: </strong>{{ externalUser.email }}
                  </b-row>

                </b-col>
              </app-collapse-item>
            </app-collapse>

          </b-col>
          <b-col v-else>
            <h5 class="mb-1">
              {{ $t('document.lifecycle.noSigner') }}
            </h5>
          </b-col>
        </b-row>

        <!-- submit and reset -->
        <br/>
        <b-row align-h="end" class="px-1">
          <b-button @click="start"
                    :disabled="selectedDocumentSignerId === null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="start"
                    variant="danger">
            {{ $t('document.lifecycle.start') }}
          </b-button>
        </b-row>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import {BButton, BCol, BForm, BFormCheckbox, BRow, BSpinner, VBTooltip} from "bootstrap-vue";
import {ValidationObserver} from "vee-validate";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {PEventBus} from "@/services/PEventBus";
import i18n from "@/libs/i18n";
import {mapState} from "vuex";
import UserSuggestion from "@/views/Groups/UserSuggestion";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import AppCollapse from "@core/components/app-collapse/AppCollapse";

export default {
  name: "StartSigningProcessModal",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    ValidationObserver,
    BFormCheckbox,
    UserSuggestion,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    documentId: String
  },
  data() {
    return {
      processing: false,
      reason: null,
      approvers: [],
      tableColumns: [
        {key: 'name', label: i18n.t('table.approver')},
        {key: 'groupName', label: i18n.t('table.group')},
        {key: 'role', label: i18n.t('table.role')},
        {key: 'sectionStatus', label: i18n.t('table.section')},
      ],
      loadingSigners: false,

      selectedDocumentSignerId: null,
      externalUser: null
    }
  },
  mounted() {
    this.getDocumentSignerInfo()
  },
  computed: {
    ...mapState({
      document: state => state.document.document,
      possibleDocumentSigners: state => state.document.possibleDocumentSigners
    })
  },
  methods: {
    start() {
      this.processing = true
      const payload = {
        documentId: this.documentId,
        request: {
          userId: this.selectedDocumentSignerId
        }
      }
      this.$store.dispatch('documentLifecycle/startSigningProcess', payload)
          .then((res) => {
            this.processing = false
            this.success()
            this.$emit('refetchDocument')
            PEventBus.$emit('load-sections')
            this.$emit('close')
          })
          .catch((err) => {
            this.processing = false
            this.$emit('close')
            this.fail(err)
          })
    },
    success() {
      let icon = 'CheckIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.document.startedSignProcess'),
              icon,
              text: i18n.t('messages.document.startedSignProcessDescriptionMessage'),
              variant: 'info',
            },
          }, {position: 'top-center'}
      )
    },
    fail(err) {
      let icon = 'AlertTriangleIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.errorOccurred'),
              icon,
              text: `${err.data.message}`,
              variant: 'danger',
            },
          }, {position: 'top-center'}
      )
    },
    getDocumentSignerInfo() {
      this.loadingSigners = true
      this.$store.dispatch('document/getDocumentSignerInfo', this.documentId)
      this.loadingSigners = false
    },
    handleSignerSelection(signer) {
      // reset if any given
      this.externalUser = null

      this.possibleDocumentSigners.forEach(s => s.selected = s.userId === signer.userId)

      this.selectedDocumentSignerId = signer.userId
    },
    handleUserSelection(event) {
      // reset if any checked
      this.possibleDocumentSigners.forEach(s => s.selected = false)

      this.externalUser = event
      this.selectedDocumentSignerId = event.id
    }
  }
}
</script>

<style scoped>

</style>