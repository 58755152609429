<template>
  <div
    role="tablist"
    class="collapse-icon"
    :class="collapseClasses"
  >
    <slot />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      collapseID: '',
    }
  },
  computed: {
    collapseClasses() {
      const classes = []

      // Collapse Type
      const collapseVariants = {
        default: 'collapse-default',
        border: 'collapse-border',
        shadow: 'collapse-shadow',
        margin: 'collapse-margin',
      }
      classes.push(collapseVariants[this.type])

      return classes
    },
  },
  created() {
    this.collapseID = uuidv4()
  },
}
</script>
<style lang="scss" scoped>
.collapse-margin {
  .card {
    margin-bottom: 0.2rem;
    box-shadow: 0 1px 0 0 rgba(98, 98, 98, 0.04), 0 1px 0 0 rgba(98, 98, 98, 0.04);
    border-radius: 0;
  }
  .card-header {
    border-radius:  0;
  }
}

.collapse-border {
  .card {
    border: 0;
    &:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .card-body {
    padding-left: 1rem;
    padding-right: 0;
    padding-top: 0;
  }
}

</style>
