<template>
    <div>
        <div class="misc-wrapper" style="margin-top: 10vh">
            <div class="misc-inner">
                <div class="w-100 text-center justify-content-center">
                    <b-img class="w-50 py-2 mb-1"
                           fluid
                           :src="imgUrl"
                           alt="Not authorized page"
                    />
                    <br/>
                    <small class="mb-2 font-medium-1 text-black-50" style="width: 100%; text-align: center">
                        {{$t('section.approverListInformation')}}
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  import {BButton, BImg} from "bootstrap-vue";
  import Ripple from 'vue-ripple-directive'

  export default {
    name: "Empty",
    components: {
      BImg,
    },
    props: {
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        modalShow: false,
        emptyImg: require('@/assets/images/illustration/approvers.svg'),
      }
    },
    computed: {
      imgUrl() {
        return this.emptyImg
      },
    },
    methods: {
      showModal() {

      }
    }
  }
</script>

<style scoped>

</style>