<template>
  <div>
    <b-nav-item-dropdown
        style="z-index: 21;"
        no-caret
        right
        toggle-class="d-flex align-items-center mr-2 dropdown-actions-link">

      <template #button-content style="z-index: -1 !important; ">
        <b-button
            style="z-index: -1 !important; "
            class="rounded-circle"
            variant="flat-secondary">
          <div class="icon-wrapper">
            <feather-icon style="z-index: -1 !important; " icon="MoreVerticalIcon" size="18"/>
          </div>
        </b-button>
      </template>
      <document-lifecycle-actions
          v-if="availableActions.length > 0"
          :available-actions="availableActions"
          @startApprovalProcess="startApprovalProcess"
          @stopSigningProcess="stopSigningProcess"
          @stopApprovalProcess="stopApprovalProcess"
          @startSigningProcess="startSigningProcess"
          @finishSigningProcess="finishSigningProcess"
          @cancel="cancel"
          @pause="pause"
          @completeManually="completeManually"
          @archive="archive"/>
      <b-dropdown-divider v-if="availableActions.length > 0" />
      <b-dropdown-group
          id="dropdown-group-1"
          :header="$t('table.actions')">
        <b-dropdown-item
            style="font-size: 1.1em;"
            @click="$emit('createTemplate')"
            link-class="d-flex align-items-center">
          <feather-icon size="18"
                        icon="LayoutIcon"
                        class="mr-1"/>
          <span class="font-weight-bold">{{ $t('document.saveAsTemplate') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
            @click="$emit('generatePdf')"
            style="font-size: 1.1em;"
            link-class="d-flex align-items-center">
          <feather-icon size="18"
                        icon="DownloadIcon"
                        class="mr-1"/>
          <span class="font-weight-bold">{{ $t('document.downloadAsPDF') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
            @click="$emit('generateDocx')"
            style="font-size: 1.1em;"
            link-class="d-flex align-items-center">
          <feather-icon size="18"
                        icon="DownloadIcon"
                        class="mr-1"/>
          <span class="font-weight-bold">{{ $t('document.downloadAsDocx') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
            @click="$emit('exportDocument')"
            style="font-size: 1.1em;"
            link-class="d-flex align-items-center">
          <img :src="require('@/assets/images/logo/papirai-logo.svg')"
               height="16"
               class="mr-1"/>
          <span class="font-weight-bold">{{ $t('document.exportAsPAI') }}</span>
        </b-dropdown-item>
      </b-dropdown-group>
      <b-dropdown-divider/>
      <b-dropdown-item
          style="font-size: 1.1em;"
          @click="$emit('delete')"
          link-class="d-flex align-items-center">
        <feather-icon size="18"
                      icon="TrashIcon"
                      class="mr-1"/>
        <span class="font-weight-bold">{{ $t('buttons.delete') }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <!--  life cycle action modals -->
    <b-modal
        lazy
        ref="StopSigningProcessModalRef"
        hide-footer
        no-close-on-backdrop
        :title="$t('document.lifecycle.stopSignProcess')">
      <stop-signing-process-modal @refetchDocument="$emit('refetchDocument')"
                                  @close="closeModal('StopSigningProcessModalRef')"/>
    </b-modal>
    <b-modal
        lazy
        size="lg"
        ref="StartApprovalProcessModalRef"
        hide-footer
        no-close-on-backdrop
        :title="$t('document.lifecycle.startApproveProcess')">
      <start-approval-process-modal :document-id="documentId"
                                    @refetchDocument="$emit('refetchDocument')"
                                    @close="closeModal('StartApprovalProcessModalRef')"/>
    </b-modal>
    <b-modal
        lazy
        size="lg"
        ref="StartSigningProcessModalRef"
        hide-footer
        no-close-on-backdrop
        :title="$t('document.lifecycle.startSignProcess')">
      <start-signing-process-modal :document-id="documentId"
                                   @refetchDocument="$emit('refetchDocument')"
                                   @close="closeModal('StartSigningProcessModalRef')"/>
    </b-modal>
    <b-modal
        lazy
        size="lg"
        ref="FinishSigningProcessFormRef"
        hide-footer
        no-close-on-backdrop
        :title="$t('document.lifecycle.completeSignProcess')">
      <finish-signing-process-modal :document-id="documentId"
                                    @refetchDocument="$emit('refetchDocument')"
                                    @close="closeModal('FinishSigningProcessFormRef')"/>
    </b-modal>
  </div>

</template>

<script>
import {BButton, BDropdownDivider, BDropdownGroup, BDropdownItem, BNavItemDropdown, VBTooltip} from 'bootstrap-vue'
import DocumentLifecycleActions from "@/views/Documents/Editor/Header/DocumentLifecycleActions";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

import StartSigningProcessModal from "@/views/Documents/Editor/Header/StartSigningProcessModal";
import StopSigningProcessModal from "@/views/Documents/Editor/Header/StopSigningProcessModal";
import StartApprovalProcessModal from "@/views/Documents/Editor/Header/StartApprovalProcessModal";
import FinishSigningProcessModal from "@/views/Documents/Editor/Header/FinishSigningProcessModal";
import i18n from "@/libs/i18n";

export default {
  name: "ActionsDropdown",
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BNavItemDropdown,
    BButton,
    BDropdownGroup,
    BDropdownItem,
    BDropdownDivider,
    DocumentLifecycleActions,
    StopSigningProcessModal,
    StartSigningProcessModal,
    StartApprovalProcessModal,
    FinishSigningProcessModal
  },
  data() {
    return {
      isStartApprovalProcessModalVisible: false,
      isStopSigningProcessModalVisible: false,
      isStartSigningProcessModalVisible: false,
    }
  },
  props: {
    availableActions: Array,
    documentId: String
  },
  methods: {
    startApprovalProcess() {
      this.$refs['StartApprovalProcessModalRef'].show()
    },
    stopApprovalProcess() {
      let message = i18n.t('document.lifecycle.approvalProcessInformation')
      let title = i18n.t('document.lifecycle.acceptQuestion')
      let actionType = 'documentLifecycle/stopApprovalProcess'
      let successMsg = i18n.t('document.lifecycle.stoppedApprovalProcessSuccessMsg')
      this.promptMessage(message, title, actionType, successMsg)
    },
    startSigningProcess() {
      this.$refs['StartSigningProcessModalRef'].show()
    },
    stopSigningProcess() {
      this.$refs['StopSigningProcessModalRef'].show()
    },
    finishSigningProcess() {
      this.$refs['FinishSigningProcessFormRef'].show()
    },
    cancel() {
      let message = i18n.t('document.lifecycle.cancelProcessInformation')
      let title = i18n.t('document.lifecycle.acceptQuestion')
      let actionType = 'documentLifecycle/cancel'
      let successMsg = i18n.t('document.lifecycle.cancelProcessSuccessMsg')
      this.promptMessage(message, title, actionType, successMsg)
    },
    pause() {
      let message = i18n.t('document.lifecycle.pauseProcessInformation')
      let title = i18n.t('document.lifecycle.acceptQuestion')
      let actionType = 'documentLifecycle/startApproval'
      let successMsg = i18n.t('document.lifecycle.pauseProcessSuccessMsg')
      this.promptMessage(message, title, actionType, successMsg)
    },
    completeManually() {
      let message = i18n.t('document.lifecycle.completeProcessInformation')
      let title = i18n.t('document.lifecycle.acceptQuestion')
      let actionType = 'documentLifecycle/completeManually'
      let successMsg = i18n.t('document.lifecycle.completeProcessSuccessMsg')
      this.promptMessage(message, title, actionType, successMsg)
    },
    archive() {
      let message = i18n.t('document.lifecycle.archiveProcessInformation')
      let title = i18n.t('document.lifecycle.acceptQuestion')
      let actionType = 'documentLifecycle/archive'
      let successMsg = i18n.t('document.lifecycle.archiveProcessSuccessMsg')
      this.promptMessage(message, title, actionType, successMsg)
    },
    closeModal(ref) {
      this.$refs[ref].hide()
    },
    promptMessage(message, title, actionType, successMsg) {
      this.$bvModal
          .msgBoxConfirm(message, {
            title: title,
            size: 'sm',
            okVariant: 'danger',
            okTitle: i18n.t('apply'),
            cancelTitle: i18n.t('exit'),
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: false,
          })
          .then(value => {
            if (value) {
              this.$store.dispatch(actionType, this.documentId)
                  .then(() => {
                    this.showToast(successMsg)
                    this.$emit('refetchDocument')
                  })
                  .catch((error) => {

                  })
            }
          })
    },
    showToast(successMsg) {
      let icon = 'CheckIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.notification'),
              icon,
              text: successMsg,
              variant: 'success',
            },
          }, {position: 'top-center'}
      )
    }
  }
}
</script>

<style lang="scss" scoped>

.dropdown-toggle::after {
  border: none;
}
</style>
