<template>
  <div class='tab' v-if='isActive'>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    isActive: Boolean,
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>

.tab {
  overflow-x: scroll;
  background-color: #fff;
  position: absolute;
  display:flex;
  width: 100%;
  height: calc(100vh - 120px);
}


</style>
