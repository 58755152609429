<template>
    <div class="labels">
        <ul>
            <li v-for="(label, index) in document.labels" :key="index">
                <div class="labelContainer">
                    <span v-if="hideRemove" class="removeButton" @click="removeLabel(label)">&times;</span>
                    <a @click="onLabelClick(label)">{{label}}</a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    props: {
      labels: {
        type: Array,
        default: () => []
      },
      hideRemove: {
        type: Boolean,
        default: false
      }
    },
    name: "Label",
    computed: {
      ...mapState({
        document: state => state.document.document
      }),
    },
    methods: {
      removeLabel(label) {
        this.$emit('remove-label', label)
      },
      onLabelClick(label) {
        this.$emit('clickOnLabel', label);
      }
    }
  }
</script>

<style scoped>
    /* The alert message box */
    .labels ul {
        font-weight: bold;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .labels ul li {
        display: inline-block;
        margin: 0;
        padding: 2px;
    }

    .labels ul li a {
        color: #766BF1;
        -webkit-transition: .2s;
        transition: .2s;
        padding-left: 5px;
    }

    .labels ul li a:hover {
        color: #1a1a1a;
        cursor: pointer;
    }

    .labelContainer {
        font-size: .9em;
        width: auto;
        padding: 1px 1px;
        border-radius: 10px;
        margin-right: 1px;
        background-color: #EAE8FE;
        color: #766BF1;
        border: 1px solid #766BF1;
    }

    /* The close button */
    .removeButton {
        margin-left: 3px;
        color: #1a1a1a;
        padding-right: 3px;
        font-weight: bold;
        float: right;
        font-size: 20px;
        line-height: 20px;
        cursor: pointer;
        margin-bottom: 5px;
        margin-top: 0;
        transition: 0.3s;
    }

    .removeButton:hover {
        color: #766BF1;
    }
</style>
