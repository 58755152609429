<template>
	<div>
		<div class="row  align-items-center editor-nav">
			<div
				class="col-5 justify-content-start container-fluid align-items-center d-flex document-title w-full pl-0">
				<b-button v-b-tooltip.hover.top="$t('buttons.backToList')" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-warning" style="background-color: rgba(255, 159, 67, 0.12)" class="rounded-circle mr-1 ml-1" @click="cleanSections">
					<div class="icon-wrapper">
						<feather-icon icon="ArrowLeftIcon" size="24"/>
					</div>
				</b-button>
				<div class="my-auto ">
					<div :contenteditable="document.lifecycleDetail.status.name === 'DRAFT'" v-show="!loading" class="vs-input-no-border" style="padding: 2px 8px 2px 5px;" @keydown="handleEditableKeyPress" @blur="updateTitle" v-on:keyup.enter="$event.target.blur()" v-on:keydown="onKeydown" v-text="title" :placeholder="$t('document.namelessDocument')">
					</div>
					<span id="document-code-tooltip-target" class=" rounded font-weight-bold text-black-50 cursor-pointer" style="background-color: rgba(9, 30, 66, 0.04);padding-left: 6px; padding-right: 6px" @click="doCopy">#{{ document.documentCode }}</span>
					<b-tooltip target="document-code-tooltip-target" :delay="{ show: 500, hide: 500 }">
						{{ $t('copied') }}
					</b-tooltip>
				</div>
			</div>
			<div class="col-7 justify-content-end  align-items-center d-flex document-title ">
				<div>
					<b-nav class="navbar-container content nav align-items-center ml-auto">
						<div class="d-flex align-items-center">
							<b-button
								:disabled="!selectedSectionStatus.sectionId"
								v-b-tooltip.hover.top="$t('section.save')"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								class=" mr-50"
								:variant="selectedSectionStatus.unsaved ? 'flat-danger' : 'flat-success'"
								@click="emitSaveSection">
								<div>
									<v-icon v-if="selectedSectionStatus.autosaving" name="spinner" scale="1"
											style="height:1.3rem; color: grey" spin/>
									<v-icon v-else style="height:1.3em;width: auto" name="cloud-upload-alt"/>
								</div>
							</b-button>
							<b-button
								v-b-tooltip.hover.left="$t('document.autoSaveDescription')"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								class=" rounded-circle mr-50" style="padding:2px"
								variant="flat-secondary"
								@click="$emit('switchVersionsView')">
								<div class="positioned-lock-icon-wrapper">
									<feather-icon style="height: 1.4em" icon="ClockIcon"/>
								</div>
							</b-button>
							<vue-apex-charts v-if="false" type="radialBar" height="70" width="70" :options="options" :series="[approveRatio]"/>
							<!--              <span class="font-weight-bold text-body-heading  ml-50">{{ approveRatio }}%</span>-->
						</div>
						<b-button
							v-if="lifecycleDetail.status.name === 'WAITING_TO_APPROVE' && sections.filter(s => s.approver && s.approvalStatus === 'WAITING').length > 0"
							@click="multiApproveSections"
							v-ripple.400="'rgba(40, 199, 111, 0.15)'"
							class="rounded font-weight-bold mr-50"
							variant="flat-primary">
							<div class="icon-wrapper">
								<feather-icon icon="CheckIcon"/>
								<span style="margin-left: 5px;">{{ $t('document.approveSections') }}</span>
							</div>
						</b-button>
						<b-button
							v-b-tooltip="$t('document.rightSidebar.comments')"
							v-ripple.400="'rgba(40, 199, 111, 0.15)'"
							@click="showCommentsPanel"
							class=" rounded font-weight-bold mr-50"
							variant="flat-secondary">
							<div class="icon-wrapper">
								<feather-icon icon="MessageSquareIcon"/>
							</div>
						</b-button>
						<actions-dropdown :document-id="documentId" @refetchDocument="$emit('refetchDocument')" @createTemplate="createTemplate" @generatePdf="generatePdf" @generateDocx="generateDocx" @exportDocument="exportById" @archive="archiveDocument" @delete="deleteDocument" :available-actions="lifecycleDetail.availableActions"/>
					</b-nav>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {BButton, BNav, BTooltip, VBTooltip,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {PEventBus} from "@/services/PEventBus";
import ActionsDropdown from "@/views/Documents/Editor/Header/ActionsDropdown";
import i18n from "@/libs/i18n";
import VueApexCharts from 'vue-apexcharts'

export default {
	name: "EditorHeader",
	props: {
		title: String,
		lifecycleDetail: Object,
		approveRatio: Number
	},
	data() {
		return {
			generating: false,
			content: null,
			htmlToPdfOptions: {
				margin: [20, 0, 20, 0],
				pagebreak: {mode: ['css']},
			},
			currentSection: null,
			options: {
				grid: {
					show: false,
					padding: {
						left: -5,
						right: -5,
						top: -5,
						bottom: -5,
					},
				},
				colors: ['#A9A2F6'],
				plotOptions: {
					radialBar: {
						hollow: {
							size: '34%',
						},
						track: {
							background: '#e9ecef',
						},
						dataLabels: {
							showOn: 'always',
							name: {
								show: false,
							},
							value: {
								offsetY: 5,
								fontSize: '10px',
								show: true,
							},
						},
					},
				},
				stroke: {
					lineCap: 'round',
				},
			},
		}
	},
	components: {
		ActionsDropdown,
		BButton,
		BNav,
		VueApexCharts,
		BTooltip
	},
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},
	computed: {
		...mapState({
			loading: state => state.document.listLoading,
			sections: state => state.document.sections,
			document: state => state.document.document,
			selectedSectionStatus: (state) => state.document.selectedSectionStatus,
		}),
		selectedSectionId() {
			return this.$store.state.document.selectedSectionStatus.sectionId
		},
		documentId() {
			return this.$route.params.id;
		},
	},
	mounted() {
		PEventBus.$on('section-selected', (evt) => this.onSectionSelect(evt))
	},
	methods: {
		emitSaveSection() {
			PEventBus.$emit(`${this.selectedSectionId}-save`);
		},
		showCommentsPanel() {
			PEventBus.$emit('toggle-comments-panel')
		},
		deleteDocument() {
			this.$bvModal
				.msgBoxConfirm(this.$i18n.t('messages.document.questionBeforeDelete'), {
					title: i18n.t('messages.areYouSure'),
					size: 'sm',
					okVariant: 'danger',
					okTitle: i18n.t('yes'),
					cancelTitle: i18n.t('exit'),
					cancelVariant: 'outline-primary',
					hideHeaderClose: false,
					centered: false,
				})
				.then(value => {
					if (value) {
						let icon = 'TrashIcon'
						this.$store.dispatch('document/deleteDocument', this.documentId)
							.then(res => {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: i18n.t('messages.notification'),
										icon,
										text: i18n.t('messages.document.deleted'),
										variant: 'danger',
									},
								}, {position: 'top-center'})
								this.$router.replace('/documents/my')
							})
							.catch(err => {
								if (err.errorCode === 4014) {
									this.fail(i18n.t('messages.document.deleteNotAllowed'))
								} else {
									this.fail(i18n.t('messages.document.errorOnDelete'))
								}
							})
					}
				})
		},
		fail(message) {
			let icon = 'AlertTriangleIcon'
			this.$toast({
				component: ToastificationContent,
				props: {
					title: i18n.t('messages.notification'),
					icon,
					text: message,
					variant: 'danger',
				},
			}, {position: 'top-center'})
		},
		handleEditableKeyPress(e) {
			if (e.keyCode === 13) {
				e.preventDefault()
			}
		},
		archiveDocument() {
			this.$bvModal
				.msgBoxConfirm(this.$i18n.t('messages.document.questionBeforeArchive'), {
					title: i18n.t('messages.areYouSure'),
					size: 'sm',
					okVariant: 'danger',
					okTitle: i18n.t('yes'),
					cancelTitle: i18n.t('exit'),
					cancelVariant: 'outline-primary',
					hideHeaderClose: false,
					centered: false,
				})
				.then(value => {
					if (value) {
						let icon = 'TrashIcon'
						this.$store.dispatch('document/archiveDocument', this.documentId)
							.then(() => {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: i18n.t('messages.notification'),
										icon,
										text: i18n.t('messages.document.archived'),
										variant: 'danger',
									},
								}, {position: 'top-center'})
								this.$router.replace('/documents/my')
							})
					}
				})
		},
		onProgress(event) {
			console.log(event);
		},
		hasStartedGeneration(event) {
		},
		hasDownloaded(event) {
		},
		hasGenerated(event) {
			this.generating = false
		},
		onKeydown(event) {

			if (event.keyCode === 13) {
				event.preventDefault();
			}
		},
		updateTitle(event) {
			if (event.target.textContent !== this.title) {
				this.$store.dispatch('document/update', {documentId: this.documentId, title: event.target.textContent})
					.then(() => {
						// do something
					})
			}
		},
		createTemplate() {
			PEventBus.$emit('show-create-template-modal', this.title)
		},
		async generatePdf() {
			this.generating = true

			// await this.$store.dispatch('document/persistAllContent')

			let documentTitle = JSON.parse(JSON.stringify(this.document)).title
			documentTitle = documentTitle === '' ? i18n.t('document.namelessDocument') : documentTitle

			const payload = {
				documentId: this.documentId,
				documentTitle: documentTitle
			}

			await this.$store.dispatch('document/downloadAsPdfById', payload)
				.then(fileUrl => {
					if (fileUrl !== undefined) {
						this.$swal({
							title: i18n.t('document.fileTransferCompleted'),
							text: i18n.t('document.clickForView'),
							icon: 'success',
							showCancelButton: true,
							confirmButtonText: i18n.t('view'),
							cancelButtonText: i18n.t('exit'),
							customClass: {
								confirmButton: 'btn btn-primary',
								cancelButton: 'btn btn-outline-danger ml-1',
							},
							buttonsStyling: false,
						}).then((res) => {
							if (res.value) {
								window.open(fileUrl);
							}
						})
					} else {
						this.fail(i18n.t('messages.document.pdfExportError'))
					}
				})

		},
		async generateDocx() {
			this.generating = true

			// await this.$store.dispatch('document/persistAllContent')

			let documentTitle = JSON.parse(JSON.stringify(this.document)).title
			documentTitle = documentTitle === '' ? i18n.t('document.namelessDocument') : documentTitle

			const payload = {
				documentId: this.documentId,
				documentTitle: documentTitle
			}

			this.$toast({
				component: ToastificationContent,
				props: {
					title: i18n.t('messages.document.itCanTakeAWhile'),
					icon: 'CheckIcon',
					variant: 'success',
				},
			}, {position: 'top-center'})

			await this.$store.dispatch('document/downloadAsDocxById', payload)
				.then(fileUrl => {
					if (fileUrl !== undefined) {
						this.$swal({
							title: i18n.t('document.fileTransferCompleted'),
							text: i18n.t('downloading'),
							icon: 'success',
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: i18n.t('close'),
							customClass: {
								confirmButton: 'btn btn-primary',
								cancelButton: 'btn btn-outline-danger ml-1',
							},
							buttonsStyling: false,
						})
					} else {
						this.fail(i18n.t('messages.document.docxExportError'))
					}
				})
				.catch(err => {
					this.fail(i18n.t('messages.document.errorOccurredWhileExport'))
				})
		},
		async exportById() {
			this.generating = true

			// await this.$store.dispatch('document/persistAllContent')

			let documentTitle = JSON.parse(JSON.stringify(this.document)).title
			documentTitle = documentTitle === '' ? i18n.t('document.namelessDocument') : documentTitle

			const payload = {
				documentId: this.documentId,
				documentTitle: documentTitle
			}

			this.$toast({
				component: ToastificationContent,
				props: {
					title: i18n.t('messages.document.itCanTakeAWhile'),
					icon: 'CheckIcon',
					variant: 'success',
				},
			}, {position: 'top-center'})

			await this.$store.dispatch('document/exportById', payload)
				.then(fileUrl => {
					if (fileUrl !== undefined) {
						this.$swal({
							title: i18n.t('document.fileTransferCompleted'),
							text: i18n.t('downloading'),
							icon: 'success',
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: i18n.t('close'),
							customClass: {
								confirmButton: 'btn btn-primary',
								cancelButton: 'btn btn-outline-danger ml-1',
							},
							buttonsStyling: false,
						}).then((res) => {
							if (res.value) {
								window.open(fileUrl);
							}
						})
					}
				})
				.catch(err => {
					this.fail(i18n.t('messages.document.errorOccurredWhileExport'))
				})


			// this.generating = true
			//
			// // await this.$store.dispatch('document/persistAllContent')
			//
			// const exportedFile = await this.$store.dispatch('document/exportById', this.documentId);
			//
			// if (exportedFile !== undefined) {
			//   this.$swal({
			//     title: i18n.t('document.fileTransferCompleted'),
			//     text: i18n.t('downloading'),
			//     icon: 'success',
			//     showCancelButton: true,
			//     confirmButtonText: i18n.t('view'),
			//     cancelButtonText: i18n.t('exit'),
			//     customClass: {
			//       confirmButton: 'btn btn-primary',
			//       cancelButton: 'btn btn-outline-danger ml-1',
			//     },
			//     buttonsStyling: false,
			//   }).then((res) => {
			//     if (res.value) {
			//       window.open(exportedFile);
			//     }
			//   })
			// } else {
			//   this.$toast({
			//     component: ToastificationContent,
			//     props: {
			//       title: i18n.t('messages.document.exportError'),
			//       icon: 'AlertTriangleIcon',
			//       variant: 'danger',
			//     },
			//   }, {position: 'top-center'})
			// }
		},
		onSectionSelect(id) {
			this.currentSection = id
		},
		saveCurrentSection() {
			//todo NEED REAL ACTION

			this.$toast({
				component: ToastificationContent,
				props: {
					title: i18n.t('messages.section.saved'),
					icon: 'CheckIcon',
					variant: 'success',
				},
			}, {position: 'top-center'})
		},
		multiApproveSections() {
			this.$store.dispatch('document/bulkApproveSections', this.documentId)
				.then(() => PEventBus.$emit("reload-document"))
				.then(() => PEventBus.$emit("load-sections"))
				.then(() => this.$toast({
					component: ToastificationContent,
					props: {
						title: i18n.t('messages.section.bulkApprove'),
						icon: 'CheckIcon',
						variant: 'success',
					},
				}, {position: 'top-center'}))
				.then(() => this.$emit('refetchDocument'))
		},
		async cleanSections() {
			await this.$store.dispatch('document/clean')
			await this.$router.push({name:'my-documents'})
		},
		doCopy() {
			this.$copyText(this.document.documentCode)
		}
	}
}
</script>

<style scoped>

</style>
