<template>
  <validation-observer ref="StopSigningProcessFormRef"
                       #default="{invalid}">
    <b-form @submit.prevent>
      <b-row align-h="start">
        <b-col class="mb-1" cols="12">
          <h5>{{ $t('document.stopProcessDescription') }}</h5>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="$t('document.setStopReason')"
                        label-for="fh-reason">
            <validation-provider #default="{ errors }"
                                 name="Reason"
                                 vid="reason"
                                 rules="required">
              <b-form-textarea v-model="reason"
                               id="fh-reason"
                               rows="6"
                               :state="errors.length > 0 ? false:null"
                               :placeholder="$t('document.stopSignProcessReason')"/>
              <small v-if="errors.length > 0" class="text-danger">
                {{ $t('required') }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <br/>
          <b-row align-h="end" class="px-1">
            <b-button @click="submit"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      :disabled="invalid"
                      variant="danger">
              {{ $t('buttons.stop') }}
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {BButton, BCol, BForm, BFormGroup, BFormTextarea, BRow} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";

export default {
  name: "StopSigningProcessModal",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reason: null,
    }
  },
  methods: {
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    submit() {
      this.processing = true

      const payload = {
        documentId: this.documentIdFromUrl(),
        request: {
          note: this.reason
        }
      }

      this.$store.dispatch('documentLifecycle/stopSigningProcess', payload)
          .then((res) => {
            this.processing = false
            this.success()
            this.$emit('refetchDocument')
            this.$emit('close')
          })
          .catch((err) => {
            this.processing = false
            this.$emit('close')
            this.fail(err)
          })
    },
    success() {
      let icon = 'CheckIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.document.stoppedSignProcess'),
              icon,
              text: i18n.t('messages.document.stoppedSignProcessDescriptionMessage'),
              variant: 'info',
            },
          }, {position: 'top-center'}
      )
    },
    fail(err) {
      let icon = 'AlertTriangleIcon'
      this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.notification'),
              icon,
              text: `${err.data.message}`,
              variant: 'danger',
            },
          }, {position: 'top-center'}
      )
    }
  }
}
</script>

<style scoped>

</style>