<template>
  <div>
    <b-modal size="xl"
             class="modal-fullscreen"
             ref="diff-check-panel-modal"
             :okTitle="$t('accept')"
             scrollable
             :cancelTitle="$t('exit')"
             hide-header-close
             cancelVariant="outline-danger"
             no-close-on-esc no-close-on-backdrop>
      <template #modal-header="{  }">
      </template>
      <div class="row d-flex w-100 mb-1 px-1">
        <div class="col">
          <b-alert v-height-fade
                   show
                   dismissible
                   fade
                   variant="warning">
            <div class="alert-body">
              <feather-icon icon="InfoIcon"
                            class="mr-50"/>
              <span>{{ $t('section.sectionVersionMayUpdate') }}</span>
            </div>
          </b-alert>
        </div>
      </div>
      <div class="row d-flex px-1">
        <div class="col-4">
          <div class="diff-check-editor ">
            <div class="p-1">
              <span>
                <h5>
                <span class="text-primary text-bolder">
                  v{{ version }}
                </span> {{ getLocalUserName }} ({{ $t('section.you') }})
              </h5>
              </span>
              <small>{{ getNow }}</small>
            </div>
            <quill-editor :options="editorOption"
                          class="editor"
                          ref="old"/>
          </div>
        </div>
        <div class="col-4">
          <div class="diff-check-editor ">
            <div class="p-1">
              <h3>{{ $t('section.diff') }}</h3>
            </div>
            <quill-editor :options="editorOption"
                          class="editor"
                          ref="diff"/>
          </div>
        </div>
        <div class="col-4">
          <div class="diff-check-editor">
            <div class="p-1">
              <span>
                <h5>
                <span class="text-primary text-bolder">
                  {{ getRemoteVersion }}
                </span>{{ getLocalUserName }} ({{ $t('section.you') }})
                </h5>
              </span>
              <small>{{ getRemoteTime }}</small>
            </div>
            <quill-editor :options="editorOption"
                          class="editor"
                          ref="new"/>
          </div>
        </div>

      </div>
      <template #modal-footer>
        <div class="w-100">
          <div class="d-flex justify-content-between w-100">
            <div>
              <b-button size="sm"
                        variant="flat-primary"
                        class="mr-1"
                        @click="destroyManually">
                {{ $t('section.sendYours') }}
              </b-button>
              <b-button size="sm"
                        variant="flat-primary"
                        class="mr-1"
                        @click="destroyManually">
                {{ $t('section.acceptTheirs') }}
              </b-button>
              <b-button size="sm"
                        variant="flat-primary"
                        class="mr-1"
                        @click="destroyManually">
                {{ $t('section.merge') }}
              </b-button>
            </div>
            <div>
              <b-button size="sm"
                        variant="outline-danger"
                        @click="destroyManually">
                {{ $t('close') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {BAlert, BButton, BModal, VBModal} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'

import {getUserData} from '@/auth/utils'
import DateUtil from "@/services/DateUtil";

export default {
  name: "index",
  props: {
    version: Number,
    sectionId: String,
    localLastUpdated: String,
    content: Object,
  },
  data() {
    return {
      diff: null,
      merged: null,
      editorOption: {
        theme: 'bubble',
        modules: {
          toolbar: []
        }
      },
    }
  },
  components: {
    BModal,
    BButton,
    BAlert,
    quillEditor,
  },
  computed: {
    editorDiff() {
      return this.$refs.diff.quill
    },
    editorNew() {
      return this.$refs.new.quill
    },
    editorOld() {
      return this.$refs.old.quill
    },
    getLocalUserName() {
      return getUserData().fullName
    },
    getNow() {
      return DateUtil.fromNow(DateUtil.now())
    },
    getRemoteTime() {
      if (this.diff != null) {
        return DateUtil.fromNow(this.diff.remote.lastUpdatedAt)
      } else {
        return 'invalid date';
      }
    },
    getRemoteVersion() {
      if (this.diff != null) {
        return 'v' + this.diff.remote.version
      }
      return 'invalid value'
    },
    getRemoteEditorName() {
      if (this.diff != null) {
        return 'v' + this.diff.remote.version
      }
      return ''
    },
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mounted() {
    this.$refs['diff-check-panel-modal'].show()
    this.onCheckDiff()
  },
  methods: {
    onCheckDiff() {
      //this.notifyDiffException()
      this.fetchDiff()
    },
    enableContents() {
      this.editorOld.enable(true)
      this.editorNew.enable(true)
    },
    disableContents() {
      this.editorOld.enable(false)
      this.editorNew.enable(false)
    },
    notifyDiffException() {
    },
    fetchDiff() {
      this.$store.dispatch('section/fetchDiff', {sectionId: this.sectionId, version: this.version})
          .then((response) => {
            //console.log(response)
            this.diff = response
            this.enableContents()
            this.findDiff()
            this.disableContents();
          })
          .catch((error) => {
            // todo handle error
          })
    },
    acceptYours() {
    },
    acceptRemote() {
    },
    merge() {
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
    findDiff() {
      this.editorOld.updateContents(this.content)
      this.editorNew.updateContents(this.diff.remote.content)

      let oldContent = this.editorOld.getContents()
      let newContent = this.editorNew.getContents()

      let diff = oldContent.diff(newContent);

      for (let i = 0; i < diff.ops.length; i++) {
        let op = diff.ops[i];
        // if the change was an insertion
        if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
          // color it green
          op.attributes = {
            background: "#cce8cc",
            color: "#003700"
          };
        }
        // if the change was a deletion
        if (Object.prototype.hasOwnProperty.call(op, 'delete')) {
          // keep the text
          op.retain = op.delete;
          delete op.delete;
          // but color it red and struckthrough
          op.attributes = {
            background: "#e8cccc",
            color: "#370000",
            strike: true
          };
        }
      }
      let adjusted = oldContent.compose(diff);

      this.editorDiff.setContents(adjusted)
    },
    destroyManually() {
      this.$refs['diff-check-panel-modal'].hide()
      this.$emit('resolve')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.diff-check-editor {
  height: 70vh;
  z-index: 0;
  box-shadow: 0 0 1px 1px rgba(98, 98, 98, 0.104), 0 0 1px 1px rgba(98, 98, 98, 0.104);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;

  .quill-content {
    > .ql-container {
      box-shadow: 0 0 1px 1px rgba(87, 86, 86, 0.09), 0 0 1px 1px rgba(87, 86, 86, 0.09);
    }
  }
}

</style>