<template>
  <div class="variable-input-area">
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="h-100">
      <div class="px-2 justify-content-between align-content-center"
           v-if="Object.entries(variables).length === 0">
        <empty-placeholder/>
      </div>
      <div v-else>
        <b-list-group>
          <b-list-group-item v-show="getVariableNodeCount(variable.name)" class="flex-column align-items-start pl-2"
                             v-for="(variable) in variables.filter(v => !v.hasParent)"
                             :key="variable.id">
            <div class="">
              <dynamic-variable-element
                  :disabled="disabled"
                  :variable="variable"
                  :document-id="documentId"
                  :workspace-id="workspaceId"
                  @onTextInputChange="handleTextInputChange"
                  @onDateInputChange="handleDateInputChange"
                  @onCompanySelectionChange="handleCompanySelectionChange"
                  @onSelectionInputChange="handleSelectableInputChange"
                  @onLinkedVariableInputChange="handleLinkedVariableInputChange"
                  @onUpdate="$emit('update', $event)"/>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <span v-if="Object.entries(variables).length > 0" class="text-black-50 mr-1">
              {{ getVariableNodeCount(variable.name) }}
              </span>
              <feather-icon
                  v-if="getVariableNodeCount(variable.name) > 0"
                  @click="goToVariableDown(variable.name)"
                  icon="ChevronDownIcon"
                  class="cursor-pointer font-weight-bold"
                  size="21"
              />
              <feather-icon
                  v-if="getVariableNodeCount(variable.name) > 0"
                  @click="goToVariableUp(variable.name)"
                  icon="ChevronUpIcon"
                  class="cursor-pointer"
                  size="21"
              />
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {BCardText, BListGroup, BListGroupItem, BOverlay, VBPopover} from 'bootstrap-vue'

import {mapState} from "vuex";
import {isDynamicRouteActive} from "@core/utils/utils";
import {PEventBus} from "@/services/PEventBus";
import Ripple from 'vue-ripple-directive'
import EmptyPlaceholder from "@/views/Documents/Editor/Variables/EmptyPlaceholder";
import DynamicVariableElement from "@/views/Documents/Editor/Variables/DynamicVariableElement";

export default {
  name: "VariableList",
  components: {
    BListGroupItem,
    BListGroup,
    EmptyPlaceholder,
    VuePerfectScrollbar,
    DynamicVariableElement
  },
  props: {
    documentId: String,
    workspaceId: String,
    disabled: Boolean,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      scrollerData: [],
      scrollCursorData: {}
    }
  },
  computed: {
    ...mapState({
      loading: state => state.document.variablesLoading,
      sections: state => state.document.sections,
    }),
    variables() {
      return this.$store.getters["document/getVariables"]
    },
  },
  mounted() {
    this.setupVariableNodesForScroll()
  },
  methods: {
    getVariableNodeCount(variableName) {
      return this.scrollerData.filter(a => a.name === variableName).length
    },
    goToVariableUp(dataName) {
      const nodeList = document.querySelectorAll(`[data-name="${dataName}"]`)
      nodeList[this.scrollCursorData[dataName]].scrollIntoView({
        behavior: 'auto',
      })
      if (nodeList.length - 1 === this.scrollCursorData[dataName]) {
        this.scrollCursorData[dataName] = 0
      } else {
        this.scrollCursorData[dataName] += 1
      }
    },
    goToVariableDown(dataName) {
      const nodeList = document.querySelectorAll(`[data-name="${dataName}"]`)
      if (this.scrollCursorData[dataName] === 0 ) {
        this.scrollCursorData[dataName] = nodeList.length - 1
      } else {
        this.scrollCursorData[dataName] -= 1
      }
      nodeList[this.scrollCursorData[dataName]].scrollIntoView({
        behavior: 'auto',
      })
    },
    setupVariableNodesForScroll() {
      this.variables.forEach(variable => {
        const nodeList = document.querySelectorAll(`[data-name="${variable.name}"]`)
        for (let i = 0; i < nodeList.length; i++) {
          this.scrollerData.push({
            name: variable.name,
            node: nodeList[i],
            offsetTop: nodeList[i].offsetTop,
          })
          this.scrollCursorData[variable.name] = 0
        }
      })
      this.scrollerData.sort((a, b) => {
        if (a.offsetTop > b.offsetTop) {
          return 1
        } else if (a.offsetTop < b.offsetTop) {
          return -1
        } else {
          return 0
        }
      })
      console.log(this.scrollerData)
    },
    handleDateInputChange(event) {
      const value = {userValue: event.inputValue, mappedValue: event.inputValue}
      this.$store.dispatch('document/setVariableValue', {
        documentId: this.documentId,
        variableId: event.varId,
        overridingDescription: event.overridingDescription,
        value: value
      }).then(() => {
        //this.recompose(event.varId, value, event.inputType)
        PEventBus.$emit(`${event.varId}-variable-updated`, value)
      })
    },
    handleCompanySelectionChange(event) {
      this.$store.dispatch('document/setVariableValue', {
        documentId: this.documentId,
        variableId: event.varId,
        overridingDescription: event.overridingDescription,
        value: {userValue: event.selectedValues, mappedValue: event.selectedValues}
      }).then(() => {
        this.recompose(event.varId, event.inputValue, event.inputType)
      })
    },
    handleSelectableInputChange(event) {
      this.$store.dispatch('document/setVariableValue', {
        documentId: this.documentId,
        variableId: event.varId,
        overridingDescription: event.overridingDescription,
        value: {userValue: event.selectedValue.option, mappedValue: event.selectedValue.option}
      }).then(() => {
        this.recompose(event.varId, event.inputValue, event.inputType)
      })
    },
    handleTextInputChange(event) {
      const value = {userValue: event.inputValue, mappedValue: event.inputValue}
      this.$store.dispatch('document/setVariableValue', {
        documentId: this.documentId,
        variableId: event.varId,
        overridingDescription: event.overridingDescription,
        value: value
      }).then(() => {
        this.recompose(event.varId, value, event.inputType)
      })
    },
    handleLinkedVariableInputChange(event) {
      const value = {userValue: event.selectedValue, mappedValue: event.selectedValue}
      this.$store.dispatch('document/setVariableValue', {
        documentId: this.documentId,
        variableId: event.varId,
        overridingDescription: event.overridingDescription,
        value: value
      }).then(() => {
        this.recompose(event.varId, value, event.inputType)
      })
    },
    recompose(variableId, value, presentationMethod) {
      console.log(variableId, value, presentationMethod)
      this.$store.dispatch('document/recomposeVariable', {
        variableId: variableId,
        userProcessedValue: value,
        presentationMethod: presentationMethod
      }).then(res => {
        PEventBus.$emit('refresh-all-section-content')
      })
    },
  },

  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 100,
    }
    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style lang="scss" scoped>
.variable-input-area {
  width: 100%;
  background-color: transparent;
}

.editor-left-side-icon-wrapper {
  .feather {
    color: red;
    height: 17px;
    width: 17px;
  }
}
</style>
