<template>

  <div :class="[isOpen
            ? 'nav-vertical left-sidebar-opened'
            : 'nav-vertical left-sidebar-closed']">
    <div
        :class="{'w-100 d-flex header p-fixed align-content-center pl-2 pr-1 py-1 my-auto py-auto align-content-center align-items-center justify-content-between': true}">
      <h6 class="section-label my-auto pl-2">
        {{ selectedTab.title }}
      </h6>
      <b-button
          v-if="activeTab > 0"
          pill
          @click="selectTab(0)"
          class="rounded px-1 py-25" variant="flat-primary">
        <feather-icon
            icon="ArrowLeftIcon"
            size="15"
            class="cursor-pointer"></feather-icon>
        <span style="margin-left: 5px;">{{ $t('back') }}</span>
      </b-button>
      <b-button
          v-else
          pill
          @click="emitCloseLeft"
          class="rounded px-1 py-25" variant="flat-primary">
        <feather-icon
            icon="XIcon"
            size="15"
            style="z-index: 1000"
            class="cursor-pointer text-primary font-weight-bold"
        />
      </b-button>
    </div>
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area">
      <div class='wrapper ml-1 justify-content-center'>
        <div>
          <div class='tabs__light  justify-content-start'>

            <div v-if="activeTab === 0" class="header flex w-100 mt-1">
              <ul style="list-style: none" class="w-100 px-2 ">
                <li @click='selectTab(1)'>
                  <tab-item :icon="'ListIcon'"
                            :title="$t('document.leftSideBar.sections')"
                            :badge-count="statistics['sectionCount']"/>
                </li>
                <li @click='selectTab(2)'>
                  <tab-item :icon="'HashIcon'"
                            :title="$t('document.leftSideBar.variables')"
                            :badge-count="statistics['documentVariableCount']"/>
                </li>
                <li @click='selectTab(4)'>
                  <tab-item :icon="'PaperclipIcon'"
                            :title="$t('document.leftSideBar.attachments')"
                            :badge-count="statistics['fileCount']"/>
                </li>
                <li @click='selectTab(5)'>
                  <tab-item :icon="'LinkIcon'"
                            :title="$t('document.leftSideBar.relations')"
                            :badge-count="statistics['relationCount']"/>
                </li>
                <li @click='selectTab(6)'>
                  <tab-item :icon="'BellIcon'"
                            :title="$t('document.leftSideBar.reminders')"
                            :badge-count="statistics['reminderCount']"/>
                </li>
                <li @click='selectTab(3)'>
                  <tab-item :icon="'LayoutIcon'"
                            :title="$t('document.leftSideBar.templates')"/>
                </li>
                <li @click='selectTab(7)'>
                  <tab-item :icon="'ClockIcon'"
                            :title="$t('document.leftSideBar.history')"/>
                </li>
              </ul>
            </div>
            <div style="height: 100%;">
              <div>
                <tab :index="1"
                     :is-active="activeTab === 1"
                     icon="ListIcon"
                     :title="$t('document.leftSideBar.sections')">
                  <table-of-contents
                      @load-sections="this.$emit('load-sections')"
                      @reload-document="this.$emit('reload-document')"
                      :workspace-id="document.idWorkspace"
                      :document-id="documentIdFromUrl"
                      :document-type="document.documentType"
                  />
                </tab>
                <tab :index="2"
                     :is-active="activeTab === 2"
                     icon="HashIcon"
                     :title="$t('document.leftSideBar.variables')">
                  <variable-list
                      :disabled="document.lifecycleDetail.status.name !== 'DRAFT'"
                      :workspace-id="document.idWorkspace"
                      :document-id="documentIdFromUrl"
                  />
                </tab>
                <tab :index="3"
                     :is-active="activeTab === 3"
                     icon="DatabaseIcon"
                     :title="$t('document.leftSideBar.library')">
                  <library-data-pool :document-id="documentIdFromUrl"/>
                </tab>
                <tab :index="4"
                     :is-active="activeTab === 4"
                     icon="PaperclipIcon"
                     :title="$t('document.leftSideBar.attachments')">
                  <attachments/>
                </tab>
                <tab :index="5"
                     :is-active="activeTab === 5"
                     icon="LinkIcon"
                     :title="$t('document.leftSideBar.relations')">
                  <document-link/>
                </tab>
                <tab :index="6" :is-active="activeTab === 6" icon="BellIcon"
                     :title="$t('document.leftSideBar.reminders')">
                  <reminder-list/>
                </tab>
                <tab :index="7"
                     :is-active="activeTab === 7"
                     icon="ClockIcon"
                     :title="$t('document.leftSideBar.history')">
                  <history-list/>
                </tab>
              </div>
              <div v-if="activeTab === 0" class="px-3 justify-content-start  align-items-start w-full p-0 flex-wrap">
                <h6 class="section-label pt-2">
                  {{ $t('document.leftSideBar.workspace') }}
                </h6>
                <b-badge variant="light-secondary" class=" p-1 font-medium-1">
                  {{ document.workspace }}
                </b-badge>
                <h6 class="section-label pt-2">
                  {{ $t('document.leftSideBar.status') }}
                </h6>
                <b-badge variant="light-secondary" class=" p-1 font-medium-1">
                  {{ $t('documentStates.'.concat(document.lifecycleDetail.status.name)) }}
                </b-badge>
                <h6 class="section-label pt-2">
                  {{ $t('document.leftSideBar.category') }}
                </h6>
                <b-badge variant="light-danger" class=" p-1 font-medium-1">
                  {{ $t('documentTypes.'.concat(document.documentType.name)) }}
                </b-badge>
                <labels :document-id="documentIdFromUrl" class="mt-2"
                        style="width: 100%"/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>

</template>

<script>
import VariableList from '@/views/Documents/Editor/Variables/InputList'
import LibraryDataPool from '@/views/Documents/Editor/LibraryData/List'
import Attachments from '@/views/Documents/Editor/Attachment/List'
import DocumentLink from '@/views/Documents/Editor/DocumentLink/List'
import ReminderList from '@/views/Documents/Editor/Reminder/List'
import HistoryList from '@/views/Documents/Editor/History/List'
import {BBadge, BButton, VBPopover,} from 'bootstrap-vue'
import TabItem from "@/views/PapirComponents/Tabs/TabItem";
import TableOfContents from '@/views/Documents/Editor/Metadata/index'
import Ripple from 'vue-ripple-directive'
import {mapState} from "vuex";
import {PEventBus} from "@/services/PEventBus";
import Tab from '@/views/PapirComponents/Tabs/Tab'
import Labels from '../Labels/index'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import i18n from "@/libs/i18n";

export default {
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  components: {
    TableOfContents,
    VariableList,
    LibraryDataPool,
    Attachments,
    ReminderList,
    DocumentLink,
    HistoryList,
    Tab, VuePerfectScrollbar,
    TabItem,
    BButton, BBadge,
    Labels
  },
  props: {
    isOpen: Boolean,
  },
  created() {
    window.addEventListener("resize", this.onEditorScreenResized);
  },
  destroyed() {
    window.removeEventListener("resize", this.onEditorScreenResized);
  },
  computed: {
    ...mapState({
      loading: state => state.document.variablesLoading,
      document: state => state.document.document,
      statistics: state => state.document.statistics
    }),
    documentIdFromUrl() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.fetchDocumentBadges()
    this.selectTab(this.$route.query.tab === undefined ? 0 : parseInt(this.$route.query.tab))
    PEventBus.$on('update-left-tab', (activeTab) => this.onUpdateLeftTab(activeTab))
  },
  methods: {
    fetchDocumentBadges() {
      this.$store.dispatch('document/fetchDocumentStatistics', this.documentIdFromUrl)
    },
    onUpdateLeftTab(activeTab) {
      const currentActiveTab = parseInt(this.$route.query.tab)
      if (activeTab === 1 && currentActiveTab <= activeTab) { // open toc tab
        this.selectTab(activeTab)
      }
    },
    selectTab(i) {
      this.activeTab = i
      this.$emit('onTabChange', this.activeTab)
      // loop over all the tabs
      this.tabItems.forEach(item => {
        if (item.index === i) {
          this.selectedTab = item;
          return
        }
      })
      this.$router.replace(`/documents/${this.documentIdFromUrl}/editor?tab=${i}`)
          .catch((err) => {
          })
    },
    emitCloseLeft() {
      PEventBus.$emit('close-left')
    },
  },
  data() {
    return {
      activeTab: 0,
      // Perfect Scrollbar
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      selectedTab: {
        index: 0,
        title: i18n.t('document.leftSideBar.manage'),
        icon: '',
      },
      tabItems: [
        {
          index: 0,
          title: i18n.t('document.leftSideBar.manage'),
          icon: '',
        },
        {
          index: 1,
          title: i18n.t('document.leftSideBar.sections'),
          icon: 'ListIcon',
        },
        {
          index: 2,
          title: i18n.t('document.leftSideBar.variables'),
          icon: 'HashIcon',
        },
        {
          index: 3,
          title: i18n.t('document.leftSideBar.library'),
          icon: 'DatabaseIcon',
        },
        {
          index: 4,
          title: i18n.t('document.leftSideBar.attachments'),
          icon: 'PaperclipIcon',
        },
        {
          index: 5,
          title: i18n.t('document.leftSideBar.relations'),
          icon: 'LinkIcon',
        },
        {
          index: 6,
          title: i18n.t('document.leftSideBar.reminders'),
          icon: 'BellIcon',
        },
        {
          index: 7,
          title: i18n.t('document.leftSideBar.history'),
          icon: 'ClockIcon',
        }
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.wrapper {
  position: relative;
  background-color: transparent;
}

.header {
  border-bottom: 1px solid rgba($black, 0.05);
}
</style>


<style lang="css" scoped>
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}


ul.tabs__header > li {
  cursor: pointer;
  text-align: center;
}

ul.tabs__header > li.tab__selected {
  font-weight: bold;
}


.tabs__light {
  margin-left: 0;
}


.tabs__light li {
  --text-opacity: 5;
  font-weight: 600;
  color: #4b4b4b;
  font-size: 1.1em;
}

.tabs__light li:hover {
  background-color: #03A5AA;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.tabs__light li.tab__selected {
  color: #03A5AA;
}

.scroll-area {
  height: calc(100vh - 100px);
  touch-action: none;
}


</style>
