<!--suppress ALL -->
<template>
  <!-- (editProgressing || (newCreating && emptySection))-->
  <div>

    <div :id="`section-${section.sectionId}`" style="font-family: 'Times New Roman'" :class="getClassOfEditorDiv">

      <div class="cursor-pointer">
        <div
            v-if="section.accessLevel !== 'NO_ACCESS'"
            style="width: 100%; margin-bottom: 8px; position: inherit">
          <div ref="editorRef">
            <div v-show="!isSectionLoading"
                 :class="{'editable-header-input-container' : true}">
              <div class="justify-content-start align-items-center d-flex overflow-hidden">
                <div v-if="document.numbering" contenteditable="false" class="mr-25">{{ index }}.</div>
                <div @keydown="handleEditableKeyPress"
                     @keypress="handleKeyPress"
                     :ref="`section-header-${section.sectionId}`"
                     class="editable-section-header overflow-hidden"
                     :placeholder="$t('document.typeTitle')"
                     @blur="onHeaderEnter"
                     @input="`if(this.innerHTML.trim()==='<br>') this.innerHTML=''`"
                     v-on:keyup.enter="onHeaderEnter"
                     :contenteditable="!isDisabled && document.lifecycleDetail.editable">
                </div>
              </div>
            </div>
            <div @click="onFocus()">
              <loading-placeholder v-if="isSectionLoading">
              </loading-placeholder>
              <papir-editor
                  v-show="!isSectionLoading"
                  :id="`editor-${section.sectionId}`"
                  :ref="`editor-ref-${section.sectionId}`"
                  :index="index"
                  :placeholder="`#${this.index} ` + $t('document.youCanStartHere')"
                  @change="onEditorChange"
                  @focus="setCursor"
                  :context-menu-enabled="true"
                  :section-id="section.sectionId"
                  :disabled="isDisabled"
                  :access-level="section.accessLevel"
                  @showSaveAsLibraryDataModal="showSaveAsLibraryDataModal"
                  :editable="document.lifecycleDetail.editable">
              </papir-editor>
            </div>
          </div>
        </div>
      </div>
      <managing-single-variable-modal
          @close="onCloseVariableUpdateModal"
          :disabled="documentStatus !== 'DRAFT'"
          :workspace-id="workspaceId"
          :section-id="section.sectionId"
          :document-id="documentId"/>
    </div>
  </div>
</template>

<script>
import {VBPopover, VBTooltip} from 'bootstrap-vue'
import {mapState} from "vuex";
import Ripple from "vue-ripple-directive";

import {PEventBus} from "@/services/PEventBus";
import 'quill/dist/quill.core.css'

import {Quill, quillEditor} from "@/views/Documents/Editor/Component";

import '@/views/Documents/Editor/Extension/papir-numbering-module'
import '@/views/Documents/Editor/Extension/variable-module';
import '@/views/Documents/Editor/Extension/index.css'
import '@/views/Documents/Editor/Extension/numbering.scss'
import "@/views/Documents/Editor/Extension/Blots/span-block"
import "@/views/Documents/Editor/Extension/Blots/heading"
import "@/views/Documents/Editor/Extension/Blots/heading-formatter"
import "@/views/Documents/Editor/Component/quill.extension.css"
import {QuillUtils} from "@/services/Utils";
import i18n from "@/libs/i18n";
import LoadingPlaceholder from "@/views/Documents/Editor/Sections/LoadingPlaceholder";
import ManagingSingleVariableModal from "@/views/Documents/Editor/UpdateVariableModal";

const Delta = Quill.import('delta')
const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
let Font = Quill.import("attributors/style/font");
// We do not add Sans Serif since it is the default
Font.whitelist = ['Inconsolata', 'Quicksand', 'Nunito Sans', 'roboto', 'monospace', 'Sofia', 'arial', 'slabo', 'Mirza', 'Times New Roman', 'serif'];
Quill.register(Font, true);

export default {
  name: "EditorSection",
  components: {
    "PapirEditor": quillEditor,
    LoadingPlaceholder,
    ManagingSingleVariableModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    readonly: Boolean,
    hideActions: Boolean,
    documentId: String,
    workspaceId: String,
    section: {type: Object},
    editProgressing: {type: Boolean, default: false},
    newCreating: {type: Boolean, default: false},
    focusEnabled: {type: Boolean, default: false},
    index: Number,
    selected: Boolean,
    removeFn: Function,
    isNew: {type: Boolean, default: false},
    last: Boolean,
    first: Boolean,
    editing: {type: Boolean, default: false},
    group: String,
    isLoading: {type: Boolean, default: false},
    documentStatus: String
  },
  data() {
    return {
      lastSavedRange: {
        range: {},
      },
      forceRenderer: 0,
      isSectionLoading: false,
      PapirQuill: null,
      isVariableManagementModalActive: false,
      toolbarId: 'toolbar_'.concat(this.section.sectionId),
      mouseover: false,
      change: new Delta(),
      autoSaveIntervalId: null,
      content: this.section.content,
      lastFocusedRange: {index: 0, length: 0},
      clickedBlot: null,
      clickedVariable: null,
      isEmptyHeaderEditable: false
    }
  },
  watch: {
    section: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (this.$refs[`editor-ref-${this.section.sectionId}`]) {
          //this.initContent()
        }
      }
    },
    index: {
      immediate: true,
      deep: true,
      handler(newValue, oldVaue) {

        QuillUtils.reorderSectionHeadings(this.section.content, parseInt(newValue))
        this.initContent()
      }
    }
  },
  mounted() {

    if (this.section != null) {
      PEventBus.$on(`${this.section.sectionId}-remove-variable`, this.onVariableRemoved)
      PEventBus.$on(`${this.section.sectionId}-select-section`, this.onSelectSection)
      PEventBus.$on(`${this.section.sectionId}-unselect-section`, this.onUnSelectSection)
      PEventBus.$on(`${this.section.sectionId}-handle-format`, this.onFormat)
      PEventBus.$on(`${this.section.sectionId}-embed-variable`, this.embedVariableInToEditor)
      PEventBus.$on(`${this.section.sectionId}-variable-selected`, this.onVariableInsert)
      PEventBus.$on(`${this.section.sectionId}-set-cursor`, this.setCursorOnLastFocusedIndex)
      PEventBus.$on(`${this.section.sectionId}-save`, this.saveSilent)
      PEventBus.$on(`${this.section.sectionId}-refresh-editor`, this.refreshContent)
      PEventBus.$on(`${this.section.sectionId}-variable-clicked`, this.onVariableClicked)
      PEventBus.$on(`${this.section.sectionId}-find-diff`, this.fetchDiffBetweenVersions)

      this.$nextTick(() => {
        let sectionHeader = this.$refs[`section-header-${this.section.sectionId}`]
        if (this.getSectionHeader !== null && this.getSectionHeader.length > 0) {
          sectionHeader.innerHTML = this.getSectionHeader
        }
      })
    }

    PEventBus.$on('apply-selected-template', this.appendAsText)

    let _quill = this.$refs[`editor-ref-${this.section.sectionId}`].quill
    if (_quill !== 'undefined') {
      this.PapirQuill = this.$refs[`editor-ref-${this.section.sectionId}`].papirQuill
    }

    if (this.PapirQuill.quill) {
      this.initContent()
    }
  },
  destroyed() {
    this.PapirQuill.quill.root.removeEventListener("blur", this.handleEditorBlur);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.beforeWindowUnload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload)
    PEventBus.$off(`${this.section.sectionId}-handle-format`, this.onFormat)
    PEventBus.$off(`${this.section.sectionId}-select-section`, this.onSelectSection)
    PEventBus.$off(`${this.section.sectionId}-unselect-section`, this.onUnSelectSection)
    PEventBus.$off(`${this.section.sectionId}-remove-variable`, this.onVariableRemoved)
    PEventBus.$off(`${this.section.sectionId}-embed-variable`, this.embedVariableInToEditor)
    PEventBus.$off(`${this.section.sectionId}-set-cursor`, this.setCursorOnLastFocusedIndex)
    PEventBus.$off(`${this.section.sectionId}-save`, this.saveSilent)
    PEventBus.$off('apply-selected-template', this.appendAsText)
    PEventBus.$off(`${this.section.sectionId}-variable-selected`, this.onVariableInsert)
    PEventBus.$off(`${this.section.sectionId}-refresh-editor`, this.refreshContent)
    PEventBus.$off(`${this.section.sectionId}-find-diff`, this.fetchDiffBetweenVersions)
    PEventBus.$off(`${this.section.sectionId}-variable-clicked`, this.onVariableClicked)
  },
  computed: {
    ...mapState({
      variableList: state => state.variables.variableList, // fetched all for menu
      usedVariableList: state => state.document.variableList,
      selectedSection: state => state.document.selectedSectionStatus,
      document: state => state.document.document,
      focusedSection: state => state.document.focusedSectionId
    }),
    getJsonContent() {
      return this.PapirQuill.quill.getContents()
    },
    getClassOfEditorDiv() {
      return this.isSelected && !this.readonly ? 'section-selected' : '';
    },
    isLocked() {
      return this.section.status.name === 'LOCKED'
    },
    isSelected() {
      return this.section.sectionId === this.selectedSection.sectionId
    },
    isDisabled() {
      return this.readonly || this.isLocked || this.section.accessLevel === 'READ' || this.section.status.name !== 'DRAFT'
    },
    isHeaderEditable() {
      return !this.isEmptyHeaderEditable && (this.section.header === null || this.section.header === '')
    },
    getSectionHeader() {
      return this.section.header
    }
  },
  methods: {
    setEditableHeader() {
      var p = document.getElementById(`section-header-${this.section.sectionId}`);
      let s = window.getSelection()
      let r = document.createRange()
      r.setStart(p, p.childElementCount)
      r.setEnd(p, p.childElementCount)
      s.removeAllRanges()
      s.addRange(r)
      if (this.section.header === null || this.section.header === '') {
        this.isEmptyHeaderEditable = true
      }
    },
    onFormat(format) {
      let range = this.PapirQuill.quill.getSelection()
      if (range == null) {
        this.focus()
        range = this.PapirQuill.lastSelection
      }
      switch (format.type) {
        case 'FontSize': {
          const formats = this.PapirQuill.quill.getFormat(range);
          this.PapirQuill.quill.formatText(range, 'size', format.value, 'user')
          this.setFormats()
          break;
        }
        case 'FontStyle': {
          this.formatFont(format.value)
          break;
        }
        case 'Bold': {
          const formats = this.PapirQuill.quill.getFormat(range);
          this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'bold', !Object.prototype.hasOwnProperty.call(formats, 'bold'), 'user')
          this.setFormats()
          break;
        }
        case 'Italic': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'italic', !Object.prototype.hasOwnProperty.call(formats, 'italic'), 'user')
          this.setFormats()
          break;
        }
        case 'Underline': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'underline', !Object.prototype.hasOwnProperty.call(formats, 'underline'), 'user')
          this.setFormats()
          break
        }
        case 'Strikethrough': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'strike', !Object.prototype.hasOwnProperty.call(formats, 'strike'), 'user')
          this.setFormats()
          break
        }
        case 'Brush': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          if (Object.prototype.hasOwnProperty.call(formats, 'background')) {
            this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'background', false)
          } else {
            this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'background', '#FFFF00')
          }
          this.setFormats()
          break
        }
        case 'Highlight': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          if (Object.prototype.hasOwnProperty.call(formats, 'color')) {
            this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'color', false)
          } else {
            this.PapirQuill.quill.formatText(this.PapirQuill.quill.getSelection(), 'color', '#ea5455')
          }
          this.setFormats()
          break
        }
        case 'Link': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          const range = this.PapirQuill.quill.getSelection()
          const text = prompt('link text:');
          const url = prompt('link url:');
          this.PapirQuill.quill.insertText(range, text, 'link', url);
          this.setFormats()
          break
        }
        case 'CodeBlock': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'code-block', !Object.prototype.hasOwnProperty.call(formats, 'code-block'))
          this.setFormats()
          break
        }
        case 'ListOrdered': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          if (Object.prototype.hasOwnProperty.call(formats, 'list')) {
            if (formats['list'] === 'ordered') {
              this.PapirQuill.quill.format('list', false)
            } else {
              this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'list', 'ordered')
            }
          } else {
            this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'list', 'ordered')
          }
          this.setFormats()
          break
        }
        case 'Clause': {
          this.addClause(format.level)
          break
        }
        case 'Bullet': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          if (Object.prototype.hasOwnProperty.call(formats, 'list')) {
            if (formats['list'] === 'bullet') {
              this.PapirQuill.quill.format('list', false)
            } else {
              this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'list', 'bullet')
            }
          } else {
            this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'list', 'bullet')
          }
          this.setFormats()
          break
        }
        case 'Indent': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'indent', '+1')
          this.setFormats()
          break
        }
        case 'Deindent': {
          const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
          this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'indent', '-1')
          this.setFormats()
          break
        }
        case 'NormalText' : {
          this.PapirQuill.quill.removeFormat(this.PapirQuill.quill.getSelection())
          break
        }
        case 'Heading2': {
          this.formatHeading2()
          break
        }
        case 'Heading3': {
          this.formatHeading3()
          break
        }
        case 'Heading4': {
          this.formatHeading4()
          break
        }
        case 'Heading5': {
          this.formatHeading5()
          break
        }
        case 'AlignJustify': {
          this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'align', 'justify')
          break
        }
        case 'AlignCenter': {
          this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'align', 'center')
          break
        }
        case 'AlignRight': {
          this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'align', 'right')
          break
        }
        case 'AlignLeft': {
          this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection(), 'align', '')
          break
        }
        case 'Reorder': {
          this.reorderNumbering()
          break
        }
        case 'CreateTable': {
          this.createTable()
          break
        }
      }
    },
    formatFont(font) {
      //const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection());
      this.PapirQuill.quill.formatText(0, this.PapirQuill.quill.getLength(), 'font', font, 'user')
      this.initContent('silent', false)
    },
    formatHeading2() {
      this.formatHeading(2, 'h2')
      const defaultFont = localStorage.getItem('font-style')
      this.formatFont(defaultFont)
      this.initContent('silent', false)
    },
    formatHeading3() {
      this.formatHeading(3, 'h3', true)
      this.formatFont(localStorage.getItem('font-style'))
      this.initContent('silent', false)
    },
    formatHeading4() {
      this.formatHeading(4, 'h4', true)
      this.formatFont(localStorage.getItem('font-style'))
      this.initContent('silent', false)
    },
    formatHeading5() {
      this.formatHeading(5, 'h5', true)
      this.formatFont(localStorage.getItem('font-style'))
      this.initContent('silent', false)
    },
    formatHeading(level, heading, newLineBefore = false) {

      if (this.PapirQuill.quill.getSelection().length === 0) {
        return alert(i18n.t('document.setTitleWarning'))
      }

      const data = {
        parentIndex: this.index,
        text: "",
        level: level,
        numbering: '',
        lineIndex: this.lastFocusedRange.index,
      }

      const formats = this.PapirQuill.quill.getFormat(this.PapirQuill.quill.getSelection())

      //console.log(this.PapirQuill.quill.getLeaf(this.PapirQuill.quill.getSelection().index))

      if (!formats['header']) {
        // if (newLineBefore) {
        //   this.PapirQuill.quill.insertText(this.PapirQuill.quill.getSelection().index, '\n', false)
        // }
        //this.PapirQuill.quill.insertText(this.PapirQuill.quill.getSelection().index, '\n')
        this.PapirQuill.quill.insertEmbed(this.PapirQuill.quill.getSelection().index, 'papirNumbering', data, 'user')
        this.PapirQuill.quill.formatLine(this.PapirQuill.quill.getSelection().index, 'header', heading)
        QuillUtils.reorderSectionHeadings(this.PapirQuill.quill.getContents(), this.index)
      }
      //console.log(this.PapirQuill.quill.getLine(this.PapirQuill.quill.getSelection().index))
    },
    addClause(clauseLevel) {
      if (!clauseLevel) {
        const line = this.PapirQuill.quill.getLine(this.PapirQuill.quill.getSelection().index)
        console.log(line)
        const blot = line[0]
        const numberingDataset = blot['domNode']['firstChild']['dataset']
        const level = numberingDataset['level']
        clauseLevel = level
      }
      const data = {
        parentIndex: this.index,
        text: "",
        level: clauseLevel,
        numbering: '',
        lineIndex: this.lastFocusedRange.index,
      }

      this.PapirQuill.quill.insertText(this.PapirQuill.quill.getSelection().index, '\n', false)
      this.PapirQuill.quill.insertEmbed(this.PapirQuill.quill.getSelection().index, 'papirNumbering', data, 'user')
      this.PapirQuill.quill.insertText(this.PapirQuill.quill.getSelection().index + 1, ' ', false)
      this.PapirQuill.quill.setSelection(this.PapirQuill.quill.getSelection().index + 1, 0)
      QuillUtils.reorderSectionHeadings(this.PapirQuill.quill.getContents(), this.index)
      this.initContent('silent', false)
    },
    reorderNumbering() {
      QuillUtils.reorderSectionHeadings(this.section.content, this.index)
      this.initContent('user', false)
    },
    createTable() {
      this.focus()
      let tableModule = this.PapirQuill.quill.getModule("table");
      tableModule.insertTable(3, 3);
    },
    onVariableClicked(blot, data) {
      // TODO fix it. redundant way to fix empty modal issue
      if (this.documentStatus === 'DRAFT') {
        this.$bvModal.show(`modalManageVariableId-${this.section.sectionId}`)
        this.$store.commit('document/SET_EDITOR_FOCUS_BY_SECTION_ID', this.section.sectionId)
        const embedVariable = (blot, variableDefinition) => this.embedVariableInToEditor(blot, variableDefinition)
        // this will open manage variable modal
        const index = blot.offset(this.PapirQuill.quill.scroll)

        this.$store.commit('document/SAVE_LAST_INDEX_ON_EDITOR', {
          sectionId: data['sectionId'],
          lastFocusedIndex: index
        })
        PEventBus.$emit(`${this.section.sectionId}-open-variable-detail-clicked`, blot, data, embedVariable)
      }
    },
    onCloseVariableUpdateModal() {
      this.$bvModal.hide(`modalManageVariableId-${this.section.sectionId}`)
      this.setCursorOnLastFocusedIndex()
    },
    handleEditableKeyPress(e) {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    },
    handleKeyPress(e) {
      let p = document.getElementById(`section-header-${this.section.sectionId}`);
      let placeholder = document.getElementById(`placeholder-section-header-${this.section.sectionId}`);
      placeholder.innerHTML = null
      let textLen = p.innerHTML.length
      if (textLen > 0) {
        placeholder.innerHTML = null
      } else {
        placeholder.innerHTML = 'Baslik ekle'
      }
    },
    putQuillInstanceInStore() {
      this.$store.commit('document/SET_QUILL_INSTANCE', {
        sectionId: this.section.sectionId,
        quillInstance: this.PapirQuill.quill
      })
    },
    fetchDiffBetweenVersions(versionId) {
      this.$store.dispatch('section/fetchDiff', {
        sectionId: this.section.sectionId,
        version: versionId,
      }).then((response) => {
        let delta = QuillUtils.applyDiff(this.PapirQuill.quill, response)
        this.PapirQuill.quill.setContents(delta, 'silent')
      }).catch((error) => {
        // todo handle error
      })
    },
    onSelectSection() {
      this.setSectionId()
      const elem = this.$el
      elem.scrollIntoView({
        behavior: 'auto',
      })
    },
    onUnSelectSection() {
      this.unsetSection()
    },
    onHeaderEnter(event) {
      event.target.blur()
      event.preventDefault()
      this.onFocus()
      this.$store.dispatch('section/updateHeader', {sectionId: this.section.sectionId, header: event.target.innerText})
          .then(res => {
            if (this.isEmptyHeaderEditable) {
              this.isEmptyHeaderEditable = false
            }
            this.focus()
          })

    },
    refreshContent(source = 'silent') {
      setTimeout(() => {
        this.PapirQuill.quill.setContents([], 'silent')
        this.PapirQuill.quill.setContents(this.section.content, source)
      }, 0)

    },
    initContent(source = 'silent', showLoading = true) {
      this.isSectionLoading = showLoading
      if (this.PapirQuill.quill) {
        if (this.readonly) {
          this.PapirQuill.quill.enable(false)
          // disable sub heading blots
          QuillUtils.setReadonlyParts(this.section.content)
        }

        if (this.section.content !== null) {
          this.optimizedContentInitialization()
        }
      }
    },
    optimizedContentInitialization() {
      const opsLength = this.section.content.ops.length
      if (opsLength < 1000) {
        setTimeout(() => {
          this.PapirQuill.quill.setContents(this.section.content, 'silent');
          this.isSectionLoading = false
          this.PapirQuill.quill.setSelection(this.lastFocusedRange.index + 1, 0)
          this.PapirQuill.quill.keyboard.addBinding(
              {key: 'Enter', shiftKey: true, ctrlKey: true},
              {}, (range, context) => {
                console.log('LAAANN')
                this.onFormat({type: 'Clause'})
              });
          this.PapirQuill.quill.keyboard.addBinding(
              {key: '@', shiftKey: true, ctrlKey: true},
              {}, (range, context) => {

                this.onFormat({type: 'Heading2'})
              });
          this.PapirQuill.quill.keyboard.addBinding(
              {key: '3', shiftKey: true, ctrlKey: true},
              {}, (range, context) => {
                this.onFormat({type: 'Heading3'})
              });

          this.PapirQuill.quill.keyboard.addBinding(
              {key: '4', shiftKey: true, ctrlKey: true},
              {}, (range, context) => {
                this.onFormat({type: 'Heading4'})
              });
        }, 0)
      } else {
        this.iterativeUpdate()
      }
    },
    iterativeUpdate() {
      const batch = 400
      let stack = []
      for (let i = this.section.content.ops.length; i >= 0; i--) {
        let op = this.section.content.ops[i];
        stack.push(op)
        if (stack.length >= batch) {
          this.updateContents(stack)
          stack = []
        }
      }

      if (stack.length > 0) {
        this.updateContents(stack)
      }
    },
    updateContents(stack) {
      let tempk = new Delta()
      tempk = tempk.compose(new Delta(stack));
      this.PapirQuill.quill.updateContents(tempk, 'silent');
    },
    exitEditor() {
      this.unsetSection()
    },
    setFormats(selection = this.PapirQuill.quill.getSelection()) {
      if (selection !== null) {
        this.$store.commit("document/SET_SELECTED_SECTION_FORMAT", this.PapirQuill.getFormat(selection));
      }
    },
    setSectionId() {
      this.$store.commit("document/SET_SELECTED_SECTION_ID", this.section.sectionId)
    },
    setUnsaved() {
      this.$store.commit("document/SET_SELECTED_SECTION_UNSAVED")
    },
    setSaved() {
      this.$store.commit("document/SET_SELECTED_SECTION_SAVED")
    },
    setAutoSavingActive() {
      this.$store.commit("document/SET_SELECTED_SECTION_AUTO_SAVING")
    },
    unsetSection() {
      this.mouseover = false
      this.$store.commit("document/SET_SECTION_UNSELECTED")
    },
    onVariableInsert(variableDefinition, color) {
      this.$nextTick(() => {
        PEventBus.$emit(`${this.section.sectionId}-open-variable-detail-added`, variableDefinition, this.section.sectionId, color, (blot, variableDefinition) => this.embedVariableInToEditor(blot, variableDefinition))
      })
    },
    embedVariableInToEditor(blot, variableDefinition) {
      this.$store.commit('document/ADD_TO_VARIABLE_BLOT_LIST', {
        name: variableDefinition.name,
        index: this.lastFocusedRange.index,
      })

      //console.log(blot.offset(this.PapirQuill.quill.scroll), this.lastFocusedRange.index)
      variableDefinition.denotationChar = "";
      if (blot !== null) {
        const blotIndex = blot.offset(this.PapirQuill.quill.scroll)
        this.PapirQuill.quill.deleteText(blotIndex, 1, 'silent')
      }

      //const index = this.$store.getters['document/getSectionLastFocusedIndex'](this.section.sectionId)

      this.PapirQuill.quill.insertEmbed(this.PapirQuill.lastSelection.index, 'mention', variableDefinition, 'silent');
      this.PapirQuill.quill.insertText(this.PapirQuill.lastSelection.index + 1, " ", 'silent');
      this.PapirQuill.quill.setSelection(this.PapirQuill.lastSelection.index + 1, 0, 'user');
    },
    onVariableRemoved(variable) {
      this.$store.dispatch('document/removeVariable', {
        documentId: this.documentId,
        variableId: variable.id
      }).then(() => {
        console.log(variable)
        //this.removeVariableFromContent(variable.uuid)
        this.save()
      })
    },
    removeVariableFromContent(variableId) {
      console.log('removing', variableId)
      let updatedContent = this.PapirQuill.quill.getContents()
      for (let i = 0; i < updatedContent.ops.length; i++) {
        let op = updatedContent.ops[i]
        if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
          if (Object.prototype.hasOwnProperty.call(op['insert'], 'mention')) {
            if (op['insert'].mention.uuid === variableId) {
              console.log('Deleting ', op['insert'].mention, variableId)
              updatedContent.ops.splice(i, 1)
            }
          }
        }
      }
      this.PapirQuill.quill.setContents(updatedContent, 'api')
    },
    showSaveAsLibraryDataModal(isSection, selectedText) {
      PEventBus.$emit('handle-add-template', {
        jsonContent: this.getJsonContent,
        textContent: selectedText,
        isSection: isSection
      })
    },
    beforeWindowUnload(e) {
      if (this.change.length() > 0) {
        return 'There are unsaved changes. Are you sure you want to leave?'
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    handleVariableMount(event) {
      event.preventDefault();
    },
    focus() {
      this.PapirQuill.quill.enable(!this.isDisabled)
      this.PapirQuill.quill.focus()
      PEventBus.$emit('section-selected', this.section.sectionId)
    },
    onFocus() {
      this.focus()
      this.setSectionId()
      this.openSectionsLeftBarTab()
    },
    openSectionsLeftBarTab() {
      PEventBus.$emit('update-left-tab', 1)
    },
    reloadSection() {
      this.$store.dispatch("section/fetchOne", this.section.sectionId)
          .then((res) => {
            this.save()
          })
    },
    setCursorOnLastFocusedIndex() {
      const index = this.$store.getters['document/getSectionLastFocusedIndex'](this.section.sectionId)
      this.PapirQuill.quill.setSelection(index + 1, 0, 'silent')
      this.onFocus()
    },
    setCursor(event) {
      this.lastFocusedRange.index = event.selection.savedRange.index
      this.lastFocusedRange.length = event.selection.savedRange.length

      this.setFormats()
    },
    onEditorChange(event) {
      setTimeout(() => {
        this.setUnsaved()
        let quillContent = event.quill.getContents()
        let htmlContent = event.html
        let textContent = event.text
        this.change = this.change.compose(event.delta);
        if (this.autoSaveIntervalId !== null) {
          clearInterval(this.autoSaveIntervalId)
          this.autoSaveIntervalId = null
        }

        let range = this.PapirQuill.quill.getSelection()

        if (range == null) {
          range = this.PapirQuill.lastSelection
        }

        this.lastFocusedRange.index = range.index
        this.lastFocusedRange.length = range.length

        this.updateSectionInStore(quillContent, textContent, htmlContent)

        this.setAutoSavingActive()
        this.PapirQuill.quill.setSelection(this.lastFocusedRange.index, this.lastFocusedRange.length, 'user')

        this.autoSaveIntervalId = setInterval(() => {
          console.log('buraya girdim')
          if (this.change.length() > 0) {
            this.save()

            //this.PapirQuill.quill.updateContents(this.change, 'silent')
            this.change = new Delta()
          } else {
            this.setSaved()
          }
          clearInterval(this.autoSaveIntervalId)
        }, 2000)
      }, 10)
    },
    updateSectionInStore(quillContent, textContent, htmlContent) {
      this.$store.dispatch('section/updateSectionInStore', {
        sectionId: this.section.sectionId,
        textContent: textContent,
        htmlContent: {html: htmlContent},
        quillContent: quillContent
      }).then(res => {
      })
    },
    // Save periodically
    saveSilent() {
      let editorContent = this.PapirQuill.quill.getContents()
      let htmlContent = this.PapirQuill.quill.root.innerHTML
      let textContent = this.PapirQuill.quill.getText()
      this.$emit('saveSilent', {
        version: this.section.version,
        id: this.section.sectionId,
        editorContent: editorContent,
        htmlContent: htmlContent,
        textContent: textContent
      })
    },
    save() {
      if (this.$store.state.document.selectedSectionStatus.unsaved) {
        this.$emit('save', {
          version: this.section.version,
          id: this.section.sectionId
        })
      }
      this.setSaved()
    },
    updateSectionVariable(parentVaribleId, childVariableId, childVariableName) {
      const content = this.PapirQuill.quill.getContents()

      for (let i = 0; i < content.ops.length; i++) {
        let op = content.ops[i]
        if (Object.prototype.hasOwnProperty.call(op, 'insert')) {
          let insert = op.insert
          if (Object.prototype.hasOwnProperty.call(insert, 'mention')) {
            let mention = insert.mention

            if (mention.id === parentVaribleId) {
              mention.id = childVariableId
              mention.name = childVariableName
            }
          }
        }
      }
    },
    addComment() {
      let selectionRange = this.PapirQuill.quill.selection.savedRange
      PEventBus.$emit("handleCommentQuote", this.PapirQuill.quill.getText(selectionRange))

      //this.PapirQuill.quill.formatText(selectionRange.index, selectionRange.length, {'color':'red'})
      //this.PapirQuill.quill.removeFormat(selectionRange.index, selectionRange.length)
    },
    createRowAbove() {
      this.focus()
      let tableModule = this.PapirQuill.quill.getModule('table')
      tableModule.insertRowAbove()
    },
    saveAsTemplate() {
      this.showSaveAsLibraryDataModal(true)
    },
    appendAsText(payload) {
      if (this.section.sectionId === payload.sectionId) {
        const selection = this.PapirQuill.quill.getSelection();
        this.PapirQuill.quill.insertText(selection.index, payload.text, '', '', ''); // todo maybe update
      }
    },
  }
}
</script>


<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

$border-radius: .25rem !default;
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.section-selected {
  border: none;
  box-shadow: 0 0 1px 1px rgba(98, 98, 98, 0.04), 0 0 1px 1px rgba(98, 98, 98, 0.04);
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: rgba(98, 98, 98, 0.01);
}

.editable-section-header {
  border: none;
  width: 70%;
  height: auto;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0;
  padding-right: 1px;
  overflow: scroll;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  font-style: italic;
  color: gray;
}
</style>
