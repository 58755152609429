import _Quill from "quill";

const Quill = window.Quill || _Quill
let Inline = Quill.import('blots/inline');

class SpanBlock extends Inline {
    static create(value){
        let node = super.create();
        node.setAttribute('class','spanblock');
        return node;
    }
}

SpanBlock.blotName = 'spanblock';
SpanBlock.tagName = 'div';

Quill.register(SpanBlock);
