
import _Quill from "quill";

const Quill = window.Quill || _Quill
let Embed = Quill.import('blots/embed');

class ShiftEnterBlot extends Embed {} // Actually EmbedBlot
ShiftEnterBlot.blotName = 'ShiftEnter';
ShiftEnterBlot.tagName = 'br';

Quill.register(ShiftEnterBlot);
