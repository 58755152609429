export default class Section {
  constructor(sectionId= null,  title = '', content = '', categoryId = null) {
    this.sectionId = sectionId;
    this.title = title;
    this.content = content;
    this.categoryId = categoryId;
    this.createdAt = null;
    this.lastUpdatedAt = null;
    this.owner = {
      id: null,
      fullName: null
    };
    this.labels = [];
    this.show = true
  }
}