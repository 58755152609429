<template>
  <b-overlay
      :show="loading"
      :variant="'light'"
      class="h-100"
      :opacity="0.65"
      rounded="sm">
    <vue-perfect-scrollbar
        class="sidebar-menu-list scroll-area p-1 mb-1 h-100">
      <div class="py-1">
        <div class="p-1 justify-content-center align-content-center mb-1"
             v-if="!loading && Object.entries(reminders).length === 0">
          <empty-placeholder></empty-placeholder>
        </div>
        <div v-else class="p-1 w-100 justify-content-center">
          <b-row>
            <div class="badge text-sm-center badge-light-warning badge-pill text-wrap">
              {{$t('variable.reminderStartDescription')}}
            </div>
          </b-row>

          <b-row v-for="(reminder,index) in reminders"
                 v-bind:key="index"
                 class="p-1 w-100">
            <div class="w-100">
              <b-row class="pl-1 pr-1">
                <b-col cols="10">
                  <b-row class="pb-1">
                    <b-badge pill
                             :variant="`light-${reminder.status === 'ACTIVE' ? 'info' : 'warning'}`"
                             class="text-capitalize">
                      {{ (reminder.status === 'ACTIVE' ? $t('variable.active') : $t('variable.passive')) }}
                    </b-badge>
                  </b-row>
                  <b-row class="pt-1">
                    {{ prepareText(reminder) }}
                  </b-row>
                </b-col>

                <b-col cols="2">
                  <b-dropdown
                      right
                      variant="link"
                      toggle-class="p-0">
                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="20"
                          class="align-middle text-primary"
                      />
                    </template>
                    <b-dropdown-item v-if="reminder.status === 'INACTIVE'"
                                     @click="start(reminder.reminderId)">
                      <span class="align-middle ml-50">{{$t('variable.activate')}}</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="reminder.status === 'ACTIVE'"
                                     @click="pause(reminder.reminderId)">
                      <span class="align-middle ml-50">{{$t('variable.deactivate')}}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteReminder(reminder.reminderId)">
                      <span class="align-middle ml-50">{{$t('buttons.delete')}}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1 pt-1">
                <span class="text-muted">
                  {{ $t('variable.linkedUsers') }}
                </span>
                <b-avatar-group
                    size="32px"
                    class="mt-50 w-100">

                  <b-avatar v-for="(user,index) in reminder.usersToRemind" :key="index"
                            v-b-tooltip.hover
                            class="pull-up"
                            variant="primary"
                            :title="user.groupName + $t('variable.groupSuffix') + `\n` + user.userName + `\n`+ user.userEmail"
                            :text="setInitials(user.userName)"/>
                </b-avatar-group>
              </b-row>
            </div>
          </b-row>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </b-overlay>
</template>

<script>
import {mapState} from "vuex";
import {BAvatar, BAvatarGroup, BBadge, BCol, BDropdown, BDropdownItem, BOverlay, BRow} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import StringUtil from "@/services/StringUtil";
import EmptyPlaceholder from "@/views/Documents/Editor/Reminder/EmptyPlaceholder";
import i18n from "@/libs/i18n";

export default {
  name: "List",
  components: {
    BRow,
    BCol,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BBadge,
    VuePerfectScrollbar,
    BAvatarGroup,
    BAvatar,
    EmptyPlaceholder
  },
  computed: {
    ...mapState({
      reminders: state => state.document.reminders,
      documentStatus: state => state.document.document.lifecycleDetail.status.name
    })
  },
  created() {
    this.fetchDocumentReminders()
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    fetchDocumentReminders() {
      this.$store.dispatch('document/getDocumentReminders', this.documentIdFromUrl());
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    mapExecutionPlan(executionPlan) {
      return executionPlan === 'EVERY_HOUR' ? i18n.t('variable.reminderOptions.everyHour')
          : executionPlan === 'EVERY_DAY' ? i18n.t('variable.reminderOptions.everyDay')
              : executionPlan === 'EVERY_3_DAY' ? i18n.t('variable.reminderOptions.every3Day')
                  : executionPlan === 'EVERY_WEEK' ? i18n.t('variable.reminderOptions.everyWeek')
                      : executionPlan === 'EVERY_2_WEEK' ? i18n.t('variable.reminderOptions.every2Week')
                          : i18n.t('variable.reminderOptions.periodically')
    },
    prepareText(reminder) {
      return i18n.tc('variable.reminderExecutionText', reminder.title, {
        title: reminder.title,
        startTime: reminder.startTime.split('T')[0],
        endTime: reminder.endTime.split('T')[0],
        executionPlan: this.mapExecutionPlan(reminder.executionPlan),
      })
    },
    start(reminderId) {
      this.$store.dispatch("reminder/start", reminderId)
          .then(() => this.successNotifier(this.$i18n.t('messages.reminder.startedSuccessfully')))
          .then(() => this.fetchDocumentReminders())
          .catch(() => this.errorNotifier(this.$i18n.t('messages.reminder.errorOccurredOnStart')))
    },
    pause(reminderId) {
      this.$store.dispatch("reminder/pause", reminderId)
          .then(() => this.successNotifier(this.$i18n.t('messages.reminder.stoppedSuccessfully')))
          .then(() => this.fetchDocumentReminders())
          .catch(() => this.errorNotifier(this.$i18n.t('messages.reminder.errorOccurredOnStop')))
    },
    deleteReminder(reminderId) {
      this.$store.dispatch("reminder/delete", reminderId)
          .then(() => this.successNotifier(this.$i18n.t('messages.reminder.deletedSuccessfully')))
          .then(() => this.fetchDocumentReminders())
          .catch(() => this.errorNotifier(this.$i18n.t('messages.reminder.errorOccurredOnDelete')))
          .then(() => this.fetchDocumentReminders())
    },
    errorNotifier(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          variant: 'danger',
          icon: "AlertIcon"
        },
      }, {position: 'top-center'})
    },
    successNotifier(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          variant: 'info"',
          icon: "CheckIcon"
        },
      }, {position: 'top-center'})
    },
    setInitials(text) {
      return StringUtil.setInitials(text)
    }
  }
}
</script>

<style scoped>

</style>