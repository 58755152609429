<template>
  <li
      class="timeline-item"
      :class="[`timeline-variant-${variant}`, fillBorder ? `timeline-item-fill-border-${variant}` : null]">
    <div
        v-if="!icon"
        class="timeline-item-point"/>
    <div
        v-else
        class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle">
      <feather-icon :icon="icon"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="subtitle.text"/>
    </div>
    <slot>
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6 v-text="title"/>
        <div v-if="document.lifecycleDetail.status.name === 'DRAFT' && requesterAccessLevel === 'CREATOR'">
          <b-dropdown
              variant="link" right>
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="20"
                  class="align-middle text-body"
              />
            </template>

            <div>
              <b-dropdown-item v-if="accessLevel !== 'READ'" @click="setAsReadOnly">
                <span class="align-middle ml-50">{{ $t('buttons.setAsReadOnly') }}</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="accessLevel !== 'WRITE'" @click="setAsEditor">
                <span class="align-middle ml-50">{{ $t('buttons.setAsEditor') }}</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="accessLevel !== 'NO_ACCESS'" @click="setAsHidden">
                <span class="align-middle ml-50">{{ $t('buttons.setAsHidden') }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>
      </div>


      <b-badge variant="light-dark" size="sm">
        <span>{{ badgeText }}</span>
      </b-badge>

      <b-badge class="ml-1" variant="light-success" size="sm">
        <span>{{ getSectionRole(accessLevel) }}</span>
      </b-badge>

      <b-badge v-if="isSigner" class="ml-1" variant="light-info" size="sm">
        <span class="text-bold">{{ $t('organization.signAuthority')}}</span>
      </b-badge>

      <br/>

      <b-button v-if="requesterAccessLevel === 'CREATOR' && subtitle.value === 'REJECTED'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="redemandForApprove"
                class="mx-50 py-25 mt-1"
                variant="outline-primary"
                size="sm">
        {{ $t('section.redemandForApprove') }}
      </b-button>

      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-25 mb-sm-0">
        <small
            class="timeline-item-time text-nowrap text-muted"
            v-text="time"/>
      </div>
    </slot>
  </li>
</template>
<script>
import {BBadge, BDropdown, BDropdownItem, BButton, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {PEventBus} from "@/services/PEventBus";
import i18n from "@/libs/i18n";
import {mapState} from "vuex";

export default {
  components: {BDropdown, BDropdownItem, BBadge, BButton},
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    badgeText: {
      type: String,
      default: null
    },
    subtitle: {
      type: Object,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    isOwner: Boolean,
    icon: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },
    accessLevel: {
      type: String,
      default: null
    },
    userId: {
      type: String,
      default: null
    },
    sectionId: {
      type: String,
      default: null
    },
    isSigner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      requesterAccessLevel: state => state.section.requesterAccessLevel,
      document: (state) => state.document.document,
    })
  },
  methods: {
    getSectionRole(role) {
      switch (role) {
        case 'READ':
          return i18n.t('statics.readOnly')
        case 'WRITE':
          return i18n.t('statics.editor')
        case 'NO_ACCESS':
          return i18n.t('statics.hidden')
        case 'CREATOR':
          return i18n.t('statics.creator')
        default:
          // do nothing for detect new state
      }
    },
    setSectionId(sectionId) {
      this.sectionId = sectionId
    },
    setAsReadOnly() {
      this.updateUserSectionRole('READ')
    },
    setAsEditor() {
      this.updateUserSectionRole('WRITE')
    },
    setAsHidden() {
      this.updateUserSectionRole('NO_ACCESS')
    },
    updateUserSectionRole(accessLevel) {
      const payload = {
        sectionId: this.sectionId,
        request: {
          userId: this.userId,
          accessLevel: accessLevel
        }
      }

      this.$store.dispatch('section/updateApproverRole', payload)
          .then(result => PEventBus.$emit('section-selected', this.sectionId))
    },
    redemandForApprove() {
      const payload = {
        documentId: this.document.documentId,
        sectionId: this.sectionId,
        approverId: this.userId
      }

      this.$store.dispatch('section/redemandForApprove', payload)
          .then(res => this.$store.dispatch('section/fetchAllApprovers', this.sectionId)
              .then(() => {
                this.approversLoading = false
              }))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
// Bootstrap includes
@import '~@core/scss/base/components/include';
// Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;

          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }

        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;

  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }

    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

</style>
