<template>
  <div>
    <h6 class="section-label mb-1">
      {{ $t('document.tags') }}
    </h6>
    <labels :document-id="documentId"
            @pushToLabels="addLabel($event)"
            @removeLabelFromDocument="removeLabel($event)">
    </labels>
  </div>
</template>

<script>
import Labels from "@/views/components/Select/LabelSuggest";

export default {
  name: "index",
  components: {
    Labels
  },
  props: {
    documentId: String,
    documentType: Object,
  },
  methods: {
    addLabel(label) {
      this.$store.dispatch('document/addToLabels', label);
    },
    removeLabel(label) {
      this.$store.dispatch('document/removeFromLabels', label);
    },
  }
}
</script>

<style scoped>

</style>