v
<template>
  <div class="editor-dox">
    <loading v-if="loading"></loading>
    <div v-else>
      <div v-if="!versionsViewEnabled">
        <EditorHeader :title="document.title"
                      @refetchDocument="loadDocument"
                      :lifecycle-detail="document.lifecycleDetail"
                      @switchVersionsView="versionsViewEnabled=true"
                      :approve-ratio="document.approveRatio"/>
        <EditorCenter :documentId="documentIdFromUrl"
                      :workspace-id="document.idWorkspace"
                      @reload-document="loadDocument"/>
        <CreateTemplateModal :documentId="documentIdFromUrl" :document-type="document.documentType.name"/>
        <save-as-template-modal/>
        <populate-variables-modal/>
      </div>
      <div v-else>
        <versions-view v-if="versionsViewEnabled"
                       :workspace-id="document.idWorkspace"
                       :document-id="documentIdFromUrl"
                       @switchEditorView="versionsViewEnabled=false"></versions-view>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import CreateTemplateModal from '@/views/Templates/Create/index'
import SaveAsTemplateModal from "@/views/Documents/Editor/LibraryData/CreateLibraryDataModal";
import {computed, onUnmounted, ref, watch,} from '@vue/composition-api'
import {VBTooltip,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {avatarText, formatDate} from '@core/utils/filter'
import {useRouter} from '@core/utils/utils'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import todoStoreModule from './todoStoreModule'
import EditorCenter from "@/views/Documents/Editor/EditorCenter";
import {mapState, mapGetters} from "vuex";
import EditorHeader from '@/views/Documents/Editor/Header/EditorHeader.vue'
import Loading from "@/views/components/Loading/Loading";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useWorkspacesList from "@/views/Workspace/List/useWorkspacesList";
import PopulateVariablesModal from "@/views/Documents/Create/PopulateVariablesModal";
import i18n from "@/libs/i18n";
import {PEventBus} from "@/services/PEventBus";
import VersionsView from "@/views/Documents/Editor/Sections/Versions/VersionsView";
import ManagingSingleVariableModal from "@/views/Documents/Editor/UpdateVariableModal";

export default {
  name: "Editor",
  components: {
    Loading,
    CreateTemplateModal,
    SaveAsTemplateModal,
    EditorHeader,
    EditorCenter,
    PopulateVariablesModal,
    VersionsView,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      versionsViewEnabled: false,
    }
  },
  mounted() {
    this.fetchOrganizations()
    this.loadGroups()
    PEventBus.$on(`reload-document`, this.loadDocument)
    window.addEventListener ("touchmove", function (event) { event.preventDefault (); }, {passive: false});

    const urlChange = () => {
      // this control fixes SPA based page redirection issue with a bad way. should find more effective way.
      if (this.$store.getters["document/getDocumentId"] != null && this.$store.getters["document/getDocumentId"] !== this.$route.params.id) {
        this.$router.push({name: 'my-documents'})
      }
    };

    window.addEventListener('popstate', urlChange);

    // Useful for cleanup when component is destroyed
    this.cleanup = urlChange;
  },
  beforeDestroy() {
    PEventBus.$off(`reload-document`, this.loadDocument)
    window.removeEventListener('popstate', this.cleanup);
  },
  created: async function () {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    // this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', {type: 'hidden'})
    // this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', {type: 'hidden'})
    await this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'full')

    if (this.$route.params.id === 'new') {
      this.$store.dispatch("document/initiate", {
        workspaceId: this.$route.query.ws,
        documentTitle: this.$route.query.title,
        documentType: this.$route.query.type,
      })
          .then(res => {
            if (res.code === 9010) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t('messages.document.requiresGroupRelation'),
                  icon: 'AlertTriangeIcon',
                  variant: 'danger'
                },
              }, {position: 'top-center'})

              this.$router.push({name: 'organizations'})
            } else if (res.uid !== undefined) {
              this.$router.replace({params: {id: res.uid}})
              this.loadDocument()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t('messages.document.loadingError'),
                  icon: 'AlertTriangeIcon',
                  variant: 'danger'
                },
              }, {position: 'top-center'})
            }
          })
    } else {
      await this.loadDocument()
    }
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const {route, router} = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)

    const tasks = ref([])

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({name: route.name, query: currentRouteQuery}).catch(() => {
      })
    }

    const blankTask = {
      id: null,
      title: '',
      description: '',
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const taskTags = [
      {title: 'Team', color: 'primary', route: {name: 'apps-todo-tag', params: {tag: 'team'}}},
      {title: 'Low', color: 'success', route: {name: 'apps-todo-tag', params: {tag: 'low'}}},
      {title: 'Medium', color: 'warning', route: {name: 'apps-todo-tag', params: {tag: 'medium'}}},
      {title: 'High', color: 'danger', route: {name: 'apps-todo-tag', params: {tag: 'high'}}},
      {title: 'Update', color: 'info', route: {name: 'apps-todo-tag', params: {tag: 'update'}}},
    ]

    const resolveTagVariant = tag => {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'update') return 'info'
      return 'primary'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({name: route.name, query: currentRouteQuery})
    }

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()

    return {
      task,
      tasks,
      clearTaskData,
      taskTags,
      searchQuery,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
  computed: {
    ...mapState({
      document: state => state.document.document
    }),
    documentIdFromUrl() {
      return this.$route.params.id;
    }
  },
  methods: {
    fetchOrganizations() {
      useWorkspacesList().fetchMyWorkspaces()
    },
    saveSection({content, index, id}) {
      if (id == null) {
        // create new section
        const payload = {
          documentId: this.documentIdFromUrl,
          header: "Section_" + index,
          content: content
        }
        this.$store.dispatch("section/create", payload)
            .then((res) => {
              this.loadSectionList()
            })
      } else {
        this.editingSectionId = id
        // edit section content
        const payload = {
          content: content,
          sectionId: id
        }
        this.$store.dispatch("section/edit", payload)
            .then((res) => {
              setTimeout(() => {
                this.editingSectionId = -1
              }, 500)
              this.loadSection(id)
            })
            .catch(err => {
              this.editingSectionId = -1
            })
      }
    },
    loadGroups() {
      this.$store.dispatch('group/fetchAll')
          .then((res) => {
            this.groups = res["groups"]
          })
    },
    loadDocument() {
      this.$store.dispatch("document/fetchDocument", this.documentIdFromUrl)
          .then(res => {
            if (res.data.code === 4018) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t('messages.document.noAccessError'),
                  icon: 'AlertTriangeIcon',
                  variant: 'danger'
                },
              })

              this.$router.push({name: 'my-documents'})
            }

            this.reloadSections()
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
          })
    },
    reloadSections() {
      this.$store.dispatch("section/fetchAll", {documentId: this.documentIdFromUrl})
    },
  }
}
</script>

<!--<style lang="scss" scoped>-->
<!--    .scroll-area {-->
<!--        z-index: 0;-->
<!--        position: relative;-->

<!--    }-->

<!--    .draggable-task-handle {-->
<!--        position: absolute;-->
<!--        left: 8px;-->
<!--        top: 50%;-->
<!--        transform: translateY(-50%);-->
<!--        visibility: hidden;-->
<!--        cursor: move;-->

<!--        .todo-task-list .todo-item:hover & {-->
<!--            visibility: visible;-->
<!--        }-->
<!--    }-->
<!--</style>-->
<style lang="scss">
@import "~@core/scss/base/pages/page-editor.scss";
</style>
