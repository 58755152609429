<template>
  <div class="row align-content-center w-100 d-flex ml-2 py-25 my-0 ">
    <div class="col-xs-1 my-auto ">
      <feather-icon size="20" :icon="icon"/>
    </div>
    <div class="col-xs-11 py-0">
      <div class="p-50">{{ title }}</div>
    </div>
    <div class="col-xs-1   py-0 ">
      <div class="p-50" v-if="badgeCount > 0">
        <b-badge style="background-color: rgba(40, 199, 111, 0.15); color: #4B4B61">{{ badgeCount }}</b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import {BBadge} from 'bootstrap-vue'
export default {
  name: "TabItem",
  components: {BBadge},
  props: {
    title: {
      type: String,
      default: 'Tab'
    },
    icon: String,
    badgeCount: Number,
  }
}
</script>

<style scoped>

</style>
