<template>
  <div>
    <b-modal
        :id='`modalManageVariableId`'
        ref="modalManageVariableRef"
        no-close-on-backdrop
        hide-footer
        :title="$t('variable.edit')">
      <validation-observer ref="supplierForm"
                           #default="{invalid}">
        <b-row align-h="start" class="w-100 m-0">
          <b-col>

            <b-form-group
                :label="variable.typeExplanation"
                label-for="override-desc">
              <validation-provider
                  name="documentVariableDescription"
                  vid="documentVariableDescription"
                  rules="required">
                <b-form-input
                    :disabled="disabled"
                    id="override-desc"
                    :placeholder="variable.description"
                    v-model="overridingDescription"
                    class="w-full">
                </b-form-input>
                <small>{{$t('variable.youCanGiveCustomName')}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="start" class="w-100 m-0">
          <b-col>
            <dynamic-variable-element
                :disabled="disabled"
                :workspace-id="workspaceId"
                :variable="variable"
                @onTextInputChange="handleTextInputChange"
                @onDateInputChange="handleDateInputChange"
                @onCompanySelectionChange="handleCompanySelectionChange"
                @onSelectionInputChange="handleSelectableInputChange"
                @onLinkedVariableInputChange="handleLinkedVariableInputChange"
                @onUpdate="$emit('update', $event)"/>
          </b-col>
        </b-row>
        <b-row align-h="start" class="mt-1 w-100 m-0">
          <b-col class="justify-content-end align-content-end d-flex w-100">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      class="mr-1"
                      @click.stop="close"
                      variant="outline-danger">
              <div class="icon-wrapper">
                <span>{{ $t('exit') }}</span>
              </div>
            </b-button>
            <b-button
                @click="save"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="elevated-btn"
                variant="primary"
                v-show="!disabled"
                :disabled="invalid || submitting || disabled">
              <b-spinner small v-if="submitting"/>
              <span v-else>{{ $t('save') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {BButton, BCol, BFormGroup, BFormInput, BModal, BRow, BSpinner} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {PEventBus} from "@/services/PEventBus";
import DynamicVariableElement from "@/views/Documents/Editor/Variables/DynamicVariableElement";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapState} from "vuex";
import {uuid} from "vue-uuid/index";

export default {
  components: {
    BButton,
    BRow, BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
    BModal,
    ValidationProvider,
    ValidationObserver,
    DynamicVariableElement
  },
  props: {
    documentId: String,
    workspaceId: String,
    sectionId: String,
    disabled: Boolean,
  },
  watch: {
    variableClickedData(newVal, oldVal) {
      if (newVal.clicked) {
        this.onVariableClicked(newVal.data)
      }
    },
  },
  directives: {
    Ripple,
  },
  name: "ManagingSingleVariableModal",
  data() {
    return {
      modalEnabled: false,
      currentBlot: null,
      forceUpdateRender: 0,
      submitting: false,
      variableDefinition: {},
      variable: {},
      insertFunction: null,
      value: null,
      overridingDescription: null,
      hasChild: false,
      parentVariableValue: {},
      visibility: false
    }
  },
  created() {
    PEventBus.$on(`${this.sectionId}-open-variable-detail-added`, this.onVariableDefinitionSelected);
    PEventBus.$on(`${this.sectionId}-open-variable-detail-clicked`, this.onVariableClicked);
  },
  beforeDestroy() {
    PEventBus.$off(`${this.sectionId}-open-variable-detail-added`, this.onVariableDefinitionSelected);
    PEventBus.$off(`${this.sectionId}-open-variable-detail-clicked`, this.onVariableClicked);
  },
  computed: {
    ...mapState({
      variableClickedData: state => state.document.variableClickData
    }),
  },
  methods: {
    getVariableFromStore(id) {
      return this.$store.getters["variables/getVariableById"](id)
    },
    save() {
      this.submitting = true
      this.$store.dispatch('document/setVariableValue', {
        documentId: this.documentId,
        variableId: this.variable.variableId,
        value: this.value,
        overridingDescription: this.overridingDescription
      }).then(() => {
        if (this.hasChild) {
          this.$store.dispatch('document/setVariableValue', {
            documentId: this.documentId,
            variableId: this.variable.parentVariableId,
            value: this.parentVariableValue
          })
          this.$emit('updateSectionVariable', this.variable.parentVariableId, this.variable.variableId, this.variable.name)
        }

        this.variableDefinition['userInput'] = this.value.userValue
        this.variableDefinition['displayText'] = this.value.userValue
        this.variableDefinition['color'] = this.variable.color
        this.variableDefinition['uuid'] = uuid.v4()

        if (this.currentBlot === null && this.sectionId !== null) {
          PEventBus.$emit(`${this.sectionId}-embed-variable`, this.currentBlot, this.variableDefinition)
          PEventBus.$emit(`${this.sectionId}-save`)
        }

        // if there is another variables exists with old value
        PEventBus.$emit(`${this.variableDefinition.id}-variable-updated`, this.value.userValue)
        this.close()
        this.submitting = false
      })
          .then(() => this.fetchDocumentBadges())
          .catch((error) => {
        this.submitting = false
      })
    },

    async onVariableClicked(blot, data, insertItemToEditor) {
      console.log(blot)
      this.currentBlot = blot
      this.variableDefinition = data
      // todo this is workaround data should be used properly
      const response = await this.getVariableOnDocument(data.id)
      this.variable = response
      this.value = response.userProcessedValue
      this.overridingDescription = response.description
      this.insertFunction = insertItemToEditor

      this.$refs.modalManageVariableRef.show()
    },
    fetchDocumentBadges() {
      this.$store.dispatch('document/fetchDocumentStatistics', this.documentId)
    },

    async onVariableDefinitionSelected(variableDefinitionItem, sectionId, color, insertItemToEditor) {
      this.sectionId = sectionId
      this.variableDefinition = variableDefinitionItem
      this.overridingDescription = variableDefinitionItem.value
      this.insertFunction = insertItemToEditor

      variableDefinitionItem['sectionId'] = sectionId

      // first check if this variable used in any section before
      const alreadyDefinedVariable = await this.getVariableOnDocument(variableDefinitionItem.id)
      if (alreadyDefinedVariable !== undefined && alreadyDefinedVariable !== '') {
        this.copyVariable(alreadyDefinedVariable)
      } else {
        this.variable = {}
        const variableDefinition = this.getVariableFromStore(variableDefinitionItem.id);

        this.variable['variableId'] = variableDefinition.id
        this.variable['description'] = variableDefinition.description
        this.variable['overridingDescription'] = variableDefinition.description
        this.variable['type'] = variableDefinition.type
        this.variable['valueType'] = variableDefinition.valueType
        this.variable['typeExplanation'] = this.variableDefinition.value
        this.variable['options'] = variableDefinition.options
        this.variable['color'] = color
        this.variable['children'] = variableDefinition.children
        this.$refs['modalManageVariableRef'].show();
      }
    },
    copyVariable(alreadyDefinedVariable) {
      console.log(alreadyDefinedVariable)
      this.variable = alreadyDefinedVariable
      this.value = alreadyDefinedVariable.userProcessedValue
      this.variableDefinition['id'] = alreadyDefinedVariable.variableId
      this.variableDefinition['userInput'] = this.value.userValue
      this.variableDefinition['displayText'] = this.value.userValue
      this.variableDefinition['color'] = alreadyDefinedVariable.colorType
      this.variableDefinition['uuid'] = uuid.v4()
      if (this.currentBlot === null && this.sectionId !== null) {
        PEventBus.$emit(`${this.sectionId}-embed-variable`, this.currentBlot, this.variableDefinition)
        PEventBus.$emit(`${this.sectionId}-save`)
      }
    },
    async getVariableOnDocument(variableId) {
      return await this.$store.dispatch('document/getVariable', {
        documentId: this.documentId,
        variableId: variableId
      })
    },
    async listVariablesUsedInDocument(variableDefinitionId) {
      return await this.$store.dispatch('document/getVariableUsedInDocument', {
        documentId: this.documentId,
        variableId: variableDefinitionId
      })
    },
    close() {
      this.clear()
      //this.$bvModal.hide('modalManageVariableId')

      this.$refs['modalManageVariableRef'].hide();
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    },
    handleTextInputChange(event) {
      this.value = {userValue: event.inputValue, mappedValue: event.inputValue}
    },
    handleDateInputChange(event) {
      this.value = {userValue: event.inputValue, mappedValue: event.inputValue}
    },
    handleSelectableInputChange(event) {
      this.value = {userValue: event.selectedValue.option, mappedValue: event.selectedValue.option}
    },
    handleLinkedVariableInputChange(event) {
      this.hasChild = true

      this.parentVariableValue = {userValue: event.parentVariableValue, mappedValue: event.parentVariableValue}

      this.variable['parentVariableId'] = this.variable['variableId']
      this.variable['variableId'] = event.varId
      this.value = {userValue: event.selectedValue, mappedValue: event.selectedValue}
    },
    handleCompanySelectionChange(event) {
      this.value = {userValue: event.selectedValues, mappedValue: event.selectedValues}
    },
    clear() {
      //PEventBus.$emit(`${this.sectionId}-set-cursor`)
      this.value = null
      this.currentBlot = null

    }
  }
}
</script>

<style scoped>

</style>
