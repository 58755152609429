<template>
  <div>
    <div
        class="row  justify-content-between m-0 align-items-center align-content-center py-1 px-50  mb-25  named-versions-check">
      <div class="col d-flex align-items-center align-content-center ">
        <span class="font-small-3">{{ $t('document.showOnlyNamedVersions') }}</span>
      </div>
      <div class="ml-auto align-items-center align-content-center" style="width: 40px;">
        <b-form-checkbox></b-form-checkbox>
      </div>
    </div>
    <b-overlay
        :show="loading"
        :variant="'light'"
        :opacity="0.65"
        class="h-100"
        rounded="sm">
      <app-collapse
          accordion
          :type="'border'">
        <div v-for="(section) in sections" v-bind:key="section.sectionId" class="p-0 m-0">
          <app-collapse-item class="text-dark font-medium-2 font-weight-bold text-nowrap p-0"
                             @loadVersions="loadSectionVersions"
                             :section-id="section.sectionId"
                             :title="`${section.sequence + 1}. ${section.header}`">
            <b-list-group-item
                v-for="(version) in versions" :key="version.id"
                style="width: 100%; border-radius: 0;"
                class=" cursor-pointer px-50 py-50 mb-0"
                :class="[selectedVersion !== null && selectedVersion.id === version.id ? 'selected' : '']"
                @click="selectVersion(version, section.sectionId)">
              <div class="row d-flex m-0 justify-content-between align-items-center align-content-center pl-1">
                <div class="col m-0 p-0 w-100">
                  <div class="row w-100  m-0 d-flex justify-content-start">
                    <span
                        class="col text-primary-100 font-medium-2 font-weight-bold text-nowrap text-truncate truncate p-0"
                        style="display: block;">
                        {{ version.header }}
                    </span>
                  </div>
                  <div class="row w-100  m-0 justify-content-start">
                    <div class="cols-12 justify-content-start">
                      <div>
                        <div class="justify-content-start" style="padding: 0">
                  <span :contenteditable="false"
                        :class="{'version-header-editable' : true, 'selected': selectedVersion !== null && selectedVersion.id === version.id}"
                        style="padding: 2px 5px 2px 5px;"
                        @keydown="handleEditableKeyPress"
                        @blur="updateTitle"
                        v-on:keyup.enter="$event.target.blur()"
                        v-on:keydown="onKeydown"
                        v-text="getFormattedCreatedDate(version.editedAt)">
                  </span>
                        </div>
                        <div v-if="currentVersion === version.version" class="justify-content-start"
                             style="padding: 2px 5px 2px 5px;">
                          <small class="font-italic font-weight-bold text-primary">Mevcut sürüm</small>
                        </div>
                        <div class="justify-content-start" style="padding: 2px 5px 2px 5px;">
                          <small>{{ version.userName }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-auto pr-25">
                  <b-nav-item-dropdown
                      no-caret
                      right
                      class="actions"
                      style="list-style: none; z-index: 21;"
                      toggle-class="d-flex align-items-center">
                    <template #button-content>
                      <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                class="rounded-circle "
                                style="padding: 2px;z-index: 1"
                                variant="flat-secondary">
                        <div class="icon-wrapper">
                          <feather-icon icon="MoreVerticalIcon" size="16"/>
                        </div>
                      </b-button>
                    </template>
                    <b-dropdown-item>
                      <span class="font-weight-bold align-middle ml-50">{{ $t('buttons.createTitle') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <span class="font-weight-bold align-middle ml-50">{{ $t('buttons.revert') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <span class="font-weight-bold   align-middle ml-50">{{ $t('buttons.duplicate') }}</span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </div>
              </div>
            </b-list-group-item>
          </app-collapse-item>
        </div>
      </app-collapse>

      <div v-if="versions.length >= 50" class="mt-2 justify-content-center align-items-center d-flex">
        <span class="text-warning">{{ $t('section.listingLast50Update') }}</span>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {BListGroupItem, BNavItemDropdown, BDropdownItem, BOverlay, BButton, BFormCheckbox} from 'bootstrap-vue'
import {mapState} from "vuex";
import Ripple from "vue-ripple-directive";
import DateUtil from '@/services/DateUtil';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {PEventBus} from "@/services/PEventBus";

export default {
  name: "List",
  components: {
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BNavItemDropdown,
    BListGroupItem,
    BDropdownItem,
    BButton,
    BFormCheckbox
  },
  props: {
    documentId: String,
    currentVersion: Number
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState({
      loading: state => state.section.sectionVersionsLoading,
      versions: state => state.section.versionList,
      selectedVersion: state => state.section.selectedVersion,
      secondSelectedVersion: state => state.section.secondSelectedVersion
    }),
    documentIdFromUrl() {
      return this.$route.params.id;
    },
    sections: {
      get() {
        return this.$store.getters["document/getSections"];
      },
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    loadSectionVersions(sectionId) {
      PEventBus.$emit(`${sectionId}-select-section`)
      this.fetchVersions(sectionId)
    },
    fetchVersions(sectionId) {
      this.$store.dispatch('section/fetchSectionVersions', sectionId)
    },
    handleEditableKeyPress(e) {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    },
    updateTitle(event) {
      if (event.target.textContent !== this.selectedVersion.title) {
        this.$store.dispatch('document/update', {documentId: this.documentId, title: event.target.textContent})
            .then(() => {
              // do something
            })
      }
    },
    onKeydown(event) {
      console.log(event)
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    },
    selectVersion(version, sectionId) {
      this.$store.commit('section/SET_SELECTED_VERSION', version)
      PEventBus.$emit(`${sectionId}-find-diff`, version.id)
    },
    selectSecondVersionToCompare(version) {
      this.$store.commit('section/SET_SECOND_SELECTED_VERSION', version)
    },
    getFormattedCreatedDate(date) {
      return DateUtil.getFormattedTime(date)
    },
    getCreatedByInfo(name) {
      return name + ' tarafından oluşturuldu.'
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      perfectScrollbarSettings,
    }
  }
}
</script>

<style lang="scss" scoped>
[dir] .list-group-item {

  &:hover {
    background-color: rgba(9, 30, 66, 0.04);

    .draggable-item {
      visibility: visible;
    }

    .actions {
      visibility: visible;
    }
  }
}

.card {
  border-radius: 0;
}

.actions {
  visibility: hidden;
}

.selected {
  font-weight: 800;
  background-color: rgba(9, 30, 66, 0.08);
}

.named-versions-check {
  position: fixed;
  right: 0;
  top: 79px;
  background-color: white;
  width: 340px;
  z-index: 99999 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.version-header-editable {

  white-space: nowrap;
  overflow: hidden;
  border: none !important;
  font-size: 1.186rem;
  font-weight: 700;
  min-height: 30px;
  line-height: 1.2;
  color: #636363;
  background-color: transparent;

  &:focus {
    background-color: rgba(9, 30, 66, 0.04);
    box-shadow: none;
  }

  &.selected {
    font-weight: 800;
  }

  br {
    display: inline;
  }
}


</style>
