<template>
  <b-dropdown ref="globalSettings"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              no-caret
              class="mr-25"
              no-flip
              left
              variant="flat-dark"
              @hide="onDropdownHide"
              @show="onShown">
    <template #button-content>
      <v-icon name="sliders-h"></v-icon>
    </template>

    <b-dropdown-form style="z-index: 9999999 !important; width: 280px;">
      <div class="w-100 flex ">

        <div class="mb-2">
          <div class="d-flex w-100 m-0 p-0 row justify-content-between">
            <h6 class="section-label">
              {{ $t('document.toolbar.settings') }}
            </h6>
            <feather-icon size="18"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="hide"/>
          </div>

          <small class="text-muted">
            {{ $t('document.toolbar.scaleSettingsDescription') }}
          </small>
        </div>

        <!--        <v-select v-model="selectedFontFamily"-->
        <!--                  :options="fontStyleArr"-->
        <!--                  class="w-100 mb-1"-->
        <!--                  :style="`font-family: ${selectedFontFamily};`"-->
        <!--                  label="description"-->
        <!--                  placeholder="Font seç"-->
        <!--                  :multiple="false">-->
        <!--          <template v-slot:option="option">-->
        <!--            <span :style="`font-family: ${option.description}; font-size:18px;`">{{ option.description }}</span>-->
        <!--          </template>-->
        <!--        </v-select>-->

        <!--        <b-form-spinbutton-->
        <!--            id="demo-sb"-->
        <!--            v-model="selectedFontSize"-->
        <!--            :formatter-fn="valueFormatter"-->
        <!--            min="1"-->
        <!--            max="48"-->
        <!--        />-->
        <v-select
            v-model="selectedScale"
            :options="zoomOptions"
            class="w-100 mb-1"
            label="description"
            :placeholder="$t('document.toolbar.chooseFont')"
            :multiple="false"
        >
          <template v-slot:option="option">
            <span :style="`font-family: ${option.description}; font-size:18px;`">{{ option.description }}</span>
          </template>
        </v-select>

        <div class=" w-100 mt-1 row">
          <div style="width: 30px" class="col-auto">
            <b-form-checkbox v-model="rememberChanges"></b-form-checkbox>
          </div>
          <div class="col ml-auto w-100">
            <small>{{ $t('document.toolbar.checkboxDescription') }}</small>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 justify-content-center mt-1" v-if="false">
        <b-button v-b-tooltip.hover.top="$t('document.toolbar.apply')"
                  pill
                  style="background-color: rgba(40, 199, 111, 0.15)"
                  variant="flat-success"
                  class="rounded-sm circle-btn px-2 p-0"
                  @click="apply">
          <div class="positioned-lock-icon-wrapper">
            <feather-icon icon="CheckIcon"/>
          </div>
        </b-button>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import vSelect from "vue-select";
import {BButton, BDropdown, BDropdownForm, BFormCheckbox, BFormSpinbutton, VBPopover, VBTooltip} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {Constants, GlobalEditorSettings} from "@/services/Utils";

export default {
  name: "GlobalEditorSettings",
  components: {
    vSelect,
    BDropdown,
    BButton,
    BDropdownForm,
    BFormCheckbox
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  data() {
    return {
      fontStyleArr: ['Arial', 'inconsolata', 'Roboto', 'monospace', 'Sofia', 'slabo', 'Mirza', 'Times New Roman', 'serif', 'Quicksand', 'Nunito Sans', 'Noto'],
      zoomOptions: ['100%', '125%'],
      selectedFontFamily: null,
      selectedFontSize: null,
      rememberChanges: true,
      closable: false,
      selectedScale: '100%'
    }
  },
  watch: {
    selectedScale: function (val) {
      this.$emit('scale-change', val)
    },
    selectedFontFamily: function (val) {
      this.$emit('apply-global-document-settings', val)
    }
  },
  props: {
    disabled: Boolean
  },
  mounted() {
    const zoom = localStorage.getItem('scale')
    if (!zoom || zoom != null) {
      switch (zoom) {
        case '1.0':
          this.selectedScale = '100%'
          break
        case '1.2':
          this.selectedScale = '125%'
          break
      }
    }

    const globalSettings = JSON.parse(localStorage.getItem(Constants.globalEditorSettings))
    if (globalSettings !== null) {
      this.selectedFontFamily = globalSettings['fontFamily']
      this.selectedFontSize = globalSettings['fontSize']
    } else {
      this.selectedFontFamily = GlobalEditorSettings.fontFamily
      this.selectedFontSize = GlobalEditorSettings.fontSize
    }
  },
  methods: {
    valueFormatter(size) {
      return `${size}px`
    },
    onDropdownHide(bvEvent) {
      if (!this.closable) {
        bvEvent.preventDefault();
      }
    },
    onShown(event) {
      this.closable = false
    },
    hide() {
      this.closable = true
      this.$refs.globalSettings.hide(true)
    },
    apply() {
      //PEventBus.$emit('apply-global-document-settings', settings, true)
      this.$emit('apply-global-document-settings', this.selectedFontFamily)
      this.hide()
    }
  }
}
</script>

<style scoped>

</style>
